import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import InputPhone from 'containers/App/components/InputPhone';
import { ROUTES } from 'config/constants';
import { Link } from 'react-router-dom';
import './ReturnProduct.scss';
import { returnReasons } from '../ReturnOrder/ReturnOrder';
import Button from 'containers/App/components/Button/Button';
import { getReturnDates, sendReturnProduct } from 'api/product';
import { notifType, notify } from 'utils/notifSender';

ReturnProduct.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    product: PropTypes.object,
    order: PropTypes.any,
    setDisable: PropTypes.func
};

export default function ReturnProduct({
    open,
    handleClose,
    order,
    product,
    setDisable
}) {
    const defaultValue = {
        block: '',
        street: '',
        building: '',
        floor: '',
        unit: '',
        country: '',
        postal_code: '',
        contact_name: '',
        contact_number: '',
        reason: '',
        message: '',
        reset_acknowledge: false,
        package_acknowledge: false,
        agree: false
    };

    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(null);
    const [dates, setDates] = useState([]);
    const [address, setAddress] = useState(
        defaultValue
    );

    const [error, setError] = useState(
        {
            block: false,
            street: false,
            building: false,
            floor: false,
            unit: false,
            country: false,
            postal_code: false,
            contact_name: false,
            contact_number: false,
            reason: false,
            message: false,
            reset_acknowledge: false,
            package_acknowledge: false,
            agree: false
        }
    );

    useEffect(() => {
        const handleDate = (e) => {
            const date = new Date(e).toString();
            const month = date.substring(4, 7);
            const day = date.substring(8, 10);

            return day + ' ' + month;
        };

        const getDates = async () => {
            try {
                const data = await getReturnDates(order.id);
                const tmp = [];

                for (const date of data.dates) {
                    const name = handleDate(date);

                    tmp.push({ value: date, name });
                }

                setDates(tmp);
            } catch (error) {
                //
            }
        };

        getDates();
    }, [order.id]);

    const toggle = () => handleClose(!open);

    const handleSelectDate = value => {
        setActive(value);
    };

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        switch (name) {
            case 'reset_acknowledge':
                setAddress(prev => ({ ...prev, [name]: checked }));

                break;

            case 'package_acknowledge':
                setAddress(prev => ({ ...prev, [name]: checked }));

                break;

            case 'agree':
                setAddress(prev => ({ ...prev, [name]: checked }));

                break;

            default:
                setAddress(prev => ({ ...prev, [name]: value }));
                break;
        }

        setError(prev => ({ ...prev, [name]: false }));
    };

    const checkForm = fields => {
        const keys = Object.keys(fields);
        let check = true;

        for (const field of keys) {
            // const ref = field;
            if (
                fields[field] === '' ||
                (field === 'postal_code' && fields[field].length !== 6) ||
                (field === 'contact_number' && fields[field].length !== 10) ||
                (field === 'reset_acknowledge' && !fields[field]) ||
                (field === 'package_acknowledge' && !fields[field]) ||
                (field === 'agree' && !fields[field]) ||
                (field === 'reason' && !fields[field])
            ) {
                // check if fields are empty

                setError(prev => ({ ...prev, [field]: true }));
                setFlag(!flag);

                notify('Check the required field.', notifType.DANGER);

                check = false;

                return false;
            }
        }

        return check;
    };

    const handleSubmit = async () => {
        if (!active) {
            notify('Please select a pickup date.', notifType.DANGER);

            return false;
        }

        const check = checkForm(address);

        if (!check) {
            return false;
        }

        setLoading(true);

        const form = {
            ...address,
            contact_number: '+' + address.contact_number,
            product_id: order.product_id,
            order_id: order.id,
            return_date: active
        };

        try {
            await sendReturnProduct(form);

            setAddress(defaultValue);
            setActive(null);
            notify('Thanks, we received your information!', notifType.SUCCESS);
            handleClose(!open);
            setDisable(true);
        } catch (error) {
            //
        }

        setLoading(false);
    };

    return (<Modal
        size='lg'
        isOpen={open}
        toggle={toggle}
        className='returnModal'>
        <div className='return'>
            <div className='return__header'>
                <div className='return__title'>
                    Return Product
                </div>
                <div className='return__close' onClick={toggle}>
                    X
                </div>
            </div>
            <div className='return__product'>
                <div className='return__product-img'>
                    <img
                        className='return__img'
                        src={order.image_url}
                        alt={product.name} />
                </div>
                <div className='return__product-info'>
                    <div className='return__product-category'>
                        {product.category.name}
                    </div>
                    <div className='return__product-name'>
                        {product.name}
                    </div>
                    <div className='return__product-color'>
                        {product.colors.find(e => e.id === order.color_id).name}
                    </div>
                </div>
                <div className='return__product-order'>
                    <div className='return__order'>
                        Order Id - {order?.order_number}
                    </div>
                    <div className='return__price'>
                        SGD {order.total}
                    </div>
                </div>
            </div>
            <div className='return__pickup' data-name='pickup'>
                <div className='return__pickup-title'>
                    Pickup Date (Only Weekdays) Time : 9am-7pm
                </div>
                <div className='return__pickup-dates'>
                    {dates.map((item, i) =>
                        <div
                            className={item.value === active ? 'return__pickupDate return__pickupDate--active' : 'return__pickupDate'}
                            key={i}
                            onClick={() => handleSelectDate(item.value)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div>
            <div className='return__address'>
                <div className='return__address-title'>
                    Address for Pickup *
                </div>
                <div className='return__address-form'>
                    <div className='d-flex'>
                        <FormGroup className='return__formGroup' data-name='block'>
                            <Label className='return__formGroup-label'>
                                Block
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='block'
                                name='block'
                                value={address.block}
                                onChange={handleChange}
                                invalid={error.block}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>

                        <FormGroup className='return__formGroup' data-name='street'>
                            <Label className='return__formGroup-label'>
                                Street
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='street'
                                name='street'
                                value={address.street}
                                onChange={handleChange}
                                invalid={error.street}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className='d-flex'>
                        <FormGroup className='return__formGroup' data-name='building'>
                            <Label className='return__formGroup-label'>
                                Building
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='building'
                                name='building'
                                value={address.building}
                                onChange={handleChange}
                                invalid={error.building}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>

                        <FormGroup className='return__formGroup' data-name='floor'>
                            <Label className='return__formGroup-label'>
                                Floor
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='floor'
                                name='floor'
                                value={address.floor}
                                onChange={handleChange}
                                invalid={error.floor}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>

                        <FormGroup className='return__formGroup' data-name='unit'>
                            <Label className='return__formGroup-label'>
                                Unit
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='unit'
                                name='unit'
                                value={address.unit}
                                onChange={handleChange}
                                invalid={error.unit}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className='d-flex'>
                        <FormGroup className='return__formGroup' data-name='country'>
                            <Label className='return__formGroup-label'>
                                Country
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='country'
                                name='country'
                                value={address.country}
                                onChange={handleChange}
                                invalid={error.country}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                        </FormGroup>

                        <FormGroup className='return__formGroup' data-name='postal_code'>
                            <Label className='return__formGroup-label'>
                                Postal Code
                            </Label>
                            <Input
                                className='return__formGroup-input'
                                id='postal_code'
                                name='postal_code'
                                value={address.postal_code}
                                onChange={handleChange}
                                invalid={error.postal_code}
                                // disabled={isFormDisabled}
                                type='text' />

                            <FormFeedback valid={false} className='return__formGroup-error'>please enter the correct postal code*</FormFeedback>
                        </FormGroup>
                    </div>

                    <FormGroup className='return__formGroup' data-name='contact_name'>
                        <Label className='return__formGroup-label'>
                            Contact Name *
                        </Label>
                        <Input
                            className='return__formGroup-input'
                            id='contact_name'
                            name='contact_name'
                            value={address.contact_name}
                            onChange={handleChange}
                            invalid={error.contact_name}
                            // disabled={isFormDisabled}
                            type='text' />

                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>

                    <FormGroup className='return__formGroup' data-name='contact_number'>
                        <Label className='return__formGroup-label'>
                            Contact Number *
                        </Label>
                        <InputPhone
                            phone={address.contact_number}
                            setPhone={(value) => handleChange({ target: { value, name: 'contact_number' } })}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            className='return__formGroup-phone'
                            country={'sg'}
                            onlyCountries={['sg']} />

                        <FormFeedback valid={false} className='return__formGroup-error'
                            style={{ display: error.contact_number ? 'block' : 'none' }}>this field is required*</FormFeedback>
                    </FormGroup>

                    <FormGroup className='return__formGroup'>
                        <Label className='return__formGroup-label'>
                            Reason *
                        </Label>
                        <Input type='select' id='returnReason'
                            name='reason'
                            value={address.reason}
                            invalid={error.reason}
                            placeholder='Please select a reason'
                            className='return__formGroup-select'
                            onChange={handleChange}>
                            <option value='' disabled={true}>Please select a reason</option>
                            {returnReasons.map((reason, index) => <option key={index}>{reason.label}</option>)}
                        </Input>

                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>

                    <FormGroup className='return__formGroup' data-name='message'>
                        <Label className='return__formGroup-label'>
                            Comments
                        </Label>
                        <Input
                            className='return__formGroup-input return__formGroup-textarea'
                            id='message'
                            name='message'
                            value={address.message}
                            onChange={handleChange}
                            invalid={error.message}
                            // disabled={isFormDisabled}
                            type='textarea' />

                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>
                </div>
                <div className='return__row'>
                    <FormGroup className='return__formGroup' check={true}
                        inline={true} data-name='reset_acknowledge' >
                        <Input
                            name='reset_acknowledge'
                            value={address.reset_acknowledge}
                            onChange={handleChange}
                            invalid={error.reset_acknowledge}
                            type='checkbox' />
                        <Label check={true} className='return__formGroup-check-label'>
                            I agree to restore my device to factory settings before returning it or pay a $50 remote reset fee if I return it without resetting it.
                        </Label>
                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>
                </div>
                <div className='return__row'>
                    <FormGroup className='return__formGroup' check={true}
                        inline={true} data-name='package_acknowledge' >
                        <Input
                            name='package_acknowledge'
                            value={address.package_acknowledge}
                            onChange={handleChange}
                            invalid={error.package_acknowledge}
                            type='checkbox' />
                        <Label check={true} className='return__formGroup-check-label'>
                            I agree to return my device in its original packaging or pay a $30 packaging replacement fee if it is damaged or missing.
                        </Label>
                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>
                </div>
                <div className='return__row'>
                    <FormGroup className='return__formGroup' check={true}
                        inline={true} data-name='agree' >
                        <Input
                            name='agree'
                            value={address.agree}
                            onChange={handleChange}
                            invalid={error.agree}
                            type='checkbox' />
                        <Label check={true} className='return__formGroup-check-label'>
                            By checking, I agree to <Link to={ROUTES.TERMS_AND_CONDITIONS} className='return__link'> Terms & Conditions </Link> and Company’s <Link className='return__link' to={ROUTES.PRIVACY_POLICY}> Return Policy </Link>
                        </Label>
                        <FormFeedback valid={false} className='return__formGroup-error'>this field is required*</FormFeedback>
                    </FormGroup>
                </div>
            </div>
            <div className='return__send'>
                <Button className='submit' title='Send' onClick={handleSubmit}
                    loading={loading} />
            </div>
        </div>
    </Modal >
    );
}
