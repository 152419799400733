import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

export async function getCategories(id) {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + `/categories${id ? '/' + id : ''}`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getAllCategories() {
    const options = {
        method: 'GET',
        // headers: {
        //     Authorization: 'Bearer ' + getAuthToken()
        // },
        url: AUTH_API_URL + '/categories'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getExploreCategories() {
    const options = {
        method: 'GET',
        // headers: {
        //     Authorization: 'Bearer ' + getAuthToken()
        // },
        url: AUTH_API_URL + '/categories?homepage'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
