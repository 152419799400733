import PropTypes from 'prop-types';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import style from './DescContent.module.scss';

DescContent.propTypes = {
    content: PropTypes.string.isRequired
};

function DescContent({ content }) {
    return (<div
        className={style.section}
        dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content, {
                allowedTags: false,
                allowedAttributes: false
            })
        }}/>);
}

export default DescContent;
