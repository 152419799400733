import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    NavLink,
    Modal
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { useQuery, getReferralParam } from 'utils/helpers';
import * as FS from '@fullstory/browser';
import { notifType, notify } from 'utils/notifSender';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { ROUTES, AUTH_API_URL } from 'config/constants';

import { addItemsToCart } from 'api/cart';
import { checkOTPCode, getOTPForForgotPassword, passwordReset, storeUserEvents, getUserEmailAvailability, getUserIP } from 'api/user';
import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';
import { clearCart, notifyHeaderOnCartUpdate } from 'redux/reducers/cart';
import {
    handleGetOTPForLogin,
    handleLogin,
    handleRegistration,
    loginModal,
    handleHideOTP,
    handleLoginWithOTP,
    handleCloseSignUp,
    toggleInvoiceModal
} from 'redux/reducers/auth';

import Button from '../Button/Button';
import LoginForm from './elements/LoginForm/LoginForm';
import SignUpForm from './elements/SignUpForm/SignUpForm';
import LoginOTPForm from './elements/LoginOTPForm/LoginOTPForm';
import OTPForm from './elements/OTPForm/OTPForm';
import ForgotForm from './elements/ForgotForm/ForgotForm';
import ResetPassword from './elements/ResetPassword/ResetPassword';
import InitialLoginForm from 'containers/App/components/InitialLoginForm/InitialLoginForm';

import close from 'assets/images/login-close-new.svg';
import Logo from 'assets/images/general/cinch-new-logo.png';
import AuthFormBg from 'assets/images/authFormBg.png';

import style from './LoginModal.module.scss';

LoginModal.propTypes = {
    isAuthenticating: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    loginModal: PropTypes.func.isRequired,
    handleLogin: PropTypes.func.isRequired,
    handleRegistration: PropTypes.func.isRequired,
    handleGetOTPForLogin: PropTypes.func,
    showOTP: PropTypes.bool,
    handleLoginWithOTP: PropTypes.func,
    signUp: PropTypes.bool,
    handleCloseSignUp: PropTypes.func,
    clearCart: PropTypes.func.isRequired,
    product: PropTypes.any,
    profile: PropTypes.any,
    isReset: PropTypes.bool,
    forgetPasswordOnAccount: PropTypes.bool,
    resetEmail: PropTypes.string,
    toggleModal: PropTypes.func
};

function LoginModal({
    isOpen,
    loginModal,
    handleLogin,
    isAuthenticating,
    handleRegistration,
    handleGetOTPForLogin,
    showOTP,
    handleLoginWithOTP,
    handleCloseSignUp,
    clearCart,
    profile,
    isReset,
    resetEmail,
    forgetPasswordOnAccount = false,
    toggleModal
}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
    const [form, setForm] = useState('');
    const [loginWithPhone, setLoginWithPhone] = useState('');
    const [timer, setTimer] = useState(90);
    const [loading, setLoading] = useState(false);
    const [forgotOtp, setForgotOtp] = useState(false);
    const [emailAddress, setEmailAddress] = useState(isReset ? resetEmail : '');
    const [otp, setOtp] = useState('');
    const [lastnumber, setLastnumber] = useState('');
    const [ip, setIP] = useState(null);
    const [doRegister, setDoRegister] = useState(false);
    const [doLogin, setDoLogin] = useState(false);
    const [emailForAutoFill, setEmailForAutoFill] = useState('');
    const [emailId, setEmailId] = useState('');

    const query = useQuery();

    useEffect(() => {
        const forgot = query.get('forgot');

        if (forgot && forgot === 'true') {
            handleChangeForm('forgot');
        }
    }, [query]);

    useEffect(() => {
        if (isReset) {
            setForm('loginOtp');
        }
    }, [isReset]);

    useEffect(() => {
        if (showOTP || forgotOtp) {
            const time = setTimeout(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
            }, 1000);

            return () => clearTimeout(time);
        } else {
            setTimer(90);
        }
    }, [forgotOtp, showOTP, timer]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await getUserIP();

                setIP(res);
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        getData();
    }, []);

    const handleLoginWithPhone = e => {
        setLoginWithPhone(e);

        if (e.length > 2) {
            setLastnumber(e.slice(-2));
        }
    };

    const handleChangeForm = e => setForm(e);

    const submitLogin = async (params) => {
        handleLogin({
            ...params,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: (resp) => {
                setDoLogin(true);

                dispatch(notifyHeaderOnCartUpdate(true));

                if (resp && (resp.orderId || resp.invoiceUrl)) {
                    dispatch(toggleInvoiceModal(true, resp.orderId, resp.invoiceUrl));
                } else {
                    if (window.location.pathname === '/products') {
                        window.location.reload();
                    }
                }
            }
        });
    };

    const submitSignUp = async (params) => {
        handleRegistration({
            ...params,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: () => {
                setDoRegister(true);

                const urlParams = new URLSearchParams(window.location.search);
                const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'irclickid'];

                const userEventParams = {
                    event: 'user_registered',
                    ...Object.fromEntries(utmFields.map(field => [field, urlParams.get(field)]).filter(([_, value]) => value)) // get utm params from url if they exist, filtering out empty values
                };

                storeUserEvents(userEventParams);
            }
        });
    };

    const getRedirectUrl = () => {
        const queryParams = new URLSearchParams(location.search);
        const authenticate = queryParams.get('authenticate') ?? false;
        const redirectUrl = queryParams.get('redirect_url') ?? null;

        if (authenticate === 'true' && redirectUrl != null) {
            return redirectUrl;
        }

        return null;
    };

    const redirect = (route) => {
        let routeToRedirect = route;
        const cartItems = JSON.parse(localStorage.getItem('products')) || [];

        if (getRedirectUrl() != null) {
            routeToRedirect = getRedirectUrl();
        }

        if (cartItems.length > 0) {
            routeToRedirect = ROUTES.CART;
        }

        if (routeToRedirect === null) {
            return;
        }

        history.push(routeToRedirect);
    };

    useEffect(() => {
        async function sendProductsToBE() {
            const makeProducts = [];

            for (const product of products) {
                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: product.selectedStorage ? product.selectedStorage.value
                            : (
                                product.attributes.specifications.values[0].id ||
                                product.attributes.inside_box.values[0].id
                            ),
                        quantity: 1,
                        rental_period_id: product.selectedRentalPeriod.id,
                        rental_duration_time: product.selectedRentalPeriod.duration_time,
                        product_id: product.id,
                        color_id: product.selectedColor.value,
                        deal_id: !product.selectedDeals?.discount_type && product.selectedDeals ? product.selectedDeals.id : null,
                        flash_deal_product_id: product.selectedDeals && product.selectedDeals?.discount_type ? product.selectedDeals.id : null

                    }
                );
            }

            try {
                await addItemsToCart(makeProducts);

                notify(`Product${makeProducts.length > 1 ? 's' : ''} added to cart successfuly`, notifType.SUCCESS);
                clearCart();
                redirect(ROUTES.CART);
            } catch (error) {
                Sentry.captureException(error);
            }
        }

        if (profile.id && doRegister) {
            ReactGA.event('user_register', {
                category: 'user_auth',
                label: 'user_register',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            trackKlaviyoEvent('add-cart', {
                email: profile?.email,
                user_id: profile?.id,
                title: products[0]?.name,
                product: products[0],
                images: products[0]?.medias,
                price: products[0]?.selectedRentalPeriod?.price,
                cartItems: products,
                cartMeta: products[0]?.total,
                selectedRentalPeriod: products[0]?.selectedRentalPeriod,
                selectedStorage: products[0]?.storage
            });

            FS.event('User registered', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);
            trackFreshChatUser('identify', profile);
            redirect(null);
            setDoRegister(false);
        }

        if (profile.id && doLogin) {
            ReactGA.event('user_login', {
                category: 'user_auth',
                label: 'user_login',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            trackKlaviyoEvent('add-cart', {
                email: profile?.email,
                user_id: profile?.id,
                title: products[0]?.name,
                product: products[0],
                images: products[0]?.medias,
                price: products[0]?.selectedRentalPeriod?.price,
                cartItems: products,
                cartMeta: products[0]?.total,
                selectedRentalPeriod: products[0]?.selectedRentalPeriod,
                selectedStorage: products[0]?.storage
            });

            if (products.length !== 0) {
                if (profile.singpass_status !== 'verified') {
                    notify('Complete your Singpass profile before proceeding.', notifType.DANGER);
                    clearCart();
                    redirect(ROUTES.MYINFORMATION);
                } else {
                    sendProductsToBE();
                }
            }

            FS.event('User login', {
                user_id: profile.id.toString()
            });
            trackKlaviyoEvent('identify', profile);
            trackFreshChatUser('identify', profile);

            if (products.length <= 0) {
                redirect(null);
            }

            setDoLogin(false);
        }
    }, [profile, doRegister, doLogin, clearCart, history, products]);

    const submitForgotPassword = async (email) => {
        setLoading(true);
        setTimer(90);
        setEmailAddress(email);
        getOTPForForgotPassword(email);
        setForgotOtp(true);
        setForm('reset');
        setLoading(false);
    };

    const handleSubmitLoginWithPhone = async () => {
        if (loginWithPhone === '') {
            notify('Please enter your mobile number.', notifType.DANGER);

            return true;
        }

        setOtp('');
        handleGetOTPForLogin(loginWithPhone);
    };

    const handleResendCode = () => {
        if (timer > 0) {
            notify(`Error ! after ${timer} seconds you can resend SMS again`, notifType.DANGER);

            return true;
        }

        setOtp('');
        handleGetOTPForLogin(loginWithPhone);
        setTimer(90);
    };

    const handleSubmitOtp = async (type) => {
        if (type === 'login' && !isReset) {
            handleLoginWithOTP({ otp });
            setOtp('');
        }

        if (isReset || type === 'forgot') {
            setLoading(true);

            try {
                await checkOTPCode(otp);
                setForgotOtp(false);

                if (isReset) {
                    setForm('reset');
                }
            } catch (error) {
                Sentry.captureException(error);

                if (error.response.status) {
                    notify('The OTP code is incorrect.', notifType.DANGER);
                }
            }

            setLoading(false);
        }
    };

    const submitResetPassword = async password => {
        setLoading(true);

        try {
            await passwordReset(
                {
                    otp_code: otp,
                    password,
                    password_confirmation: password
                }
            );
            notify('Password changed successfully.', notifType.SUCCESS);
            setForm('signin');
            setForgotOtp(false);

            if (isReset) {
                toggleModal();
            }
        } catch (error) {
            Sentry.captureException(error);

            if (error.response.status) {
                notify('Please try again.', notifType.DANGER);
            }
        }

        setOtp('');
        setLoading(false);
    };

    const checkForUserExistence = async (e) => {
        e.preventDefault();
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const email = emailId;

        if (email === null || email.trim() === '') {
            return false;
        }

        if (!emailPattern.test(email)) {
            return false;
        }

        try {
            const data = await getUserEmailAvailability(email);

            if (data?.exists) {
                setEmailForAutoFill(email);
                setForm('signin');
            } else {
                setEmailForAutoFill(email);
                setForm('signup');
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const backToMainLogin = () => setForm('');

    const handleSocialLogin = (provider) => {
        const storageReferral = getReferralParam();

        window.location.href = `${AUTH_API_URL}/auth/oauth/redirect/${provider}?channel=cinch&referral_code=${storageReferral}`;
    };

    const resendRequest = async () => {
        if (timer > 0) {
            notify(`Error ! after ${timer} seconds you can resend SMS again`, notifType.DANGER);

            return true;
        }

        setOtp('');
        submitForgotPassword(emailAddress);
        setTimer(90);
    };

    useEffect(() => {
        if (form === 'reset' && otp && otp.length === 6) {
            handleSubmitOtp('forgot');
        }
    }, [otp]);

    useEffect(() => {
        if (forgetPasswordOnAccount && resetEmail && isOpen) {
            setOtp('');
            submitForgotPassword(resetEmail);
        }
    }, [forgetPasswordOnAccount, resetEmail, isOpen]);

    return (
        <Modal isOpen={isOpen} className={style.modal}>
            <div className={style.section}>
                <div className={style.header}>
                    <div className={style.header__close}>
                        <img className={style.header__close__img}
                            onClick={isReset ? () => { toggleModal(); handleCloseSignUp(); loginModal(false); setForm(''); } : () => { handleCloseSignUp(); loginModal(false); setForm(''); }}
                            src={close}
                            alt='close' />
                    </div>
                </div>

                <div className={style.forms}>
                    {form === '' &&
                        <InitialLoginForm
                            handleSocialLogin={handleSocialLogin}
                            setEmailId={setEmailId}
                            checkEmailAvailability={checkForUserExistence}
                            loading={isAuthenticating}
                            fromPopup={true} />
                    }

                    {form === 'signin' &&
                        <LoginForm
                            submit={submitLogin}
                            changeForm={handleChangeForm}
                            backToMainLogin={backToMainLogin}
                            email={emailForAutoFill}
                            setEmail={setEmailForAutoFill}
                            loading={isAuthenticating} />
                    }

                    {form === 'signup' &&
                        <SignUpForm
                            submit={submitSignUp}
                            changeForm={handleChangeForm}
                            backToMainLogin={backToMainLogin}
                            email={emailForAutoFill}
                            setEmail={setEmailForAutoFill}
                            loading={isAuthenticating} />}

                    {(form === 'loginOtp' && !showOTP && !isReset) &&
                        <LoginOTPForm
                            phone={loginWithPhone}
                            setPhone={handleLoginWithPhone}
                            submit={handleSubmitLoginWithPhone}
                            changeForm={handleChangeForm}
                            loading={isAuthenticating} />}

                    {(form === 'loginOtp' && (showOTP || isReset)) &&
                        <OTPForm
                            lastnumber={lastnumber}
                            otp={otp}
                            setOtp={setOtp}
                            type='login'
                            resendCode={handleResendCode}
                            timer={timer}
                            submit={handleSubmitOtp}
                            changeForm={handleChangeForm}
                            email={emailAddress}
                            isReset={isReset}
                            loading={isAuthenticating} />}

                    {form === 'forgot' &&
                        <ForgotForm
                            submit={submitForgotPassword}
                            changeForm={handleChangeForm}
                            backToMainLogin={backToMainLogin}
                            Filledemail={emailForAutoFill}
                            loading={loading} />}

                    {form === 'reset' && !forgotOtp &&
                        <ResetPassword
                            submit={submitResetPassword}
                            changeForm={handleChangeForm}
                            isReset={isReset}
                            backToMainLogin={!forgetPasswordOnAccount ? backToMainLogin : null}
                            loading={loading} />}

                    {form === 'reset' && forgotOtp &&
                        <OTPForm
                            lastnumber={lastnumber}
                            otp={otp}
                            setOtp={setOtp}
                            email={emailAddress}
                            type={'forgot'}
                            resendCode={handleResendCode}
                            timer={timer}
                            backToMainLogin={!forgetPasswordOnAccount ? backToMainLogin : null}
                            submit={resendRequest}
                            changeForm={handleChangeForm}
                            loading={loading} />}
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps({ cart, auth }) {
    const { products, product } = cart;
    const { isAuthenticating, showOTP, signUp, profile } = auth;

    return {
        products: JSON.parse(products),
        isAuthenticating,
        showOTP,
        signUp,
        product,
        profile
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loginModal: (openLogin) => dispatch(loginModal(openLogin)),
        handleLogin: ({
            email,
            password,
            isRemember,
            gaSessionId,
            trueIp,
            onSuccess
        }) => {
            dispatch(handleLogin({
                email,
                password,
                isRemember,
                gaSessionId,
                trueIp,
                onSuccess
            }));
        },
        handleRegistration: ({
            email,
            password,
            confirmPassword,
            gaSessionId,
            trueIp,
            onSuccess
        }) => {
            dispatch(handleRegistration({
                email,
                password,
                confirmPassword,
                gaSessionId,
                trueIp,
                onSuccess
            }));
        },
        handleGetOTPForLogin: (phone) => dispatch(handleGetOTPForLogin({ phone })),
        handleHideOTP: () => dispatch(handleHideOTP()),
        handleLoginWithOTP: ({ otp }) => dispatch(handleLoginWithOTP({ otp })),
        handleCloseSignUp: () => dispatch(handleCloseSignUp()),
        clearCart: () => dispatch(clearCart())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
