import PropTypes from 'prop-types';
import Button from 'containers/App/components/Button/Button';
import React from 'react';
import style from './RiseTicket.module.scss';
// import SelectColor from '../SelectColor/SelectColor';
import { notifType, notify } from 'utils/notifSender';

RiseTicket.propTypes = {
    onClick: PropTypes.func.isRequired,
    submitLoading: PropTypes.bool,
    riseTicket: PropTypes.object,
    setRiseTicket: PropTypes.func
};

function RiseTicket({
    onClick,
    submitLoading,
    riseTicket,
    setRiseTicket
}) {
    const handleSubmit = (e) => {
        e.preventDefault();

        if (riseTicket.reason === '' || riseTicket.message === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        onClick(riseTicket);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'message' && value.length > 1000) {
            notify('You have reached the maximum character limit.', notifType.DANGER);

            return true;
        }

        setRiseTicket(prevState => ({ ...prevState, [name]: value }));
    };

    return (<div >
        <form onSubmit={handleSubmit}>
            <div className={style.select}>
                <div className={style.select__title}>
                    What your issue is about ?
                </div>
                <div className={style.select__options}>
                    <input className={style.select__options__title} name='reason' onChange={handleChange} />
                    {/* <SelectColor
                    classNamePrefix='ticket'
                    options={sampleOptions}/> */}
                </div>
            </div>

            <div className={style.select}>
                <div className={style.select__title}>
                    Details of your request
                </div>
                <div className={style.select__desc}>
                    <textarea className={style.select__desc__text} name='message' onChange={handleChange} />
                </div>
            </div>

            <div>
                <Button
                    loading={submitLoading}
                    className={'submit '}
                    title={'Send'} />
            </div>
        </form>
    </div>);
}

export default RiseTicket;
