import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';
import Button from 'containers/App/components/Button/Button';

import NoImg from 'assets/images/noImg.png';

export function Autocomplete({
    productsList,
    categsList,
    closeList,
    handleRedirection,
    propogateHideDrawer
}) {
    const history = useHistory();
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    return (
        <div className='search-admin__autocomplete'>
            {!!categsList.length && (
                <p className='search-admin__autocomplete-subtitle'>
                    <span>CATEGORIES</span>
                </p>
            )}
            {categsList.map(cat => (
                <div
                    className='d-flex align-items-center search-admin__autocomplete-item'
                    onClick={() => {
                        closeList();
                        propogateHideDrawer();
                        history.push({
                            pathname: ROUTES.PRODUCTS,
                            search: `?category[]=${cat.slug}&rental_period=12`
                        });
                        window.location.href = ROUTES.PRODUCTS + `?category[]=${cat.slug}&rental_period=12`;
                    }}
                    key={cat.id}>
                    <img className='mr-0' src={cat.icon_url} alt={`Icon for ${cat.name}`} />
                    {cat.name}
                </div>
            ))}

            {!!productsList.length && (
                <div className='search-admin__autocomplete-subtitle'>
                    <span>TOP RESULTS</span>
                </div>
            )}
            {productsList.map(
                (product, i) =>
                    i < 5 && (
                        <div
                            className='search-admin__autocomplete-item'
                            onClick={() => {
                                if (product?.productUrl) {
                                    window.open(product.productUrl + '?source=search-product', '_blank');
                                } else if (isMobile) {
                                    propogateHideDrawer();
                                    window.location.href = ROUTES.PRODUCT.generate(product?.slug + '?source=search-product');
                                } else {
                                    window.location.href = ROUTES.PRODUCT.generate(product?.slug + '?source=search-product', null);
                                }
                            }}
                            key={product.id}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img
                                className=''
                                src={product.medias?.[0]?.url || NoImg}
                                alt={`Image for ${product.name} in search results`} />
                            <div>
                                <p className='search-admin__autocomplete-item-name'>
                                    {product.name}
                                </p>
                            </div>
                        </div>
                    )
            )}
            {productsList.length > 5 && (
                <div className='search-admin__autocomplete-show'>
                    <Button
                        className={'submit'}
                        title='Show more products'
                        onClick={() => {
                            closeList();
                            propogateHideDrawer();
                            handleRedirection();
                        }} />
                </div>
            )}
        </div>
    );
}

Autocomplete.propTypes = {
    productsList: PropTypes.array,
    categsList: PropTypes.array,
    closeList: PropTypes.func,
    handleRedirection: PropTypes.func,
    propogateHideDrawer: PropTypes.func
};
