// import i18next from 'i18next';
import {
    AUTH_LOGOUT
    // MODEL_CREATE, MODEL_DELETE, MODEL_UPDATE
} from './messages-success';

export function getMessageByResponseSuccess({ method, endpoint }) {
    if (method !== 'post') {
        return;
    }

    endpoint = endpoint.split('/');

    switch (endpoint[0]) {
        case 'auth': {
            switch (endpoint[1]) {
                case 'logout': return AUTH_LOGOUT;
                // case 'logout': return i18next.t('AUTH_LOGOUT');

                default: break;
            }

            break;
        }

        // case 'xxxx': {
        //     switch (endpoint[1]) {
        //         case 'yyyy': return MODEL_CREATED;
        //
        //         default: {
        //             switch (endpoint[2]) {
        //                 case 'delete': return MODEL_DELETE;
        //
        //                 // Check BE /routes/api.php if there are any other routes
        //                 default: return MODEL_UPDATE;
        //             }
        //         }
        //     }
        // }

        default: return false;
    }

    // uncomment to check for "no-unreachable"
    // return false;
}
