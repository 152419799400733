import PropTypes from 'prop-types';
import React from 'react';

import style from './SpecificContent.module.scss';

SpecificContent.propTypes = {
    options: PropTypes.array.isRequired
};

function SpecificContent({ options }) {
    return (<div className={style.section}>
        {options.map((option, i) =>
            <div key={i} className={style.box}>
                <div className={style.box__title}>
                    {option.key}
                </div>
                <div className={style.box__value}>
                    {option.value}
                </div>
            </div>
        )}
    </div>);
}

export default SpecificContent;
