import React from 'react';
import './EligiblePopUp.scss';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { ReactComponent as Noteligible } from 'assets/images/general/noteligible.svg';

function EligiblePopUp({ isOpen, onToggle }) {
    return (
        <Modal
            centered={true}
            className='eligibleModal'
            isOpen={isOpen}
            toggle={onToggle}>
            <div className='eligibleModal__content'>
                <div className='eligibleModal__alert'>
                    <Noteligible className='eligibleModal__icon' />
                </div>
                <div className='eligibleModal__p'>
                    Not eligible to rent this product as it exceeds your available credit limit
                </div>
                <div className='eligibleModal__action'>
                    <button className='eligibleModal__action-btn' onClick={onToggle}>Close</button>
                </div>
            </div>
        </Modal>
    );
}

EligiblePopUp.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
};
export default EligiblePopUp;
