import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import close from 'assets/images/general/close.svg';
import TradeInReq1 from 'assets/images/trade-in-req1.png';
import TradeInReq2 from 'assets/images/trade-in-req2.png';
import TradeInReq3 from 'assets/images/trade-in-req3.png';
import TradeInReq4 from 'assets/images/trade-in-req4.png';

import './TradeInReqModal.scss';

export default function TradeInReqModal({
    isOpen,
    onToggle
}) {
    const tradeInRequirements = [
        {
            id: 1,
            image: TradeInReq1,
            label: 'Phone must be in proper working condition.'
        },
        {
            id: 2,
            image: TradeInReq2,
            label: 'No screen cracks or deep scratches.'
        },
        {
            id: 3,
            image: TradeInReq3,
            label: 'Battery health should be at least 60%.'
        },
        {
            id: 4,
            image: TradeInReq4,
            label: 'Reset the phone to factory settings and unlink all accounts'
        }
    ];

    return (
        <Modal isOpen={isOpen} centered={true} toggle={onToggle}
            backdrop={true} className='trade-in-req-modal'>
            <div className='trade-in-req-modal__container'>
                <div className='w-100 trade-in-req-modal__container__header'>
                    <img className='ic-close' src={close} onClick={onToggle}
                        alt='Close' />
                </div>
                <div className='trade-in-req-modal__container__content'>
                    <p className='title'>Trade-In Eligibility Requirements</p>
                    <div className='requirements'>
                        {tradeInRequirements.map((item) => (
                            <div className='requirements__req-tile' key={item?.id}>
                                <img className='image' src={item?.image} alt={item?.label}/>
                                <p className='desc'>{item?.label}</p>
                            </div>
                        ))}
                    </div>
                    <p className='note'>*Failure to meet these criteria during inspection will result in cancellation of the trade-in offer, and Cinch retains the right to decline the trade-in based on its evaluation of the device.</p>
                    <p className='note'>*Payment will be processed within 5-7 days via PayNow upon receiving and inspecting the item.</p>
                </div>
            </div>
        </Modal>
    );
}

TradeInReqModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func
};
