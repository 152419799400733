import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PaymentScreenConfirmation from '../Payment/NewConfirmation/Confirmation';
import { notifType, notify } from 'utils/notifSender';

import { getAddress } from 'api/address';

const OrderSummaryScreenConfirmation = () => {
    const [address, setAddress] = useState({ id: -1 });
    const { selectedMethodId } = useSelector((state) => ({
        selectedMethodId: state.payment.selectedPaymentMethodId
    }));
    const tradeInObj = localStorage.getItem('currentOrderTradeIn');
    const orderedProduct = JSON.parse(localStorage.getItem('orderedProduct'));
    const meta = JSON.parse(localStorage.getItem('meta'));
    const selectedTradeIn = tradeInObj !== null && tradeInObj !== undefined ? JSON.parse(tradeInObj) : null;
    const paynowInfo = localStorage.getItem('currentOrderPayNow');

    const getRecentAddress = async () => {
        try {
            const resp = await getAddress();

            setAddress(resp);
        } catch (error) {
            // Handle the error here
            notify('Error ! Failed to get address', notifType.DANGER);
        }
    };

    useEffect(() => getRecentAddress(), []);

    return (
        <div>
            <PaymentScreenConfirmation
                cart={orderedProduct}
                address={address}
                selectedPaymentMethod={selectedMethodId}
                meta={meta}
                selectedTradeIn={selectedTradeIn}
                currentOrderPayNowInfo={paynowInfo} />
        </div>
    );
};

export default OrderSummaryScreenConfirmation;
