import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getElfsightWidgets } from 'api/elfsight';

const ElfsightWidget = ({ type }) => {
    const [code, setCode] = useState('');

    const getElfsightWidget = async (type) => {
        const data = await getElfsightWidgets();
        const match = data.find(d => d.type === type);

        if (match) setCode(match.code);
    };

    useEffect(() => {
        getElfsightWidget(type);
    }, []);

    if (!code) return null;

    return (
        <div className={code} data-elfsight-app-lazy={true} />
    );
};

export default ElfsightWidget;

ElfsightWidget.propTypes = {
    type: PropTypes.string.isRequired
};
