import PropTypes from 'prop-types';
import React from 'react';
import './InputPhone.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

InputPhone.propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string.isRequired,
    setPhone: PropTypes.func.isRequired,
    country: PropTypes.string,
    onlyCountries: PropTypes.array,
    disableDropdown: PropTypes.bool,
    countryCodeEditable: PropTypes.bool
};

function InputPhone({ className, phone, setPhone, country, onlyCountries, disableDropdown, countryCodeEditable, ...rest }) {
    return (
        <PhoneInput
            countryCodeEditable={countryCodeEditable}
            disableDropdown={disableDropdown}
            country={country || 'sg'}
            onlyCountries={onlyCountries || ['sg']}
            containerClass={className || 'phoneInput'}
            value={phone}
            onChange={phone => setPhone(phone)}
            {...rest}/>

    );
}

export default InputPhone;
