import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { storeUserEvents } from 'api/user';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.svg';
import EyeIcon from 'assets/images/eye-icon.svg'; // Add this import for the eye icon
import EyeSlashIcon from 'assets/images/eye-slash-icon.svg'; // Add this import for the hide icon

import style from './LoginForm.module.scss';

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string,
    setEmail: PropTypes.func.isRequired,
    backToMainLogin: PropTypes.func
};

function LoginForm({
    submit,
    changeForm,
    loading,
    email,
    setEmail,
    backToMainLogin
}) {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        if (email === '' || password === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        if (password.length < 5 || password.length > 48) {
            notify('The email or password you have entered is incorrect.', notifType.DANGER);

            return true;
        }

        submit({ email, password, isRemember: true });
    };

    useEffect(() => {
        storeUserEvents({
            event: 'sign_in'
        });
    }, []);

    return (<div className={style.section}>
        <form onSubmit={handleSubmit}>
            <h3><img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />Welcome to Cinch</h3>
            <p>Please sign in to continue.</p>
            <div className={style.row}>
                <div className={style.label}>
                    Email Address
                </div>
                <div className={style.value}>
                    <input name='email' type='email' placeholder='Email Address'
                        className={style.value__input} value={email} onChange={e => setEmail(e.target.value)} />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.label}>
                    Password
                </div>
                <div className={style.value}>
                    <div className={style.withIcon}>
                        <input
                            name='password'
                            placeholder='Password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className={style.value__input + ' ' + style.withIcon__input + ' ' + style.last__input}
                            type={showPassword ? 'text' : 'password'} />
                        <img
                            src={showPassword ? EyeIcon : EyeSlashIcon}
                            alt='Toggle password visibility'
                            className={style.passwordToggle}
                            onClick={() => setShowPassword(!showPassword)} />
                    </div>
                </div>
            </div>
            <div className={style.forgot}>
                <span
                    className={style.forgot__title}
                    onClick={() => changeForm('forgot')}>Forgot Password?</span>
            </div>
            <div className={style.buttons}>
                <Button
                    loading={loading}
                    type='submit'
                    title={'Sign In'}
                    disabled={!email || !password}
                    className={'submit'} />
            </div>
        </form>
    </div>);
}

export default LoginForm;
