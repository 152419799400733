import React from 'react';
import AppLoading from './AppLoading';
import './AppLoadingOverlay.scss';
import PropTypes from 'prop-types';

AppLoadingOverlay.propTypes = {
    className: PropTypes.string
};

AppLoadingOverlay.defaultProps = {
    className: ''
};

export default function AppLoadingOverlay({ className }) {
    return (
        <div className='overlay'>
            <AppLoading className={className} />
        </div>
    );
}
