import React, { useEffect } from 'react';
import Iframe from 'react-iframe';
import { useDispatch } from 'react-redux';

import { storeUserEvents } from 'api/user';
import { notifyHeaderOnCartUpdate } from 'redux/reducers/cart';

const ForBusinessClientsContainer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = 'For Business';

        storeUserEvents({ event: 'for_businesses' });
        dispatch(notifyHeaderOnCartUpdate(true));
    }, []);

    return (
        <>
            <Iframe url='https://d1rowbn844ovp8.cloudfront.net' name='business-iframe' width='100%'
                height='900px' allowFullScreen='true' />
        </>
    );
};

export default ForBusinessClientsContainer;
