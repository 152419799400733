import { lazy } from 'react';

export const HomeContainer = lazy(() => import('containers/Home/HomeContainer'));
export const InviteContainer = lazy(() => import('containers/Invite/InviteContainer'));
export const ProductsContainer = lazy(() => import('containers/Products/ProductsContainer'));
export const DealsContainer = lazy(() => import('containers/Deals/DealsContainer'));
export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));
export const ProductContainer = lazy(() => import('containers/Product/ProductContainer'));
export const OrderedProductContainer = lazy(() => import('containers/OrderedProduct/OrderedProductContainer'));
export const CartContainer = lazy(() => import('containers/Cart/CartContainer'));
export const ShippingContainer = lazy(() => import('containers/Shipping/ShippingContainer'));
export const PaymentContainer = lazy(() => import('containers/Payment/PaymentContainer'));
export const OrderSummaryContainer = lazy(() => import('containers/OrderSummary/OrderSummaryContainer'));
export const OrderConfirmedContainer = lazy(() => import('containers/OrderConfirmed/OrderConfirmedContainer'));
export const AboutUsContainer = lazy(() => import('containers/AboutUs/AboutUsContainer'));
export const TermsAndConditionsContainer = lazy(() => import('containers/TermsAndConditions/TermsAndConditionsContainer'));
export const FAQContainer = lazy(() => import('containers/FAQ/FAQContainer'));
export const PrivacyPolicyContainer = lazy(() => import('containers/PrivacyPolicy/PrivacyPolicyContainer'));
export const ForBusinessClientsContainer = lazy(() => import('containers/ForBusinessClients/ForBusinessClientsContainer'));
export const HowItWorksContainer = lazy(() => import('containers/HowItWorks/HowItWorksContainer'));
export const MyAccountContainer = lazy(() => import('containers/MyAccount/MyAccountContainer'));
export const SingPassContainer = lazy(() => import('containers/SingPass/SingPassContainer'));
export const ProfileContainer = lazy(() => import('containers/Profile/ProfileContainer'));
export const BillingHistoryContainer = lazy(() => import('containers/BillingHistory/BillingHistoryContainer'));
export const SingpassManualFormContainer = lazy(() => import('containers/SingpassManualForm/SingpassManualFormContainer'));
export const SingpassResultContainer = lazy(() => import('containers/SingpassResult/SingpassResultContainer'));

export const MyInformationContainer = lazy(() => import('containers/MyInformation/MyInformationContainer'));
export const SubscriptionsContainer = lazy(() => import('containers/Subscriptions/SubscriptionsContainer'));
export const InactiveSubscriptionsContainer = lazy(() => import('containers/InactiveSubscriptions/InactiveSubscriptionsContainer'));
export const OrdersContainer = lazy(() => import('containers/Orders/OrdersContainer'));
export const PaymentInfoContainer = lazy(() => import('containers/PaymentInfo/PaymentInfoContainer'));
export const ShippingInfoContainer = lazy(() => import('containers/ShippingInfo/ShippingInfoContainer'));
export const ReferAndEarnContainer = lazy(() => import('containers/ReferAndEarn/ReferAndEarnContainer'));
export const LuckyDrawContainer = lazy(() => import('containers/LuckyDraw/LuckyDrawContainer'));
export const AppleVisionProContainer = lazy(() => import('containers/AppleVisionPro/AppleVisionProContainer'));
export const FlashDealsProductContainer = lazy(() => import('containers/FlashDealsProduct/FlashDealsProductContainer'));
export const AssortmentContainer = lazy(() => import('containers/Assortment/AssortmentContainer'));
export const SearchContainer = lazy(() => import('containers/Search/SearchContainer'));
export const InvoiceContainer = lazy(() => import('containers/Invoice/InvoiceContainer'));
export const WhyCinchContainer = lazy(() => import('containers/WhyCinch/WhyCinchContainer'));
