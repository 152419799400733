import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    Input,
    Spinner
} from 'reactstrap';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import {
    handleLogout,
    loginModal,
    handleOpenSignUp
} from 'redux/reducers/auth';
import { handleFetchingCart, notifyHeaderOnCartUpdate } from 'redux/reducers/cart';
import { getAutocompleteProducts } from 'api/products';

import { ROUTES } from 'config/constants';
import { useQuery, setReferralParam, getAuthToken } from 'utils/helpers';
import SearchAdmin from 'containers/App/components/Header/SearchAdmin';
import { Autocomplete } from 'containers/App/components/Header/Autocomplete';

import basket from 'assets/images/general/purple-basket.svg';
import Logo from 'assets/images/general/cinch-new-logo.png';
import SearchIcon from 'assets/images/search-normal.png';

import InvoiceModal from '../InvoiceModal/InvoiceModal';

import './Header.scss';

const Header = ({
    loginModal,
    token,
    handleLogout,
    handleOpenSignUp,
    singpassStatus,
    productCategories,
    stateForBottomSheet
}) => {
    const history = useHistory();
    const query = useQuery();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const listRef = useRef(null);
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 1220px)').matches);
    const [isAutocompleteVisibile, setAutocompleteVisibility] = useState(false);
    const [categsList, setCategsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { products, notifyHeader } = useSelector(state => ({
        products: state.cart.fetchedProducts,
        notifyHeader: state.cart.notifyHeader
    }));
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useQueryParam('query', StringParam);
    const subcategories = productCategories && productCategories.map(item => item.children.map((x) => x));
    const subcatsArray = subcategories && [].concat(...subcategories);
    const allCats = subcatsArray && [...subcatsArray, ...productCategories];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 1220px)').matches);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target) && !isMobile) {
            setAutocompleteVisibility(false);
        }
    };

    const cookieExists = (name) => {
        return document.cookie.split(';').some(cookie => {
            const [cookieName] = cookie.trim().split('=');

            return cookieName === name;
        });
    };

    useEffect(() => {
        const isAuthed = getAuthToken();
        const exists = cookieExists('sk-authToken');

        if ((isAuthed !== '' && isAuthed !== null && notifyHeader && exists)) {
            dispatch(handleFetchingCart());

            if (notifyHeader) {
                dispatch(notifyHeaderOnCartUpdate(false));
            }
        } else if (token !== '' && token !== null && (location.pathname === ROUTES.HOME || location.pathname === ROUTES.PRODUCTS || location.pathname.indexOf('product') > -1)) {
            dispatch(handleFetchingCart());
            dispatch(notifyHeaderOnCartUpdate(false));
        }
    }, [location.pathname, dispatch, notifyHeader, token]);

    useEffect(() => {
        const forgot = query.get('forgot');

        if (forgot && forgot === 'true') {
            loginModal(true);
        }
    }, [loginModal, query]);

    const goToCart = () => history.push(ROUTES.CART);

    const goToMyAccount = () => {
        window.location.href = ROUTES.MYINFORMATION;
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const referralCode = queryParams.get('referral_code');

        if (referralCode !== null) {
            setReferralParam(referralCode);
        }
    }, []);

    useEffect(() => {
        if (searchQuery !== null && searchQuery !== '' && searchQuery !== undefined) {
            setSearch(searchQuery);
        }
    }, [searchQuery]);

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value !== null && e.target.value !== '' && e.target.value !== undefined) {
            const searches = JSON.parse(localStorage.getItem('searches')) || [];

            searches.push(search);
            localStorage.setItem('searches', JSON.stringify(searches));
            window.location.href = `/search?query=${search}`;
        }
    };

    const getProducts = async val => {
        setIsLoading(true);
        const prods = await getAutocompleteProducts(val);

        setProductsList(prods.data);
        setTimeout(() => setIsLoading(false), 1000);
    };

    const resetSearch = () => {
        setSearch('');
        setProductsList([]);
        setAutocompleteVisibility(false);
    };

    const handleRedirection = () => {
        if (pathname !== '/products') {
            window.location.href = `${ROUTES.PRODUCTS}?query=${search}&rental_period=12`;
        } else {
            if (!search) {
                setSearchQuery(undefined);
            } else {
                setSearchQuery(search);
            }
        }
    };

    const hideDrawerOnItemSelection = () => {
        setAutocompleteVisibility(false);
        setIsSheetOpen(false);
    };

    const handleSearch = e => {
        setProductsList([]);
        const { value } = e.target;

        setSearch(value);

        if (value.length > 2) {
            if (isMobile) {
                if (isSheetOpen) {
                    setTimeout(() => setAutocompleteVisibility(true), 1500);
                }
            } else {
                setAutocompleteVisibility(true);
            }

            getProducts(value);
            setCategsList(allCats.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setAutocompleteVisibility(false);
            setProductsList([]);
            setCategsList([]);

            if (value.length === 0) {
                resetSearch();
            }
        }
    };

    return (
        <div className='nav__bg' ref={listRef}>
            <Navbar className='d-flex justify-content-between align-items-center' light={true} expand='lg'>
                <NavbarBrand className={`${isMobile ? 'nav__log_container' : ''}`}>
                    <NavLink href={ROUTES.HOME}><img className='nav__logo' src={Logo} alt='Cinch Logo' /></NavLink>
                    {isMobile && <NavLink className='nav__link'>
                        <SearchAdmin productCategories={productCategories} stateForBottomSheet={stateForBottomSheet} />
                    </NavLink>}
                </NavbarBrand>
                <Collapse isOpen={isOpen} navbar={true} className='flex-jsb'>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink className='nav__link' href={ROUTES.HOW_IT_WORKS}>
                                How it Works
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className='nav__link'
                                href='/products'>
                                Products
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className='nav__link'
                                href='/deals'>
                                Deals
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className='nav__link'
                                href={ROUTES.FOR_BUSINESS}>
                                For Businesses
                            </NavLink>
                        </NavItem>
                        <NavItem className='d-flex justify-content-center align-items-center nav__link__search'>
                            {isLoading ? <Spinner /> : <img src={SearchIcon} alt='Search' />}
                            <Input value={search} autocomplete='off' name='search'
                                onChange={handleSearch} className='search__input' placeholder='Search for device'
                                onKeyDown={handleSearchKeyDown} />
                        </NavItem>
                    </Nav>
                    <Nav className='nav__start' navbar={true}>
                        <Button
                            className='nav__btn'
                            onClick={token === ''
                                ? () => {
                                    handleOpenSignUp();
                                    loginModal(true);
                                    setIsOpen(false);
                                }
                                : () => {
                                    goToMyAccount();
                                    setIsOpen(false);
                                }}>
                            {token === '' ? 'Sign In / Up' : 'My Account'}
                        </Button>{' '}
                        {token === '' ? null : (
                            <NavItem className='nav__col'>
                                <div className='loginBtn'>
                                    <span onClick={() => handleLogout()}>
                                        Log Out
                                    </span>
                                    {!isMobile &&
                                        singpassStatus &&
                                        singpassStatus !== 'rejected' && (
                                        location.pathname !== ROUTES.ORDER_CONFIRMATION
                                            ? <Badge count={products?.length}>
                                                <img
                                                    src={basket}
                                                    alt='Icon for Shopping Cart'
                                                    className='loginBtn__icon'
                                                    onClick={goToCart}/>
                                            </Badge> : <img
                                                src={basket}
                                                alt='Icon for Shopping Cart'
                                                className='loginBtn__icon'
                                                onClick={goToCart}/>
                                    )}
                                </div>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
            <InvoiceModal />
            {(!!productsList.length || !!categsList.length) &&
                isAutocompleteVisibile && (
                <Autocomplete
                    productsList={productsList}
                    categsList={categsList}
                    handleRedirection={handleRedirection}
                    propogateHideDrawer={hideDrawerOnItemSelection}
                    closeList={resetSearch} />
            )}
        </div>
    );
};

Header.propTypes = {
    loginModal: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    token: PropTypes.string,
    handleOpenSignUp: PropTypes.func,
    singpassStatus: PropTypes.any,
    productCategories: PropTypes.array,
    stateForBottomSheet: PropTypes.bool
};

const mapStateToProps = ({ auth }) => {
    const { token, profile } = auth;

    return {
        token,
        singpassStatus: profile.singpass_status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginModal: openLogin => dispatch(loginModal(openLogin)),
        handleLogout: () => dispatch(handleLogout()),
        handleOpenSignUp: () => dispatch(handleOpenSignUp())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
