import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DefaultErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return {
            isError: true
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isError: false
        };
    }

    render() {
        const { fallbackMessage, children } = this.props;
        const { isError } = this.state;

        if (isError) {
            return <div>{fallbackMessage}</div>;
        }

        return children;
    }
}

DefaultErrorBoundary.propTypes = {
    fallbackMessage: PropTypes.string,
    children: PropTypes.any
};

export default DefaultErrorBoundary;
