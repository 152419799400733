import React from 'react';

import './SufficientBalance.scss';

export default function SufficientBalance() {
    return (
        <div className='SufficientBalance'>
            <p>
                *Ensure your account maintains a <span className='suitable-balance'>suitable balance</span> to facilitate order fulfillment.
                Upon approval of your order, the initial monthly fee will be charged to your card.
            </p>
        </div>
    );
}
