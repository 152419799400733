import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export function Price({ price, setPrice }) {
    return (
        <div className='mb-4'>
            <p className='sidebar__label'>Price</p>

            <div
                className={classNames('sidebar__radio', {
                    'is-active': price === 'high'
                })}
                onClick={() => setPrice('high')} >
                <span /> High To Low
            </div>
            <div
                className={classNames('sidebar__radio', {
                    'is-active': price === 'low'
                })}
                onClick={() => setPrice('low')} >
                <span /> Low To High
            </div>
        </div>
    );
}

Price.propTypes = {
    price: PropTypes.string,
    setPrice: PropTypes.func
};
