import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { reviews } from './data';
import ReviewCard from './ReviewCard';

import HomePageViewAll from 'assets/images/home-page-view-all.png';
import StartBlack from 'assets/images/general/star-black.png';

import './Ratings.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#E3E3E3'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#5900D0'
    }
}));

Ratings.propTypes = {
    rating: PropTypes.object
};

function Ratings() {
    const ratingsRef = useRef(null);
    const [reviewsToShow, setReviewsToShow] = useState(3);

    const handleShowMoreLess = () => {
        if (reviewsToShow >= reviews.length) {
            setReviewsToShow(3);
            ratingsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            setReviewsToShow((prev) => prev + 3);
        }
    };

    return (
        <div className='ratings' ref={ratingsRef}>
            {/* <div className='ratings__rating d-none'>
                <p className='ratings__rating__title'>Rating & Review</p>
                <div className='ratings__rating__container'>
                    <div className='d-flex align-items-baseline'>
                        <span className='overall-rating'>4.4</span>
                        <img className='star' src={StartBlack} alt='star' />
                    </div>
                    <p className='mt-3 mb-0 font-14'>
                        <span className='me-1'>24 Reviews</span>|{' '}<span>40 Verified Buyers</span>
                    </p>
                    <div className='my-3'>
                        <p className='font-12 mb-2'>Quality and Condition</p>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <BorderLinearProgress variant='determinate' value={70} />
                        </Stack>
                    </div>
                    <div className='my-3'>
                        <p className='font-12 mb-1'>Performance and Working Condition</p>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <BorderLinearProgress variant='determinate' value={80} />
                        </Stack>
                    </div>
                </div>
            </div>
            <div className='ratings__reviews__container' style={{ height: reviewsToShow > 3 ? '100%' : '' }}>
                <div className='ratings__reviews'>
                    <p className='ratings__reviews__title'>
                        Ratings & Reviews
                    </p>
                </div>
                <div className={`ratings__reviews-comments ${reviewsToShow > 3 ? 'ratings__reviews-comments-expanded' : ''}`}>
                    {reviewsToShow < reviews.length ? <div className='ratings__reviews-comments-shadow' /> : null}
                    <div className='ratings__reviews-comments-cards'>
                        {reviews.slice(0, reviewsToShow).map((review) => (
                            <ReviewCard key={review.id} review={review} />
                        ))}
                    </div>
                </div>
                <div className='ratings__cta' onClick={handleShowMoreLess}>
                    {reviewsToShow >= reviews.length ? 'Show Less' : 'Show More'}
                    <img src={HomePageViewAll} alt='Carousel Right Arrow' />
                </div>
            </div>
            <div className='ratings__writeNew'>
                <div className='ratings__writeNew__container'>
                    <div className='flex-col'>
                        <p className='ratings__writeNew__title'>Share your thought with other customers</p>
                        <a className='ratings__writeNew__btn' href='https://g.page/r/Cbole4zs90GBEAI/review' target='_new'> {reviews.length > 0 ? 'Write a Review' : 'Be the first to write a review'} </a>
                    </div>
                </div>
            </div> */}
            <div className='elfsight-app-a2d66b2a-2d95-4813-bd47-cf0cf14c9727' data-elfsight-app-lazy='true' />
        </div>
    );
}

export default Ratings;
