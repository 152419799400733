import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

const UtmParamsChecker = ({ isAuthed }) => {
    const location = useLocation();
    const [userActive, setUserActive] = useState(true);

    // const hsaFields = ['hsa_acc', 'hsa_cam', 'hsa_grp', 'hsa_ad', 'hsa_src', 'hsa_net', 'hsa_ver', 'fbclid'];
    const utmFields = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term', 'hsa_acc', 'hsa_cam', 'hsa_grp', 'hsa_ad', 'hsa_src', 'hsa_net', 'hsa_ver', 'fbclid', 'irclickid'];

    // Time to consider user as idle (30 minutes)
    const idleTimeout = 30 * 60 * 1000;

    const clearUtmsFromUrl = () => {
        // clear only UTM params from URL
        const params = new URLSearchParams(location.search);

        utmFields.forEach(field => {
            if (params.has(field)) {
                params.delete(field);
            }
        });

        const newParams = params.toString();

        if (newParams) {
            window.history.replaceState({}, '', `${location.pathname}?${newParams}`);
        } else {
            window.history.replaceState({}, '', location.pathname);
        }
    };

    const updateLastActiveTime = () => {
        const currentTime = Date.now();

        Cookies.set('lastActiveTime', currentTime, { expires: 1 / 2 });
    };

    const checkInactivity = () => {
        const lastActiveTime = Cookies.get('lastActiveTime');

        if (lastActiveTime) {
            const currentTime = Date.now();
            const elapsedTime = currentTime - lastActiveTime;

            if (elapsedTime >= idleTimeout) {
                utmFields.forEach(field => localStorage.removeItem(field));
                Cookies.remove('lastActiveTime');
                clearUtmsFromUrl();
                setUserActive(false);
            }
        }
    };

    const getUtmParamsFromLocalStorage = () => {
        const utmValues = Object.fromEntries(
            utmFields.map(field => [field, localStorage.getItem(field)]).filter(([_, value]) => value)
        );

        if (Object.values(utmValues).some(value => value !== null && value !== undefined)) {
            const utmUrlString = `?${new URLSearchParams(utmValues).toString()}`;

            // append UTM params to URL
            window.history.replaceState({}, '', `${location.pathname}${utmUrlString}`);
        }
    };

    const getUtmParamsFromUrl = () => {
        const params = new URLSearchParams(location.search);

        const utmValues = Object.fromEntries(
            utmFields.map(field => [field, params.get(field)]).filter(([_, value]) => value)
        );

        if (Object.keys(utmValues).length > 0) {
            Cookies.remove('lastActiveTime');
            Object.entries(utmValues).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
        } else {
            // if no UTM parameters found in the URL, try retrieving from localStorage
            return getUtmParamsFromLocalStorage();
        }
    };

    const clearUtms = () => {
        // clear UTM params from URL
        window.history.replaceState({}, '', location.pathname);

        // clear UTM params from localStorage
        utmFields.forEach(field => localStorage.removeItem(field));
    };

    useEffect(() => {
        // if (isAuthed) return clearUtms();

        getUtmParamsFromUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        // Initialize last active time on component mount
        if (utmFields.some(field => localStorage.getItem(field)) && userActive) {
            checkInactivity();
            updateLastActiveTime();

            // Attach event listeners for user activity
            window.addEventListener('mousemove', updateLastActiveTime);
            window.addEventListener('keydown', updateLastActiveTime);

            // Check for inactivity at regular intervals
            const intervalId = setInterval(checkInactivity, 1000); // Check every second

            return () => {
                // Cleanup on component unmount
                clearInterval(intervalId);
                window.removeEventListener('mousemove', updateLastActiveTime);
                window.removeEventListener('keydown', updateLastActiveTime);
            };
        }
    }, [userActive]);

    return null;
};

UtmParamsChecker.propTypes = {
    isAuthed: PropTypes.bool.isRequired
};

export default UtmParamsChecker;
