import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import Logo from 'assets/images/general/cinch-new-logo.png';

import Facebook from 'assets/images/general/facebook.svg';
import Instagram from 'assets/images/general/instagram.svg';
import Linkedin from 'assets/images/general/linkedin.svg';

import './Footer.scss';
import { is } from 'date-fns/locale';

export default function Footer() {
    const history = useHistory();
    const isMobile = window.matchMedia('(max-width: 992px)').matches;
    const isSmMobile = window.matchMedia('(max-width: 767px)').matches;
    const location = useLocation();
    const isProductDetail = location.pathname.includes('/product/') || location.pathname.includes('/deals/') || location.pathname.includes('/flash-deals/');

    return (
        <div className='footer'>
            <div className='footer__bg' style={{ paddingBottom: isProductDetail ? isSmMobile ? '6rem' : '9rem' : '' }}>
                <div className='footer__container'>
                    <div className='footer__container__col-1'>
                        <img src={Logo} className='footer__logo' onClick={() => { window.location.href = ROUTES.HOME; }}/>
                        <div className='footer__address-container footer__address-container__desktop'>
                            <p className='mb-2'>160 Robinson Road, 14-04, 068593,<br/>Singapore</p>
                            <p className='mb-0'>UEN: 202221862D</p>
                        </div>
                    </div>
                    <div className='footer__container__col-2'>
                        <div className='footer__container__col-2__links'>
                            <div className='footer__links-container'>
                                <a className='footer__link' href={ROUTES.HOME}>Home</a>
                                <a className='footer__link' href={ROUTES.PRODUCTS}>View All Products</a>
                                <a className='footer__link' href={ROUTES.FOR_BUSINESS}>For Businesses</a>
                                <a className='footer__link' href={ROUTES.ABOUT_US}>About Us</a>
                            </div>
                            <div className='footer__links-container'>
                                <a className='footer__link' href={ROUTES.FAQ}>FAQ</a>
                                <a className='footer__link' href={ROUTES.HOW_IT_WORKS}>How it Works</a>
                                <a className='footer__link' href={ROUTES.TERMS_AND_CONDITIONS}>Terms & Conditions</a>
                                <a className='footer__link' href={ROUTES.PRIVACY_POLICY}>Privacy Policy</a>
                                <a className='footer__link' href={ROUTES.WHY_CINCH}>Why Cinch</a>
                            </div>
                        </div>
                        <div className='footer__container__col-2__ctas'>
                            <a className='footer__btn footer__btn-filled' href={ROUTES.CONTACTUS}>Contact Us</a>
                            <a className='footer__btn footer__btn-outline' rel='noreferrer' href='https://g.page/r/Cbole4zs90GBEAI/review'
                                target='_blank'>Leave a Review</a>
                        </div>
                    </div>
                    <div className='footer__address-container footer__address-container__mobile'>
                        <p className='mb-2'>160 Robinson Road, 14-04, 068593,<br/>Singapore</p>
                        <p className='mb-0'>UEN: 202221862D</p>
                    </div>
                </div>
                <div className='footer__copyright'>
                    <p className='footer__copyright__text'>© {new Date().getFullYear()} Cinch Tech Holding Pte. Ltd. All rights reserved.</p>
                    <div className='footer__icons-container'>
                        <a
                            href='https://www.facebook.com/cinchtechsg'
                            target='_blank'
                            rel='noreferrer'>
                            <img
                                className='footer__icon'
                                src={Facebook}
                                alt='Facebook'/>
                        </a>
                        <a
                            href='https://www.instagram.com/cinchtechsg/'
                            target='_blank'
                            rel='noreferrer'>
                            <img
                                className='footer__icon'
                                src={Instagram}
                                alt='Instagram'/>
                        </a>
                        <a
                            href=' https://www.linkedin.com/company/cinch-asia'
                            target='_blank'
                            rel='noreferrer'>
                            <img
                                className='footer__icon'
                                src={Linkedin}
                                alt='LinkedIn'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
