import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TradeInReqModal from 'components/TradeInReqModal/TradeInReqModal';
import { TRADE_IN_DEVICES, STORAGEKEY_TRADE_IN_DEVICE } from 'config/constants';

import './TradeIn.scss';

TradeIn.propTypes = {
    setIsTradeInApplied: PropTypes.func
};

function TradeIn({ setIsTradeInApplied }) {
    const [selectedTradeIn, setSelectedTradeIn] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => setIsOpen(!isOpen);

    const storeTradeInDevice = (deviceKey) => {
        setSelectedTradeIn(deviceKey);
        setIsTradeInApplied(deviceKey !== 'none');
        localStorage.setItem(STORAGEKEY_TRADE_IN_DEVICE, deviceKey);
    };

    useEffect(() => {
        const tradeInDeviceKey = localStorage.getItem(STORAGEKEY_TRADE_IN_DEVICE);

        storeTradeInDevice(tradeInDeviceKey || 'none');
    }, []);

    return (
        <>
            <div className='trade-in'>
                <div className='d-flex justify-content-between'>
                    <p className='trade-in__label'>Trade In</p>
                    <p className='learn-more learn-more-mob' onClick={() => onToggle()}>Learn more</p>
                </div>
                <div className='w-100 trade-in__options-container'>
                    <div className='description-container'>
                        <p className='trade-in__description'>Simply trade in your old device for up to <span className='strong'>SGD 300</span> cashback. It&apos;s a win-win deal you can&apos;t afford to miss!</p>
                        <p className='learn-more' onClick={() => onToggle()}>Learn more</p>
                    </div>
                    <div className='trade-in__options'>
                        {TRADE_IN_DEVICES.map((device) => (
                            <div key={device.key} className={`option ${selectedTradeIn === device.key ? 'option__selected' : ''}`}
                                onClick={() => storeTradeInDevice(device.key)}>
                                <p className='title'>{device.series}</p>
                                {device?.cashback && <>
                                    <div className='divider'/>
                                    <div className='cashback-amount'>
                                        <p className='cashback'>Cashback</p>
                                        <p className='amount'>{device?.cashback}</p>
                                    </div>
                                </>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <TradeInReqModal isOpen={isOpen} onToggle={onToggle}/>
        </>
    );
}

export default TradeIn;
