import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import * as reducers from 'redux/reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

export const history = createBrowserHistory();

// to download extension -  https://github.com/reduxjs/redux-devtools
const composeEnhancers = composeWithDevTools({});

const middleWaresWithoutDevTools = applyMiddleware(routerMiddleware(history), thunk);

const middleWares = process.env.REACT_APP_ENV === 'production' ? middleWaresWithoutDevTools : composeEnhancers(middleWaresWithoutDevTools);

export const store = createStore(
    combineReducers({
        router: connectRouter(history),
        ...reducers
    }),
    compose(middleWares, window.devToolsExtension ? window.devToolsExtension() : f => f)
);
