import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export function Period({ period, setPeriod }) {
    return (
        <div className='mb-4 mt-5'>
            <p className='sidebar__label'>Minimum Rental Period</p>

            <div className='sidebar__btn-group'>
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center flex-column',
                        { 'period-is-active': period === '1' }
                    )}
                    onClick={() => setPeriod('1')} >
                    <p className='sidebar__btn-group-no'>1</p>
                    <p className='sidebar__btn-group-period'>Month</p>
                </div>
                <span className='sidebar__divider' />
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center flex-column',
                        { 'period-is-active': period === '3' }
                    )}
                    onClick={() => setPeriod('3')} >
                    <p className='sidebar__btn-group-no'>3</p>
                    <p className='sidebar__btn-group-period'>Months</p>
                </div>

                <span className='sidebar__divider' />
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center flex-column',
                        { 'period-is-active': period === '6' }
                    )}
                    onClick={() => setPeriod('6')} >
                    <p className='sidebar__btn-group-no'>6</p>
                    <p className='sidebar__btn-group-period'>Months</p>
                </div>
                <span className='sidebar__divider' />
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center flex-column',
                        { 'period-is-active': period === '12' }
                    )}
                    onClick={() => setPeriod('12')} >
                    <p className='sidebar__btn-group-no'>12</p>
                    <p className='sidebar__btn-group-period'>Months</p>
                </div>
                {/* <span className='sidebar__divider' />
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center flex-column',
                        { 'period-is-active': period === '18' }
                    )}
                    onClick={() => setPeriod('18')} >
                    <p className='sidebar__btn-group-no'>18+</p>
                    <p className='sidebar__btn-group-period'>Month</p>
                </div> */}
            </div>
        </div>
    );
}

Period.propTypes = {
    period: PropTypes.string,
    setPeriod: PropTypes.func
};
