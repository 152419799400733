import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState } from 'react';

import './Brands.scss';

export function Brands({ brands, handleBrands, brandsQuery }) {
    const [showAll, setShowAll] = useState(false);

    const orderedBrands = brands.sort((a, b) => a.name.localeCompare(b.name));
    const filteredBrands = brands.filter(brand => brand.id < 10);

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const options = (brands) => {
        return brands.map(item =>
            <div
                className={classNames('sidebar__checkbox', {
                    'is-active': brandsQuery?.includes(item.id.toString())
                })}
                key={item.id}
                onClick={() => handleBrands(item.id.toString())}>
                <span /> {item.name}
            </div>);
    };

    return (
        <div className='mb-4 mt-5'>
            <p className='sidebar__label'>Brands <span onClick={handleShowMore} className='brands__button'>{showAll ? 'Show Less' : 'Show All'}</span></p>
            {(showAll && !!brands?.length) ? options(orderedBrands) : options(filteredBrands)}
            { }
        </div>
    );
}

Brands.propTypes = {
    brands: PropTypes.array,
    handleBrands: PropTypes.func,
    brandsQuery: PropTypes.array
};
