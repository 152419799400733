// import i18next from 'i18next';
import {
    AUTH_LOGIN_401, AUTH_LOGIN_422, AUTH_REGISTER_422,
    PASSWORD_FORGOT_404, PASSWORD_RESET_404, PASSWORD_RESET_422
} from './messages-error';

export function getMessageByResponseError({ status, endpoint }) {
    endpoint = endpoint.split('/');

    switch (endpoint[0]) {
        case 'auth': {
            switch (endpoint[1]) {
                case 'login': {
                    switch (status) {
                        case 401: return AUTH_LOGIN_401;
                        case 422: return AUTH_LOGIN_422;
                        default: return false;
                    }
                }

                case 'register': {
                    switch (status) {
                        case 422: return AUTH_REGISTER_422;
                        // case 422: return i18next.t('AUTH_REGISTER_422');
                        default: return false;
                    }
                }

                default: return false;
            }
        }

        case 'password': {
            switch (endpoint[1]) {
                case 'forgot': {
                    switch (status) {
                        case 404: return PASSWORD_FORGOT_404;
                        default: return false;
                    }
                }

                case 'reset': {
                    switch (status) {
                        case 404: return PASSWORD_RESET_404;
                        case 422: return PASSWORD_RESET_422;
                        default: return false;
                    }
                }

                default: return false;
            }
        }

        default: return false;
    }

    // uncomment to check for "no-unreachable"
    // return false;
}
