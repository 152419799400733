export const categories = [
    {
        key: 'general',
        label: 'General'
    },
    {
        key: 'account',
        label: 'Account'
    },
    {
        key: 'manage_your_device',
        label: 'Managing Your Device, Insurance & Repairs'
    },
    {
        key: 'returns',
        label: 'Returns'
    },
    {
        key: 'customer_support',
        label: 'Customer Support & Misc.'
    }
];

export const content = {
    general: [
        {
            id: 33,
            title: 'How does it work?',
            text:
                '   1. Choose your device.\n' +
                '   2. Select the preferred rental period and device specifications. \n' +
                '   3. Add to cart.\n' +
                '   4. Create an account and click proceed to order.\n' +
                '   5. Finalise payment and delivery details.\n' +
                '   6. Check your email to receive updates on your order status.\n' +
                '   7. Receive the device at your doorstep!\n\n' +
                'Please note that Cinch’s subscriptions are available to you if  you are:\n\n' +
                '    - A Singaporean Citizen\n' +
                '    - A Permanent Resident (PR)\n' +
                '    - Employment Pass Holder (EP)\n' +
                '    - S Pass Holder\n\n' +
                'We require customers to complete an identity verification (i.e. SingPass MyInfo) and credit check process.\n\n' +
                'If you have any further questions, please do not hesitate to contact our customer support at +65 8168 8604 or email us at ',
            link: 'support@cinch.sg',
            text2: ' We will get back to you within 1 working day.'
        },
        {
            id: 34,
            title: 'Which payment methods do you accept?',
            text: 'We accept Direct Debit, Visa, MasterCard or American Express card. We do not accept any prepaid cards.'
        },
        {
            id: 35,
            title: 'Is the product brand new?',
            text: 'We guarantee as good as new conditions for all our devices. If you feel the product you received does not meet that promise, send it back within three (3) days and we will provide you a new one free of charge.'
        },
        {
            id: 36,
            title: 'When do I pay the first month’s rent?',
            text:
                'Your rent is always paid monthly in advance. The first rental payment is made when your order request has been confirmed. All subsequent rental payments are made monthly from the date of the delivery of the product. You will only pay for the period you have the product.\n\n' +
                'Example based on a three month rental period: \n\n' +
                '15/02/2022 placed order and order is confirmed  - first payment is deducted on this day 18/02/2022 - the day the device is delivered to you.\n\n' +
                'Your subsequent payments are made on the 18th of each month afterwards: \n\n' +
                '18/3/2022\n\n' +
                '18/4/2022'
        },
        {
            id: 37,
            title: 'How long will it take to deliver my order?',
            text: 'We know you are excited and we want you to start using your product as soon as possible! As soon as your order is completed and approved, you should receive your order within 3-5 working days*\n\n' +
            'If you have any queries regarding your order, please reach out via our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: ' and we will assist you from there.\n\n' +
            '*Please note that that pre-orders will take a little longer, as they are subject to manufacturer shipment dates.'
        },
        {
            id: 38,
            title: 'Can I buy my rented device?',
            text: 'If you want to buy the product you are renting, all of the rental payments you have made so far will be credited towards your purchase.\n\nThere are two ways to buy:\n\n' +
            '1. The first option is to rent your device long enough until you can purchase it for SGD $1. As we credit your rental payments towards a potential purchase, once your rental payments reach the purchase threshold, we offer the option to buy the device for $1. When you reach the purchase threshold, we will send you an email to notify you.\n\n' +
            '2. The second option is to purchase your device during the last month of your rental term. We credit the rental payments you have made towards the final purchase price. This option will become accessible in your account settings during the last month of your term.\n\n' +
            'The purchase price in option 2 is transparently set at a 25% premium above recommended retail price. All of your previous rental payments will be credited towards the purchase price.\n\n' +
            'If you have any queries regarding your order, please reach out via our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: ' and we will assist you from there.'
        },
        {
            id: 39,
            title: 'Can I purchase the product immediately?',
            text: 'No, Cinch is a rental model for consumer tech that provides you the flexibility to purchase after you have committed to a minimum rental period.\n\n' +
            'If you are keen to purchase immediately, we recommend going to your local retailer.'
        },
        {
            id: 40,
            title: 'How do you calculate the pricing for buying a rented device?',
            text: 'The purchase price is transparently set at a 25% premium above recommended retail price. All of your previous rental payments will be credited towards the purchase price. '
        },
        {
            id: 41,
            title: 'Can I reschedule the delivery timing?',
            text: 'We do not recommend rescheduling of delivery as the delivery is by outsource delivery partners. However, should you require assistance on that, please do not hesitate to contact us via WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: '.'
        },
        {
            id: 42,
            title: 'How do you make sure my data is safe?',
            text: 'We only use your data to manage your relationship with Cinch and our services. Through Singpass we receive standard-format personal data including income information. We only use privacy and encryption protocols that are approved by Singpass. We take data privacy extremely seriously. For full details on how we protect and manage your data, please refer to our ',
            link: 'privacy policy',
            text2: '.'
        },
        {
            id: 47,
            title: 'Can I upgrade to another model or changed of mind after receiving my order?',
            text: 'If you change your mind about an order you just placed, you can cancel and return your product (in good condition and in the original packaging) within 3 days of receiving the order.\n\n' +
            'If you want to end your subsription early or request to switch to another model, but after the 3-day return window, it will be considered as an early upgrade and it will be considered as an early termination/upgrade fee. You will have to pay an early termination fee of SGD 250.\n\n' +
            'For all cancellation and early upgrade/termination issues, please tell our support team your reason for cancelling and we’ll let you know how to proceed.\n\n' +
            'Please reach out to our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg'
        }
    ],
    account: [
        {
            id: 22,
            title: 'Am I eligible to subscribe to Cinch?',
            text:
                "Cinch's subscriptions are available to you if you are:\n\n" +
                '    - A Singapore Citizen\n' +
                '    - A Permanent Resident (PR)\n' +
                '    - Holding an Employment Pass (EP)\n' +
                '    - Holding an S Pass (SP)\n\n' +
                'We will contact you through email if we need more information on your account. You must also pass our identity verification (i.e. SingPass Myinfo) and credit checks. We will contact you through email if we need more information on your account. '
        },
        {
            id: 11,
            title: 'How long is the approval process?',
            text: 'Once an order is placed, it will take 24-48 hours for order approval. The delivery will take 3-5 working days after order confirmation (except pre-orders). You will receive an email update at every step of the way (order confirmation, delivery, etc).\n\n' +
            'If you encounter any issue while placing an order, please do not hesitate to contact our customer support at +65 8168 8604 or email us at ',
            link: 'support@cinch.sg'
        },
        {
            id: 23,
            title: 'Help! I’ve forgotten my password.',
            text: 'No problem, you can reset your password by following this ',
            link: 'forgot_password',
            text2: '.'
        },
        {
            id: 24,
            title: 'Can I increase my credit limit?',
            text:
                'Credit limits are naturally increased for our existing customers with good payment behaviour.\n\n' +
                'Alternatively, if you have recently switched jobs with a higher salary, you can reach out to us at ',
            link: 'support@cinch.sg',
            text2: ' to re-assess your credit limit. All reviews are subject to approval.'
        },
        {
            id: 25,
            title: 'I would like to update my mobile number.',
            text:
                'You can update your mobile number in your Cinch account. Simply log into your Cinch account, click on "My Account", update your mobile number and an OTP will be sent to your new number.\n\n' +
                'If you have any issues updating it, please reach out via our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: ' and we will assist you from there.'
        },
        {
            id: 43,
            title: 'What if I am late on my payments?',
            text: 'If you fail to pay the Fees for the product by the relevant due date (i.e. same recurring date each month during the Rental Period following the receipt of your product), you will be charged a late payment fee of SGD 15 for each late payment.\n\nPlease note: Repeated delays in payment will be reported to the credit bureau in Singapore. This will impact your credit score and lead to the suspension of your Cinch account until your entire overdue amount, including late fees, is paid in full.'
        },
        {
            id: 44,
            title: 'I want to add a new payment card.',
            text: 'You will be able to add a new payment card in your cinch account.\n\n' +
                '   1. Go to: ',
            link: 'cinch.sg',
            text2: '\n    2. Sign in and click on "My Account"\n' +
                '   3. Click on "Payment Info"\n' +
                '   4. Click on "Add a new card\n\n' +
                'As currently you are unable to set the card as default on the website, please reach out to us via WhatsApp at +65 8168-8604 send us an email at ',
            link2: 'support@cinch.sg',
            text3: ' and provide us the last 4 digit number, we will set your preferred card as the default card for you.'
        },
        {
            id: 45,
            title: 'What is Notice of Assessment (NOA)?',
            text: 'The Notice of Assessment includes the following information:\n\n' +
                'Assessable Income: This is the overall amount of money earned from renting out your property to others, including wages and interest.\n' +
                'Personal Reliefs: The personal reliefs that Singaporean taxpayers receive from the tax system are distinct from the deductibles that are available to corporations.\n' +
                'Chargeable Income: Your personal taxes are charged on the amount of chargeable income that you earn in the NOA for people. The amount is calculated by subtracting reliefs from your Assessable Income, the same as we\'ve seen with businesses.\n' +
                'Tax Payable: The total amount of tax you owe is determined by comparing your Chargeable Income to the appropriate personal income tax rates.'
        },
        {
            id: 46,
            title: 'Can I cancel my order after placing an order? ',
            text: 'If you change your mind about an order you just placed, you can cancel and return your product (in good condition and in the original packaging) within 3 days of receiving the order.\n\n' +
                'If you want to end your rental early, but after the 3-day return window, you will have to pay an early termination fee of SGD 250.' +
                'For all cancellation and early termination issues, please tell our support team your reason for cancelling and we’ll let you know how to proceed.\n\n' +
                'Please reach out to our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: '.'
        }
    ],
    manage_your_device: [
        {
            id: 4,
            title: 'Can I change my chosen minimum rental period after I have received my device?',
            text: 'You have the option to extend or renew your rental during the last month of your term. Moving forward, your monthly rental rate will reflect that of the newly selected term. Unfortunately, you cannot shorten your rental term without terminating your rental. This can be done at any time after the initial 3-day return window. However, it is important to note that this will incur an early termination fee of SGD 250. If you wish to extend or terminate your rental, please reach out to our WhatsApp at +65 8168-8604 with your desired change or send us an email at ',
            link: 'support@cinch.sg'
        },
        {
            id: 5,
            title: 'How does Cinch insurance work?',
            text: 'Cinch Insurance covers 90% of all repair costs due to accidental damage and 100% of natural wear and tear.\n\nIn the event of theft, loss or wilful damage, you will be responsible for the full cost of the device at the prevailing retail price.\n\nOnce you have returned the product in its original packaging to us, all you have to do is sit back and relax. We will go through the following steps and notify you if there are any additional costs related to your rental:\n\n   1.   Our Cinch Repair Team professionally audits the device and repairs any damages.\n   2.   Reconciliation & billing: we look at both assessments and charge you 10% of the repair costs incurred for accidental damages only.\n\n' +
            'If we determine that there has been water damage or the product is beyond repair due to serious damage, we will charge you the full retail price of the product. Please enjoy our product during your rental period, but please don’t destroy it!\n\n' +
            'For devices requiring repair, simply reach out to our customer support via WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: 'to receive a replacement device at no additional cost.'
        },
        {
            id: 17,
            title: 'What happens if I lose my device or if it’s stolen?',
            text: 'Unfortunately, we do not reimburse for theft or loss of devices. You will be responsible for paying the retail price to replace the device. \n\nHowever, you may be able to get your own liability insurance that covers loss and theft. Ask your insurance provider about covering these costs for rented items. '
        },
        {
            id: 15,
            title: 'What happens if my product is damaged and what type of damages are covered by Cinch insurance?',
            text: "We cover 100% of costs associated with natural minor wear and tear and any device errors from the manufacturers.\n\nCinch Insurance covers 90% of all repair and replacements costs due to accidental damage. This includes:\n\n    - Broken display\n    - Strong signs of wear and tear\n\nYou will only be charged 10% of refurbishment costs resulting from such accidental damages. Intentional damage and loss/theft are not covered by Cinch Insurance. In the event this happens, you will be responsible to pay the replacement cost of the device.\n\nIf your device is damaged, don't worry – we’re here to help. Just follow the following steps and we’ll have your device fixed up and back in your hands in no time.\n\n    1. Report damage to us within 48 hours.\n    2. We will arrange for the device to be collected from you.\n    3. The device will undergo several checks to assess damage.\n    4. We’ll look at multiple assessments, and charge you for the 10% of the repair cost.\n    5. Our repair partners will fix the device, check it thoroughly, and we’ll send it back to you.\n\nIf we determine that there has been water damage or the product is beyond repair due to serious damage, we will charge you the full retail price of the product. Please enjoy our product during your rental period, but please don’t destroy it! "
        },
        {
            id: 25,
            title: 'Who is responsible for repairs to the device?',
            text: 'We manage all repairs on your device. If there is an issue, please contact us, we will collect the device and repair it. Since Cinch Insurance covers 90% of the repair cost of accidental damages, we’ll only charge you for the remaining 10%. Please do not try to repair the device yourself or use your own repair service; we will not cover devices repaired by other parties. '
        },
        {
            id: 26,
            title: 'Can I repair or modify my device by myself?',
            text: 'We appreciate the thought, but please do not do this. Because we try to keep our devices in the best condition for all the device’s owners, we only allow repairs and refurbishments done by the Cinch Repair Team. We do not accept the return of devices that have been repaired, modified or refurbished externally, and would require you to purchase the device from us. '
        },
        {
            id: 27,
            title: 'I didn’t return, extend, or purchase my device, but my rental period has ended. What happens next?',
            text: 'If you did not take action, but the rental term has ended, you will automatically be switched to a pay-as-you-go plan at the same monthly rate. You will still have the option to return the device, purchase it, or extend the rental at any point. '
        }
    ],
    returns: [
        {
            id: 7,
            title: 'How and when do I return my device?',
            text: 'To return your device, you need to cancel your subscription within the last 30 days of your rental term. Before the last date of your rental term, you must schedule a return with us to occur within 5 working days of the last day of your term. You will be charged pay-as-you-go in the following month should you choose to return the device later than the 5 day return window. Please make sure you reset your device, and return it with its accessories in the original packaging. Failure to do so will incur a $50 resetting fee. You can find the return label in your account by:\n\n    Go to My Account > My Active Subscription > Select the device > Select "Return"\n\nPlease remember to:\n\n    1. Place the product with the accessories in the original packaging.\n    2. Reset your device to factory conditions and ensure that all your data has been erased.\n\nThis way, we can rent the device safely to the next customer. Please stow the device safely in the original packaging and stick the label onto the box. Remember to send only 1 device per box. On the day of delivery, please be present to give the box to our courier. To avoid paying for another month, please make sure to not miss the pick-up. As soon as your box is processed by the delivery carrier, your monthly payments will be stopped. Your rental ends when we get the product back and check that everything is okay. '
        },
        {
            id: 28,
            title: 'How long does it take to refund my money?',
            text: 'It will take approximately 5-10 business days for the amount to be reflected in your account. Please contact your bank directly should you have any questions regarding the refund as we do not have visibility on that. '
        },
        {
            id: 18,
            title: 'How do I prepare my iPhone or iPad for return?',
            text: "Before you send your rented iPhone or iPad back to us, you must reset the device, remove all personal data and the activation lock. If you do not do this, we will not be able to reset the device and will have to charge you a $50 reset fee.\n\nOnce the device has been reset, please place it, along with any accessories, in the original packaging.\n\n    1. Backup your device if you have important data stored on it.\n    2. If you paired your iPhone with an Apple Watch, unpair the Apple Watch first.\n    3. Sign out of iCloud and “iTunes & App Store”:\n       • If you're using iOS 10.3 or later, tap Settings > name > Sign Out > Enter your Apple ID password and tap Turn Off.\n       • If you're using iOS 10.2 or earlier, tap Settings > iCloud > Sign Out. Tap Sign Out again, then tap Delete from My device name and enter your Apple ID password. Then go to Settings > iTunes & App Store > Apple ID > Sign Out.\n    4. Go back to Settings and tap General > Reset > Erase All Content and Settings. If you turned on Find My, you might need to enter your Apple ID and password.\n    5. If asked for your device passcode or restrictions passcode, enter it. Then tap ‘Erase device name’.\n    6. In order to remove your old device from your list of trusted devices, open iCloud.com, and:\n\        • Go to Settings > your name.\n         • Select a device from the list.\n         • Tap the X next to your device to remove it from the list.\n\nIf you’ve removed your iPhone or iPad correctly, Find My for the device and Activation Lock should be turned off.\n\nFor more details, please refer to the ",
            link: 'official Apple instructions.'
        },
        {
            id: 19,
            title: 'How do I unpair my Apple watch?',
            text: "Please reset the device to factory settings before returning it to us. This protects your data and allows us to refurbish the device so we can send it to the next person. If you do not do this, we will not be able to reset the device and will have to charge you a $50 reset fee. Once the device has been reset, please place it, along with any accessories, in the original packaging. Then print out the label, attach it to the box, and pass it to our courier.\n\nThis is how to unpair your Apple Watch:\n\n    1. Keep your Apple Watch and iPhone close together as you unpair them.\n    2. Open the Watch app on your iPhone.\n    3. Go to the My Watch tab and tap All Watches.\n    4. Tap the info button next to the watch you want to unpair.\n    5. Tap Unpair Apple Watch.\n    6. For GPS + Cellular models, remove your mobile data plan. Remember to cancel your plan if you’re no longer using it.\n    7. Tap again to confirm.\n\nYou may need to enter your Apple ID password to disable Activation Lock. Before erasing all content and settings on your Apple Watch, your iPhone creates a new backup of your Apple Watch. You can use the backup to restore a new Apple Watch. After your Apple Watch unpairs, you'll see the Start Pairing message.\n\nFor more details, please refer to the ",
            link: 'official Apple instructions.'
        },
        {
            id: 32,
            title: 'How do I prepare my Macbook for return?',
            text: 'Please reset the device to factory settings before returning it to us. This protects your data and allows us to refurbish the device so we can send it to the next person. If you do not do this, we will not be able to reset the device and will have to charge you a $50 reset fee. Once the device has been reset, please place it, along with any accessories, in the original packaging. Then print out the label, attach it to the box, and pass it to our courier. \n\nTo reset the device, please refer to the ',
            link: 'official Apple instructions.'
        },
        {
            id: 20,
            title: 'How do I prepare my Android smartphone or tablet for return?',
            text: 'Please reset the device to factory settings before returning it to us. This protects your data and allows us to refurbish the device so we can send it to the next person. If you do not do this, we will not be able to reset the device and will have to charge you a $50 reset fee. Once the device has been reset, please place it, along with any accessories, in the original packaging. Then print out the label, attach it to the box, and pass it to our courier.\n\nTo reset the device, please refer to the ',
            link: 'official Google instructions.'
        },
        {
            id: 29,
            title: 'I forgot to reset my device or delete my data. How can I ensure my data is safe?',
            text: 'If you forgot to reset your device before returning it to us, we will reach out to coordinate a way to unlock and reset it. However, be aware that as we will not be able to refurbish the device until it has been reset, we will have to charge a $50 reset fee.\n\nEvery device undergoes a thorough check before it is sent to any customer. We never send out devices that have anyone’s personal data stored on it. '
        },
        {
            id: 30,
            title: 'I threw away or damaged the original packaging of the device. How should I return my device?',
            text: 'If you threw away or damaged the original packaging, please pack it safely in a box of your choosing. If you are unable to find a box that safely fits the device, please contact our customer service and we can arrange one to be sent to you. '
        },
        {
            id: 31,
            title: 'What happens to my device after I return it?',
            text: 'After you return your device, it undergoes a thorough refurbishment process. After it passes our quality checks, we then find it a new home by renting it to another Cinch customer. Once a device has passed its usable life, we make sure it is recycled responsibly through our recycling partners.'
        }
    ],
    customer_support: [
        {
            id: 14,
            title: 'How can I contact Cinch’s customer support?',
            text: 'Please feel free to reach out to our customer support via WhatsApp at +65 8168-8604 or email us at ',
            link: 'support@cinch.sg',
            text2: ' We will get back to you within 1 working day.'
        },
        {
            id: 21,
            title: 'My question is not listed.',
            text: 'If your question is not listed here, please contact us and we will do our best to help.\n\nYou can contact us via our WhatsApp at +65 8168-8604 or send us an email at ',
            link: 'support@cinch.sg',
            text2: '.'
        }
    ]
};
