import React from 'react';
// import PropTypes from 'prop-types';
import style from './FormMessage.module.scss';
import logo from 'assets/images/general/logo.svg';
import Button from 'containers/App/components/Button/Button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config/constants';

// FormMessage.propTypes = {

// };

function FormMessage() {
    const history = useHistory();

    return (
        <div className={style.message}>
            <div className={style.message__logo}>
                <img src={logo} alt='Cinch logo' />
            </div>
            <div className={style.message__text}>
                <div className={style.message__lines}>
                    To access all features of Cinch,
                </div>
                <div className={style.message__lines}>
                  please complete your profile.
                </div>
            </div>
            <div className={style.message__row}>
                <div className={style.message__col}>
                    <Button
                        className='submit'
                        onClick={() => history.push(ROUTES.PROFILE)}
                        title='Complete My Profile '/>
                </div>
                <div className={style.message__col}>
                    <Button
                        onClick={() => history.push(ROUTES.HOME)}
                        className='ignore'
                        title='Go to Homepage'/>
                </div>
            </div>
        </div>
    );
}

export default FormMessage;
