import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';

/**
 * brands
 */

export async function getBrands(categQuery) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/brands${categQuery?.length
                ? categQuery.map(
                    (item, i) =>
                        `${i === 0 ? '?' : '&'}category[]=${item}`
                )
                : ''
            }`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getBrandsForHomepage() {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            '/brands?homepage'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
