import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';
import Tabs from 'react-responsive-tabs';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import DescContent from '../DescContent/DescContent';
import SpecificContent from '../SpecificContent/SpecificContent';
import BoxContent from '../BoxContent/BoxContent';

// IMPORTANT you need to include the default styles
import 'react-responsive-tabs/styles.css';
import './ProductDescriptionTabs.scss';

const ProductDescriptionTabs = ({ description, specifications, box }) => {
    const [activeTab, setActiveTab] = useState(0);
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const presidents = [
        { id: 0, name: 'Description', content: <DescContent content={description}/> },
        { id: 1, name: 'Specifications', content: <SpecificContent options={specifications}/> },
        { id: 2, name: 'Inside the box', content: <BoxContent options={box} /> }
    ];

    const getTabs = () => {
        return presidents.map((president, index) => ({
            title: president.name,
            getContent: () => president.content,
            key: index,
            tabClassName: 'tabs',
            panelClassName: 'panels'
        }));
    };

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const renderMobileView = () => {
        return (<div className='products__details_tab-view'>
            <Nav tabs={true}>
                {presidents.map(t => {
                    return (
                        <NavItem key={t.name}>
                            <NavLink
                                className={classnames({ active: activeTab === t.id })}
                                onClick={() => { toggle(t.id); }}>
                                {t.name}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={0}>
                    <Row>
                        <Col sm='12'>
                            <DescContent content={description}/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={1}>
                    <Row>
                        <Col sm='12'>
                            <SpecificContent options={specifications}/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={2}>
                    <Row>
                        <Col sm='12' className='box__content-wrapper'>
                            <BoxContent options={box} />
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>);
    };

    return !isMobile ? <div><Tabs items={getTabs()} /></div> : renderMobileView();
};

ProductDescriptionTabs.propTypes = {
    description: PropTypes.string,
    specifications: PropTypes.array,
    box: PropTypes.array
};

export default ProductDescriptionTabs;
