import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';
import { getParentCategoryName } from 'utils/helpers';

import sample from 'assets/images/sample.png';

import 'swiper/swiper-bundle.css';
import './SimillarProducts.scss';
import style from './SimillarProducts.module.scss';
import { $ } from 'moneysafe';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import SimilarCard from './SimilarCard';
import ProductItem from 'containers/App/components/Product/ProductItem/ProductItem';

SimillarProducts.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    source: PropTypes.string,
    products: PropTypes.array.isRequired,
    currency: PropTypes.string
};

function SimillarProducts({ title, source, type, products, currency }) {
    const history = useHistory();
    const isMobile = window.matchMedia('(max-width: 992px)').matches;
    const { categories } = useSelector(state => ({ categories: state.categories.categories }));
    const containerRef = useRef(null);

    const getProducts = () => {
        const augmentedProducts = products?.map(item => {
            const uniqueDeals = item.deals && item.deals[0] ? item.deals[0] : false;
            const uniqueFlashDeals = item.flash_deals && item.flash_deals[0] ? item.flash_deals[0] : false;
            const dealsData = [...(uniqueDeals ? [uniqueDeals] : []), ...(uniqueFlashDeals ? [uniqueFlashDeals] : [])];

            return {
                ...item,
                deals: dealsData
            };
        });

        return augmentedProducts;
    };

    const getPrice = product => {
        if (product.deals?.length > 0) {
            let price = product.rental_period[product.rental_period.length - 1].price;

            if (product?.deals[0].discount_type === 'percentage') {
                price = $(price).minus($(price).times(product?.deals[0].discount_amount / 100));
            } else {
                price = $(price).minus(product?.deals[0].discount_amount);
            }

            price = Math.ceil($(price)).toFixed(0);

            return $(price).toString();
        } else {
            return product.rental_period[product.rental_period.length - 1].price;
        }
    };

    const getDiscountPercent = product => {
        const price = product.rental_period[product.rental_period.length - 1].price;

        if (product?.deals.length > 0) {
            if (product?.deals[0].discount_type === 'percentage') {
                return product?.deals[0].discount_amount.toString();
            } else {
                return Math.ceil((product?.deals[0].discount_amount) * 100 / price).toString();
            }
        }
    };

    const getLink = product => {
        if (product.productUrl && product.productUrl.trim() !== '') {
            return product.productUrl;
        } else {
            if (type === 'similar_products' && product.deals.length > 0) {
                if (product.deals[0].discount_type !== '') {
                    return ROUTES.FLASH_DEAL.generate(product.deals[0].id, product.slug);
                }

                return ROUTES.DEAL.generate(product.deals[0].id, product.slug);
            } else {
                return ROUTES.PRODUCT.generate(product.slug, null);
            }
        }
    };

    const handleClick = (event, product) => {
        const url = getLink(product);

        if (product.productUrl && product.productUrl.trim() !== '') {
            window.open(url, '_blank');
            event.preventDefault();
        }
    };

    return (<div className={style.section}>
        {/* <div className={style.title}>{title}</div> */}
        <div>
            {/* <div className='embla__viewport' >
                <div className='embla__container'>
                    {getProducts().map((product, i) => (
                        <a href={getLink(product)}
                            className='embla__slide'
                            key={i}
                            onClick={(e) => handleClick(e, product)}
                            rel='noreferrer'
                            target={product.productUrl && product.productUrl.trim() !== '' ? '_blank' : undefined}>
                            {type === 'similar_products' && product.deals?.length > 0 && (
                                <div className={style.product__deal}>
                                    <span className={style.product__deal__label}>Deal</span>
                                    <span className={style.product__deal__discount}>
                                        <Badge
                                            className={classNames('badge', {
                                                'light-red-badge': true
                                            })}
                                            key={product.deals.id}
                                            pill={true} color='danger'>
                                            {'-' + getDiscountPercent(product)}%
                                        </Badge>
                                    </span>
                                </div>
                            )}
                            <div className='embla__slide__inner'>
                                <img src={product.medias.length !== 0 ? product.medias[0].url : sample} alt={product.name} className='embla__slide__img'/>
                            </div>
                            <div className='embla__slide__innerInfo'>
                                <div className={style.product__title}>
                                    <h5>{_.truncate(product.name, { length: 40, omission: '...' })}</h5>
                                </div>
                                <div className={style.product__subTitle}>
                                    {getParentCategoryName(categories, product.category_id) || product?.category?.name}
                                </div>
                                {!isMobile && <div className={style.product__price}>
                                    {type === 'similar_products' && product.deals?.length > 0 && (
                                        <p>
                                            rent <span className={style.product__price__strike}>{currency} {product.rental_period[product.rental_period.length - 1].price}</span><span className={style.product__price__discounted}>{currency} {getPrice(product)}</span> / month
                                        </p>
                                    )}
                                    {(type !== 'similar_products' || product.deals?.length <= 0) && (
                                        <p>
                                        rent <span>{product.currency} {product.rental_period[product.rental_period.length - 1].price}
                                            </span>/month
                                        </p>
                                    )}
                                </div>}
                                {isMobile && <div className='product__item-info-mobile-view'>
                                    <p className='product__item-price'>
                                        <span>rent</span> <span><span className='highlighted__color'>{currency} {getPrice(product)}</span>/mo</span>
                                    </p>
                                </div>}
                            </div>
                        </a>
                    ))}
                    <button className='simillar__products__explore' onClick={() => history.push(ROUTES.PRODUCTS)}>Explore more</button>
                </div>
            </div> */}

            <div className='similar__products'>
                <div className='section'>
                    <div>
                        <h2 className='similar__products__title'>{title}</h2>
                    </div>

                    <div className='similar__products__content'>
                        <div ref={containerRef} className='d-flex CarouselDesktopWrapper'>
                            {getProducts()?.map((spotlight, index) => (
                                <ProductItem
                                    item={spotlight}
                                    key={spotlight.id}
                                    id={spotlight.id}
                                    image={spotlight.medias?.filter((x) => x.primary)[0]?.url}
                                    title={spotlight?.name}
                                    category={spotlight?.category?.name}
                                    slug={spotlight?.slug}
                                    source={source}
                                    price={spotlight?.rental_period[0]?.price?.toFixed(2)}
                                    currency={'SGD'}
                                    eligible={true} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default SimillarProducts;
