import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ProductScreen from 'screens/Product/ProductScreen';
import { getOneProductWithFlashDeals } from 'api/product';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { connect } from 'react-redux';
import { getRecentlyViewedProducts, fetchSimilarProducts } from 'api/products';
import { userSimilarProducts, storeUserEvents } from 'api/user';
import { ROUTES } from 'config/constants';
import FAQ from 'screens/Home/components/NewFaq/FAQ';
import GetInTouch from 'screens/Home/components/GetInTouch/GetInTouch';

FlashDealsProductContainer.propTypes = {
    token: PropTypes.string.isRequired,
    isAuthed: PropTypes.bool,
    singpassStatus: PropTypes.string,
    cart: PropTypes.any
};

function FlashDealsProductContainer({
    token,
    isAuthed,
    singpassStatus
}) {
    const history = useHistory();
    const { dealId, slug } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const rentalTerm = queryParams.get('rental_term');

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [recentProducts, setRecentProducts] = useState([]);
    const [colors, setColors] = useState([]);
    const [selectedRentalPeriod, setSelectedRentalPeriod] = useState({ id: -1 });
    const [isOpen, setIsOpen] = useState(false);
    const [attribute, setAttribute] = useState([]);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedStorage, setSelectedStorage] = useState(null);
    const [images, setImages] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [selectedDeals, setSelectedDeals] = useState({});
    const [deals, setDeals] = useState([]);
    const [colorsGallery, setColorsGallery] = useState([]);
    const [source] = useQueryParam('source', StringParam);

    useEffect(() => {
        async function getViewedProducts() {
            try {
                const recentlyViewedProducts = await getRecentlyViewedProducts();
                const tmp = recentlyViewedProducts.data.slice(0, 4);

                setRecentProducts(tmp);
            } catch (error) {
                //
            }
        }

        if (isAuthed) {
            getViewedProducts();
        }
    }, [isAuthed, dealId, slug, rentalTerm]);

    useEffect(() => {
        if (product?.data?.id) {
            storeUserEvents({
                event: 'product_details',
                product_id: product?.data?.id,
                source: source
            });
        }
    }, [isAuthed, product]);

    useEffect(() => {
        async function getProduct() {
            setLoading(true);

            try {
                const oneProduct = await getOneProductWithFlashDeals(slug);

                window.document.title = oneProduct.data.name;

                setProduct(oneProduct);

                if (oneProduct.data.attributes.storage && oneProduct.data.attributes.storage.values !== 0) {
                    const tmp = [];

                    oneProduct.data.attributes.storage.values.map(item => tmp.push({
                        value: item.id,
                        label: item.value,
                        item
                    }));
                    setAttribute(tmp);
                    setSelectedStorage(
                        {
                            value: oneProduct.data.attributes.storage.values[0].id,
                            label: oneProduct.data.attributes.storage.values[0].value,
                            item: oneProduct.data.attributes.storage.values[0]
                        }
                    );
                } else {
                    setAttribute(oneProduct.data.attributes);
                }

                let colorIndex = 0;

                const medias = oneProduct.data.medias;
                const sortedMedia = [];

                if (oneProduct.data.colors !== 0) {
                    setColorsGallery(oneProduct.data.colors);
                    const tmp = [];

                    for (const color of oneProduct.data.colors) {
                        tmp.push({
                            value: color.id,
                            label: <div className='selectOption'>
                                <span className='selectOption__color' style={{ background: color.hex_code || color.name }} />
                                <span className='selectOption__title'>{color.name}</span>
                            </div>,
                            text: color.name,
                            index: colorIndex
                        });

                        if (medias && medias.length !== 0) {
                            // get the primary one
                            const indexPrimary = medias.findIndex(e => e.color_id === color.id && e.primary);

                            if (indexPrimary > -1) {
                                const item = medias.splice(indexPrimary, 1)[0];
                                // for only selected color
                                const specificColor = { id: color.id, images: [{ ...item }] };

                                sortedMedia.push(specificColor);

                                colorIndex++;

                                // add other images with specific color
                                for (let index = 0; index < medias.length; index++) {
                                    const element = medias[index];

                                    if (element.color_id === color.id) {
                                        // for only selected color
                                        sortedMedia[sortedMedia.length - 1].images.push({ ...element });

                                        colorIndex++;
                                    }
                                }
                            } else {
                                // no primary found for that color
                                const specificColor = { id: color.id, images: [] };

                                sortedMedia.push(specificColor);

                                for (let index = 0; index < medias.length; index++) {
                                    const element = medias[index];

                                    if (element.color_id === color.id) {
                                        // for only selected color
                                        sortedMedia[sortedMedia.length - 1].images.push({ ...element });

                                        colorIndex++;
                                    }
                                }
                            }
                        }
                    }

                    setColors(tmp);
                    setSelectedColor(tmp[0]);
                }

                setImages(sortedMedia);

                // if there is a rentalTerm, ensure that it's a value within oneProduct.data.rental_period. If not, set it to the last value in the rental_period array
                const validRentalTerm = oneProduct.data.rental_period.find(e => e.duration_time === Number(rentalTerm));

                setSelectedRentalPeriod(validRentalTerm || oneProduct.data.rental_period[oneProduct.data.rental_period.length - 1]);

                if (dealId && oneProduct.data.flash_deals) {
                    setDeals(oneProduct.data.flash_deals);
                    const selectedDeal = oneProduct.data.flash_deals.find(e => e.id === Number(dealId));

                    setSelectedDeals(selectedDeal);

                    if (!selectedDeal) {
                        history.push(ROUTES.DEALS);
                    }
                }

                getSimilarProducts(oneProduct.data.id, oneProduct.data.category_id);
            } catch (error) {
                //
            }

            setLoading(false);
        }

        async function getSimilarProducts(productId, categoryId) {
            try {
                let data = [];

                if (isAuthed) {
                    data = await userSimilarProducts();
                } else {
                    data = await fetchSimilarProducts(productId, categoryId);
                }

                const tmp = data.data.slice(0, 4);

                setSimilarProducts(tmp);
            } catch (error) {
                //
            }
        }

        getProduct();
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${product.data?.name} | Cinch - Deals`}</title>
                <meta name='description' content={`Deals: ${product.data?.description}`} />
                <link rel='canonical' href={`https://www.cinch.com/flash-deals/${dealId}/${slug}`} />
            </Helmet>
            <ProductScreen
                similarProducts={similarProducts}
                images={images}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                selectedStorage={selectedStorage}
                setSelectedStorage={setSelectedStorage}
                attribute={attribute}
                singpassStatus={singpassStatus}
                messageType=''
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                selectedRentalPeriod={selectedRentalPeriod}
                setSelectedRentalPeriod={setSelectedRentalPeriod}
                recentProducts={recentProducts}
                token={token}
                colors={colors}
                isOrdered={false}
                order={null}
                product={product.data || {}}
                loading={loading}
                selectedDeals={selectedDeals}
                deals={deals}
                colorsGallery={colorsGallery}
                isDeal={true}
                isAuthed={isAuthed} />
            <div className='bg bg-grey container-fluid'>
                <FAQ />
                <div style={{ width: '100%' }} className='last-container'>
                    <GetInTouch />
                </div>
            </div>
        </>
    );
}

function mapStateToProps({ auth, cart }) {
    const { token, isAuthed, profile } = auth;

    return {
        token,
        isAuthed,
        singpassStatus: profile.singpass_status
    };
}

export default connect(mapStateToProps, null)(FlashDealsProductContainer);
