import PropTypes from 'prop-types';
import React from 'react';
import style from './ModalMessage.module.scss';
import icon from 'assets/images/general/tick.svg';
import Button from 'containers/App/components/Button/Button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config/constants';

ModalMessage.propTypes = {
    messageType: PropTypes.string.isRequired
};

function ModalMessage({ messageType }) {
    const history = useHistory();

    return (<div className={style.ModalMessage}>
        <div className={style.ModalMessage__icon}>
            <img src={icon} alt='Message icon' className={style.ModalMessage__icon__img} />
        </div>
        {messageType === 'order' &&
        <div className={style.ModalMessage__detail}>
            <p>
                We recieved your return order request.
            </p>
            <p>
                We sent you an email with the needed information to proceed.
            </p>
        </div>
        }

        {messageType === 'contact' &&
        <div className={style.ModalMessage__detail}>
            <p>
                We received your contact request.
            </p>
            <p>
                We will contact you as soon as possible.
            </p>
        </div>
        }

        <div className={style.ModalMessage__buttons}>
            <div className={style.ModalMessage__buttons__col}>
                <Button
                    onClick={() => history.push(`${ROUTES.MY_ACCOUNT}#orders`)}
                    title={'View Order History'}
                    className={'submit'} />
            </div>
            <div className={style.ModalMessage__buttons__col}>
                <Button
                    onClick={() => history.push(ROUTES.HOME)}
                    title={'Go to Homepage '}
                    className={'ignore'} />
            </div>
        </div>
    </div>);
}

export default ModalMessage;
