import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import { getAuthedProfile } from 'api/user';

import BeforeBuy from './elements/BeforeBuy/BeforeBuy';
import ProductDescriptionTabs from './elements/ProductDescriptionTabs/ProductDescriptionTabs';
import SimillarProducts from './elements/SimillarProducts/SimillarProducts';
import SlideShowGallery from './elements/SlideShow/SlideShow';
import AfterBuy from './elements/AfterBuy/AfterBuy';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import ModalMessage from './elements/ModalMessage/ModalMessage';
import DealsBeforeBuy from './elements/DealsBeforeBuy/DealsBeforeBuy';
import Ratings from './elements/Ratings/Ratings';

import styles from './Product.module.scss';

ProductScreen.propTypes = {
    product: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    colors: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    setColors: PropTypes.func,
    setAttribute: PropTypes.func,
    handleRiseTicket: PropTypes.func,
    submitLoading: PropTypes.bool,
    recentProducts: PropTypes.array,
    isOrdered: PropTypes.bool,
    order: PropTypes.any,
    selectedRentalPeriod: PropTypes.object,
    setSelectedRentalPeriod: PropTypes.func,
    changeRentalTerms: PropTypes.func,
    handleBuyNow: PropTypes.func,
    handleReturnOrder: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    messageType: PropTypes.string,
    isOpenFirst: PropTypes.bool,
    setIsOpenFirst: PropTypes.func,
    isOpenSecond: PropTypes.bool,
    setIsOpenSecond: PropTypes.func,
    componnetType: PropTypes.any,
    setComponnetType: PropTypes.func,
    riseTicket: PropTypes.object,
    setRiseTicket: PropTypes.func,
    returnOrderType: PropTypes.string,
    setReturnOrderType: PropTypes.func,
    singpassStatus: PropTypes.string,
    attribute: PropTypes.array,
    selectedColor: PropTypes.object,
    setSelectedColor: PropTypes.func,
    selectedStorage: PropTypes.object,
    setSelectedStorage: PropTypes.func,
    images: PropTypes.array,
    similarProducts: PropTypes.array,
    selectedDeals: PropTypes.object,
    deals: PropTypes.array,
    isDeal: PropTypes.bool,
    colorsGallery: PropTypes.array.isRequired,
    isAuthed: PropTypes.bool,
    orderRentalPeriod: PropTypes.object,
    setOrderRentalPeriod: PropTypes.func
};

function ProductScreen({
    similarProducts,
    loading,
    product,
    colors,
    setColors,
    token,
    handleRiseTicket,
    submitLoading,
    recentProducts,
    isOrdered,
    order,
    selectedRentalPeriod,
    setSelectedRentalPeriod,
    changeRentalTerms,
    handleBuyNow,
    handleReturnOrder,
    isOpen,
    setIsOpen,
    messageType,
    isOpenFirst,
    setIsOpenFirst,
    isOpenSecond,
    setIsOpenSecond,
    componnetType,
    setComponnetType,
    riseTicket,
    setRiseTicket,
    returnOrderType,
    setReturnOrderType,
    singpassStatus,
    attribute,
    setAttribute,
    selectedColor,
    setSelectedColor,
    selectedStorage,
    setSelectedStorage,
    images,
    selectedDeals,
    deals,
    isDeal,
    colorsGallery,
    isAuthed,
    orderRentalPeriod,
    setOrderRentalPeriod
}) {
    const [authProfile, setAuthProfile] = useState({});
    const verified = authProfile?.data?.singpass_status === 'verified';

    const isEligible = () => {
        if (isAuthed && verified) {
            return !('eligible' in product) || (selectedStorage ? selectedStorage.item.eligible : product.eligible);
        }

        return true;
    };

    const handleAttribute = (attribute) => {
        const attr = attribute && attribute.map && attribute.map(att => {
            if (selectedStorage == null) return att;

            if (att?.item?.id === selectedStorage?.item?.id) {
                if (colors == null || product == null) return att;

                const hasMatchingColor = colors.some(color => {
                    const itemToFind = product?.internal_asset_codes?.find(asset => (
                        asset?.product_attribute_value_id === att?.item.id &&
                        asset?.color_id === color?.value
                    ));

                    if (selectedColor == null) return false;

                    return itemToFind?.in_stock && color?.value === selectedColor?.value;
                });

                return hasMatchingColor ? { ...att, stock: 1 } : { ...att, stock: 0 };
            } else {
                const newAtt = { ...att };

                delete newAtt.stock;

                return newAtt;
            }
        });

        return attr;
    };

    const handleColor = (colors, product) => {
        const storageVariants = product?.attributes?.storage?.values;

        const color = colors && colors.map && colors && colors.map(color => {
            const itemToFind = product?.internal_asset_codes?.find(asset => (
                asset?.color_id === color?.value &&
                (storageVariants && storageVariants?.length > 0 && asset?.product_attribute_value_id ? asset?.product_attribute_value_id === selectedStorage?.item?.id : true)
            ));

            return itemToFind ? { ...color, stock: itemToFind?.in_stock } : color;
        });

        return color;
    };

    useEffect(() => {
        if (attribute != null && attribute.length !== 0) {
            const updatedAttributes = handleAttribute(attribute);

            if (colors == null || product == null) return;
            const updatedColors = handleColor(colors, product);

            if (attribute && (JSON.stringify(attribute) !== JSON.stringify(updatedAttributes))) {
                setAttribute && setAttribute(updatedAttributes);
            }

            if (JSON.stringify(colors) !== JSON.stringify(updatedColors)) {
                setColors && setColors(updatedColors);
            }
        } else {
            if (colors == null || product == null) return;
            const updatedColors = handleColor(colors, product);

            if (JSON.stringify(colors) !== JSON.stringify(updatedColors)) {
                setColors && setColors(updatedColors);
            }
        }
    }, [attribute, colors, selectedStorage, selectedColor, product]);

    useEffect(() => {
        if (isAuthed) {
            getAuthedProfile().then((res) => setAuthProfile(res));
        }
    }, [isAuthed]);

    // if isOrdered && mobile view, scroll to #manageSubscription
    useEffect(() => {
        isOrdered && !loading && window.innerWidth < 992 &&
            document.getElementById('manageSubscription')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, [isOrdered, loading]);

    return (loading
        ? <div className={styles.loading}>
            <AppLoadingOverlay />
        </div>
        : product.name ? <>
            <div className={styles.top__slideshow}>
                <SlideShowGallery
                    eligible={isEligible()}
                    slides={images}
                    product={product}
                    productTiles={product?.tiles}
                    selectedColor={selectedColor} />
            </div>
            <div className={`container-fluid product__detail-container ${styles.page__container}`}>
                <div className={styles.top}>
                    {order?.order_number && <h1 className={styles.title}>Order no: <span>{order?.order_number}</span></h1>}
                    <div className={`${styles.top__details}`} id='manageSubscription'>
                        {isDeal && (
                            !isOrdered
                                ? <DealsBeforeBuy
                                    images={images}
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    selectedStorage={selectedStorage}
                                    setSelectedStorage={setSelectedStorage}
                                    selectedRentalPeriod={selectedRentalPeriod}
                                    setSelectedRentalPeriod={setSelectedRentalPeriod}
                                    attribute={attribute}
                                    singpassStatus={singpassStatus}
                                    product={product}
                                    rentalOptions={product.rental_period.length !== 0 ? product.rental_period : []}
                                    token={token}
                                    colors={colors}
                                    currency={product.currency || 'SGD'}
                                    title={product.name}
                                    price={product.rental_period[product.rental_period.length - 1].price}
                                    priceDetails={'per month'}
                                    selectedDeals={selectedDeals}
                                    colorsGallery={colorsGallery}
                                    deals={deals} />

                                : <AfterBuy
                                    returnOrderType={returnOrderType}
                                    setReturnOrderType={setReturnOrderType}
                                    riseTicket={riseTicket}
                                    setRiseTicket={setRiseTicket}
                                    isOpenFirst={isOpenFirst}
                                    setIsOpenFirst={setIsOpenFirst}
                                    isOpenSecond={isOpenSecond}
                                    setIsOpenSecond={setIsOpenSecond}
                                    componnetType={componnetType}
                                    setComponnetType={setComponnetType}
                                    order={order}
                                    handleReturnOrder={handleReturnOrder}
                                    handleBuyNow={handleBuyNow}
                                    selectedRentalPeriod={selectedRentalPeriod}
                                    setSelectedRentalPeriod={setSelectedRentalPeriod}
                                    changeRentalTerms={changeRentalTerms}
                                    rentalOptions={product.rental_period}
                                    submitLoading={submitLoading}
                                    product={product}
                                    handleRiseTicket={handleRiseTicket}
                                    orderRentalPeriod={orderRentalPeriod}
                                    setOrderRentalPeriod={setOrderRentalPeriod} />
                        )
                        }

                        {!isDeal && (
                            !isOrdered
                                ? <BeforeBuy
                                    images={images}
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    selectedStorage={selectedStorage}
                                    setSelectedStorage={setSelectedStorage}
                                    selectedRentalPeriod={selectedRentalPeriod}
                                    setSelectedRentalPeriod={setSelectedRentalPeriod}
                                    attribute={attribute}
                                    singpassStatus={singpassStatus}
                                    product={product}
                                    rentalOptions={product.rental_period.length !== 0 ? product.rental_period : []}
                                    token={token}
                                    colors={colors}
                                    currency={product.currency || 'SGD'}
                                    title={product.name}
                                    price={product.rental_period[product.rental_period.length - 1].price}
                                    colorsGallery={colorsGallery}
                                    priceDetails={'per month'}
                                    isAuthed={isAuthed} />

                                : <AfterBuy
                                    returnOrderType={returnOrderType}
                                    setReturnOrderType={setReturnOrderType}
                                    riseTicket={riseTicket}
                                    setRiseTicket={setRiseTicket}
                                    isOpenFirst={isOpenFirst}
                                    setIsOpenFirst={setIsOpenFirst}
                                    isOpenSecond={isOpenSecond}
                                    setIsOpenSecond={setIsOpenSecond}
                                    componnetType={componnetType}
                                    setComponnetType={setComponnetType}
                                    order={order}
                                    handleReturnOrder={handleReturnOrder}
                                    handleBuyNow={handleBuyNow}
                                    selectedRentalPeriod={selectedRentalPeriod}
                                    setSelectedRentalPeriod={setSelectedRentalPeriod}
                                    changeRentalTerms={changeRentalTerms}
                                    rentalOptions={product.rental_period}
                                    submitLoading={submitLoading}
                                    product={product}
                                    handleRiseTicket={handleRiseTicket}
                                    orderRentalPeriod={orderRentalPeriod}
                                    setOrderRentalPeriod={setOrderRentalPeriod} />
                        )}
                    </div>
                </div>
                <div className={`${styles.middle}`}>
                    {!isOrdered &&
                        <>
                            <div className={styles.middle__description}>
                                {/* <div className={styles.middle__title}>About The Product</div> */}
                                <div>
                                    <ProductDescriptionTabs
                                        description={product?.description_html ?? product.description}
                                        specifications={product.attributes.length !== 0 ? product.attributes.specifications.values : []}
                                        box={product.attributes.length !== 0 ? product.attributes.inside_box.values : []} />
                                </div>
                            </div>
                            {/* <div className={styles.middle__certificate}>
                        <Certificate />
                    </div> */}
                            {/* {
                            !isMobile &&
                            <div>
                                <Properties />
                            </div>
                        } */}
                        </>}
                    <Ratings />
                    <div>
                        {recentProducts.length !== 0 && <SimillarProducts title='Recently Viewed' source='?source=recently-viewed-product' type='recently_viewed'
                            products={recentProducts}
                            currency={product.currency || 'SGD'} />}
                        {similarProducts.length !== 0 && <SimillarProducts title='You Might Also Like' source='?source=you-might-also-like-product' type='similar_products'
                            products={similarProducts}
                            currency={product.currency || 'SGD'} />}
                    </div>
                </div>

                <Modal
                    toggle={() => setIsOpen(false)}
                    isOpen={isOpen}
                    className={styles.modal}>
                    <ModalMessage messageType={messageType} />
                </Modal>
            </div></> : '');
}

export default ProductScreen;
