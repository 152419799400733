import { createAxiosInstance } from 'utils/helpers';

const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE';

export const submitContactFormStart = () => ({
    type: SUBMIT_CONTACT_FORM_START
});

export const submitContactFormSuccess = (data) => ({
    type: SUBMIT_CONTACT_FORM_SUCCESS,
    data
});

export const submitContactFormFailure = () => ({
    type: SUBMIT_CONTACT_FORM_FAILURE
});

export const handleSubmitContactForm = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(submitContactFormStart());

        const instance = createAxiosInstance();

        try {
            await instance.post('/contact/create', form);

            dispatch(submitContactFormSuccess());
            onSuccess();
        } catch (e) {
            console.error(e);
            dispatch(submitContactFormFailure());
            onFailure();
        }
    };
};

const initialState = {};

export default function cart(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
