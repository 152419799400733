import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UncontrolledCollapse, Collapse } from 'reactstrap';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';
import React, { useState } from 'react';

export function Categories({ categories, handleCategories, categoryQuery }) {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className='mb-4 mt-5'>
            <p className='sidebar__label d-flex align-items-center justify-content-between cursor-pointer'>
                Categories{' '}
                <span onClick={toggle}>{isOpen ? 'Hide' : 'Show'}</span>
            </p>
            <Collapse isOpen={isOpen}>
                {!!categories?.length &&
                    categories.map((item, index) => (
                        <div
                            onClick={() => handleCategories(item.id, item.slug)}
                            className={classNames(
                                'sidebar__checkbox flex-column align-items-baseline',
                                {
                                    'is-active': categoryQuery?.includes(
                                        item.slug.toString()
                                    )
                                }
                            )}
                            key={item.id}>
                            <div className='w-100 d-flex align-items-center ml-0 position-relative sidebar__item'>
                                <span /> {item.name}{' '}
                                <div
                                    className='ml-5 sidebar__arrow'
                                    id={`toggler-${index}`}
                                    onClick={(e) => e.stopPropagation()}>
                                    <ChevronIcon />
                                </div>
                            </div>
                            <UncontrolledCollapse
                                defaultOpen={!isMobile}
                                toggler={`#toggler-${index}`}
                                className={`position-static h-100 w-100 mt-2 ${item.children.some(child => categoryQuery?.includes(child.id.toString())) && 'show'}`}>
                                {!!item.children.length &&
                                    item.children.map((child) => (
                                        <div
                                            className='w-100 mb-2'
                                            key={child.id}>
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    if (
                                                        categoryQuery?.includes(
                                                            item.slug.toString()
                                                        )
                                                    ) {
                                                        handleCategories(child.id, child.slug);
                                                        handleCategories(item.id, item.slug);
                                                    } else {
                                                        handleCategories(child.id, child.slug);
                                                    }
                                                }}
                                                className={classNames(
                                                    'sidebar__checkbox flex-column align-items-baseline w-100',
                                                    {
                                                        'is-active':
                                                            categoryQuery?.includes(
                                                                child.slug.toString()
                                                            )
                                                    }
                                                )}>
                                                <div className='w-100 d-flex align-items-center'>
                                                    <span /> {child.name}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </UncontrolledCollapse>
                        </div>
                    ))}
            </Collapse>
        </div>
    );
}

Categories.propTypes = {
    categories: PropTypes.array,
    handleCategories: PropTypes.func,
    categoryQuery: PropTypes.array
};
