import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

export async function createAddress(formData) {
    const options = {
        method: 'POST',
        data: formData,
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/address'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getAddress() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/address'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function updateAddress(formData, id) {
    const options = {
        method: 'PUT',
        data: formData,
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + `/user/address/${id}`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
