import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { storeUserEvents } from 'api/user';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.svg';

import style from './ForgotForm.module.scss';

ForgotForm.propTypes = {
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    Filledemail: PropTypes.string,
    backToMainLogin: PropTypes.func
};

function ForgotForm({
    submit,
    loading,
    Filledemail,
    backToMainLogin
}) {
    const [email, setEmail] = useState(Filledemail);

    const handleSubmit = async e => {
        e.preventDefault();

        if (email === '') {
            notify('Email is required.', notifType.DANGER);

            return true;
        }

        submit(email);
    };

    useEffect(() => {
        storeUserEvents({ event: 'forgot_password' });
    }, []);

    return (
        <div className={style.section}>
            <form onSubmit={handleSubmit}>
                <h3><img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />Reset Password</h3>
                <p>Enter the email address associated with your Cinch account.</p>
                <div className={style.row}>
                    <div className={style.label}>
                        Email Address
                    </div>
                    <div className={style.value}>
                        <Input value={email} name='email' onChange={e => setEmail(e.target.value)}
                            className={style.value__input} />
                    </div>
                </div>

                <div className={style.buttons}>
                    <Button
                        loading={loading}
                        type='submit'
                        title={'Send OTP'}
                        className={'submit'} />

                </div>
            </form>
        </div>
    );
}

export default ForgotForm;
