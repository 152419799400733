import * as Sentry from '@sentry/react';

import {
    isProduction,
    getDefaultColorOptionOnProductItem,
    getDefaultStorageOptionOnProductItem,
    getTheBrandNamePerGivenBrandId,
    getDefaultRentalOptionOnProductItem
} from 'utils/helpers';

// TODO: rename to more generic tracking function
export async function trackKlaviyoEvent(event, params = {}) {
    var klaviyo = window.klaviyo = window.klaviyo || [];
    var dataLayer = window.dataLayer = window.dataLayer || [];

    if (!isProduction()) {
        return;
    }

    try {
        switch (event) {
            case 'identify':
                klaviyo.identify({
                    $email: params.email,
                    $first_name: params.name,
                    $last_name: params.surname,
                    channel: 'cinch'
                }, () => {});
                break;
            case 'products-listing':
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'view_item_list',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        items: params.items.map((item) => ({
                            item_list_name: item.brand.name === 'Samsung' ? 'Samsung Products' : 'Cinch Products',
                            item_id: item.id,
                            item_name: item.name,
                            item_brand: item.brand.name,
                            item_category: item.category.name,
                            item_category2: getDefaultColorOptionOnProductItem(item),
                            item_category3: getDefaultStorageOptionOnProductItem(item),
                            item_variant: item.rental_period.filter(e => e.duration_time === parseInt(params?.periodQuery))[0]?.duration_time || 12,
                            price: Math.round(item.rental_period.filter(e => e.duration_time === parseInt(params?.periodQuery) || 12)[0]?.price)
                        }))
                    },
                    channel: 'cinch'
                });

                klaviyo.push(['track', 'View Products']);
                break;
            case 'product-view':
                var item = {
                    ProductName: params.title,
                    ProductID: params.product && params.product.length > 0 ? params.product[0]?.id : '',
                    Categories: params.product && params.product.length > 0 && params.product[0]?.category ? [params.product[0]?.category?.name] : [],
                    ImageURL: params.images && params.images.length > 0 ? params.images[0]?.url : '',
                    URL: window.location.href,
                    Brand: params.product && params.product.length > 0 && params.product[0]?.brand ? params.product[0]?.brand?.name : '',
                    Price: params.price,
                    CompareAtPrice: '',
                    deals: params.deals,
                    channel: 'cinch'
                };

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'select_item',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        currency: 'SGD',
                        value: Math.round(params.price),
                        items: params.product.map((item) => ({
                            item_id: item.id,
                            item_name: item.name,
                            item_brand: item.brand.name,
                            item_category: item.category.name,
                            item_category2: getDefaultColorOptionOnProductItem(item, params.selectedColor),
                            item_category3: params.selectedStorage ? getDefaultStorageOptionOnProductItem(item, params.selectedStorage) : null,
                            item_variant: getDefaultRentalOptionOnProductItem(item, params?.selectedRentalPeriod),
                            price: Math.round(params.price)
                        }))
                    },
                    channel: 'cinch'
                });

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'view_item',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        currency: 'SGD',
                        value: Math.round(params.price),
                        items: params.product.map((item) => ({
                            item_id: item.id,
                            item_name: item.name,
                            item_brand: item.brand.name,
                            item_category: item.category.name,
                            item_category2: getDefaultColorOptionOnProductItem(item, params.selectedColor),
                            item_category3: params.selectedStorage ? getDefaultStorageOptionOnProductItem(item, params.selectedStorage) : null,
                            item_variant: getDefaultRentalOptionOnProductItem(item, params?.selectedRentalPeriod),
                            price: Math.round(params.price)
                        }))
                    },
                    channel: 'cinch'
                });

                klaviyo.push(['track', 'Viewed Product', item]);
                break;
            case 'add-cart':
                if (params?.cartItems.length === 0) { return; }

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'add_to_cart',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        currency: 'SGD',
                        value: Math.round(params?.price),
                        items: params?.cartItems.map((item) => ({
                            item_id: item?.base_product?.id || item?.id,
                            item_name: item?.base_product?.name || item?.name,
                            item_brand: item?.base_product ? getTheBrandNamePerGivenBrandId(item?.base_product?.brand_id) : item?.brand.name,
                            item_category: item?.base_product?.category?.name || item?.category?.name,
                            item_category2: getDefaultColorOptionOnProductItem(item?.base_product, params?.selectedColor || item?.selectedColor || item?.color),
                            item_category3: params?.selectedStorage ? getDefaultStorageOptionOnProductItem(item?.base_product, params?.selectedStorage || item?.selectedStorage, item?.product_attribute_value_id) : item?.selectedStorage?.label,
                            item_variant: getDefaultRentalOptionOnProductItem(params?.product, params?.selectedRentalPeriod || item?.selectedRentalPeriod || item?.rental_period),
                            price: Math.round(params?.price)
                        }))
                    },
                    channel: 'cinch'
                });

                klaviyo.push(['track', 'Added to Cart', {
                    $value: params?.cartMeta?.total || params?.price,
                    AddedItemProductName: params?.title,
                    AddedItemProductID: params?.product?.id,
                    AddedItemCategories: [params?.product?.category?.name],
                    AddedItemImageURL: params?.images[0]?.url,
                    AddedItemURL: window.location.href,
                    AddedItemPrice: params?.price,
                    AddedItemQuantity: 1,
                    deals: params?.deals,
                    ItemNames: params?.cartItems.map((item) => (item?.base_product?.name)),
                    CheckoutURL: 'https://www.cinch.sg/shipping',
                    channel: 'cinch',
                    Items: params.cartItems.map((item) => ({
                        ProductID: item?.base_product?.id,
                        SKU: '',
                        ProductName: item?.base_product?.name,
                        Quantity: item?.quantity,
                        ItemPrice: item?.total,
                        RowTotal: item?.total,
                        ProductURL: window.location.href,
                        ImageURL: item?.base_product?.medias[0]?.url,
                        ProductCategories: [item?.base_product?.category?.name]
                    }))
                }]);
                break;
            case 'start-checkout':
                if (params.cartItems.length === 0) { return; }

                klaviyo.push(['track', 'Started Checkout', {
                    $event_id: `${params.cartMeta.id}_${Math.round(Date.now() / 1000)}`,
                    $value: params.cartMeta.total,
                    ItemNames: params.cartItems.map((item) => (item?.base_product?.name)),
                    CheckoutURL: 'https://www.cinch.sg/shipping',
                    Categories: params.cartItems.map((item) => (item?.base_product?.category?.name)),
                    channel: 'cinch',
                    Items: params.cartItems.map((item) => ({
                        ProductID: item?.base_product?.id,
                        SKU: '',
                        ProductName: item?.base_product?.name,
                        Quantity: item.quantity,
                        ItemPrice: item.total,
                        RowTotal: item.total,
                        ProductURL: 'https://www.cinch.sg/product/' + item?.base_product?.slug,
                        ImageURL: item?.base_product?.medias[0]?.url,
                        ProductCategories: [item?.base_product?.category?.name]
                    }))
                }]);
                break;
            case 'payment-added':
                var total = params.items.reduce((acc, cur) => acc + parseInt(cur?.total), 0);

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'add_payment_info',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        currency: 'SGD',
                        value: total,
                        items: params.items.map((item) => ({
                            item_id: item?.base_product?.id,
                            item_name: item?.base_product?.name,
                            item_brand: item?.base_product ? getTheBrandNamePerGivenBrandId(item?.base_product?.brand_id) : item?.brand.name,
                            item_category: item?.base_product.category.name,
                            item_category2: item?.color?.name,
                            item_category3: item?.base_product?.attributes.some(e => e?.type === 'storage') ? item?.base_product?.attributes?.filter(a => a?.type === 'storage')[0]?.attribute_values.filter(a => a?.id === item?.pivot?.product_attribute_value_id)[0]?.value : null,
                            item_variant: item?.rental_period?.duration_time,
                            price: Math.round(parseInt(item?.total))
                        }))
                    },
                    channel: 'cinch'
                });

                klaviyo.push(['track', 'Payment Added', {
                    channel: 'cinch'
                }]);
                break;
            case 'shipping-added':
                var totalAmount = params.items.reduce((acc, cur) => acc + parseInt(cur?.total), 0);

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'add_shipping_info',
                    email: params.email,
                    user_id: params.user_id,
                    ecommerce: {
                        currency: 'SGD',
                        value: totalAmount,
                        items: params.items.map((item) => ({
                            item_id: item?.base_product?.id,
                            item_name: item?.base_product?.name,
                            item_brand: item?.base_product ? getTheBrandNamePerGivenBrandId(item?.base_product?.brand_id) : item?.brand.name,
                            item_category: item?.base_product.category.name,
                            item_category2: item?.color?.name,
                            item_category3: item?.base_product?.attributes.some(e => e?.type === 'storage') ? item?.base_product?.attributes?.filter(a => a?.type === 'storage')[0]?.attribute_values.filter(a => a?.id === item?.pivot?.product_attribute_value_id)[0]?.value : null,
                            item_variant: item?.rental_period?.duration_time,
                            price: Math.round(parseInt(item?.total))
                        }))
                    },
                    channel: 'cinch'
                });

                klaviyo.push(['track', 'Shipping Added', {
                    ZipCode: params.zip_code,
                    channel: 'cinch'
                }]);
                break;
            case 'user-registered':
                klaviyo.push(['track', 'User Registered', {
                    user_id: params.id,
                    email: params.email,
                    channel: 'cinch',
                    referral_code: params.referral_code
                }]);
                break;
            case 'singpass-attempt':
                dataLayer.push({
                    event: 'singpass_submit_cta_click',
                    channel: 'cinch'
                });
                break;
            case 'acknowledge_popup_view':
                dataLayer.push({
                    event: 'acknowledge_popup_view',
                    email: params.email,
                    user_id: params.user_id,
                    channel: 'cinch'
                });
                break;
            case 'purchase':
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'purchase',
                    email: params.email,
                    order_discount: Math.round(params.meta?.discount),
                    user_id: params.user_id,
                    ecommerce: {
                        transaction_id: params.transaction_id,
                        value: Math.round(params.meta?.total),
                        coupon: params.meta?.coupon,
                        tax: '',
                        shipping: '',
                        currency: 'SGD',
                        items: params.items.map((item) => ({
                            coupon: params.meta?.coupon,
                            discount: Math.round(params.meta?.discount),
                            item_id: item?.base_product?.id,
                            item_name: item?.base_product?.name,
                            item_brand: item?.base_product ? getTheBrandNamePerGivenBrandId(item?.base_product?.brand_id) : item?.brand.name,
                            item_category: item?.base_product.category.name,
                            item_category2: item?.color?.name,
                            item_category3: item?.base_product?.attributes.some(e => e?.type === 'storage') ? item?.base_product?.attributes?.filter(a => a?.type === 'storage')[0]?.attribute_values.filter(a => a?.id === item?.pivot?.product_attribute_value_id)[0]?.value : null,
                            item_variant: item?.rental_period?.duration_time,
                            price: Math.round(parseInt(item?.total))
                        }))
                    },
                    channel: 'cinch'
                });
                break;
            case 'set_user_id':
                dataLayer.push({
                    event: 'set_user_id',
                    email: params.email,
                    user_id: params.user_id,
                    channel: 'cinch'
                });
                break;
            default:
                break;
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}

export async function trackFreshChatUser(event, params = {}) {
    var fcWidget = window.fcWidget || [];

    if (!isProduction() || !fcWidget || !fcWidget.user) {
        return;
    }

    try {
        if (event === 'identify') {
            fcWidget.user.setProperties({
                externalId: params.email,
                firstName: params.name,
                lastName: params.surname,
                email: params.email
            });
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}
