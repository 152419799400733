import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { useQueryParam, StringParam } from 'use-query-params';

export function MonthlyPrice({ priceLimits }) {
    const location = useLocation();
    const [minPriceQuery, setMinPriceQuery] = useQueryParam(
        'min_rental_price',
        StringParam
    );
    const [maxPriceQuery, setMaxPriceQuery] = useQueryParam(
        'max_rental_price',
        StringParam
    );
    const [value, setValue] = useState([
        parseInt(minPriceQuery) || priceLimits[0],
        parseInt(maxPriceQuery) || priceLimits[1]
    ]);

    useEffect(() => {
        if (!maxPriceQuery && !minPriceQuery) {
            setValue([priceLimits[0], priceLimits[1]]);
        }

        if (!maxPriceQuery && minPriceQuery) {
            setValue([parseInt(minPriceQuery), priceLimits[1]]);
        }

        if (!minPriceQuery && maxPriceQuery) {
            setValue([priceLimits[0], parseInt(maxPriceQuery)]);
        }
    }, [location, priceLimits]);

    const handleAfterChange = (value, index) => {
        if (index === 0) {
            setMinPriceQuery(value[0]);
        } else {
            setMaxPriceQuery(value[1]);
        }
    };

    return (
        <div className='mb-4'>
            <p className='sidebar__label'>Monthly Price</p>
            <div className='d-flex justify-content-between mt-2 mb-3'>
                <p className='sidebar__value'>Min Price(${value[0]})</p>
                <p className='sidebar__value'>Max Price(${value[1]})</p>
            </div>

            <ReactSlider
                value={value}
                onChange={(value) => {
                    setValue(value);
                }}
                onAfterChange={(value, index) => {
                    handleAfterChange(value, index);
                }}
                min={priceLimits[0]}
                max={priceLimits[1]}
                className='horizontal-slider'
                thumbClassName='example-thumb'
                trackClassName='example-track' />
        </div>
    );
}

MonthlyPrice.propTypes = {
    priceLimits: PropTypes.array
};
