import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import style from './ResetPassword.module.scss';
import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { storeUserEvents } from 'api/user';
import EyeIcon from 'assets/images/eye-icon.svg'; // Add this import for the eye icon
import EyeSlashIcon from 'assets/images/eye-slash-icon.svg'; // Add this import for the hide icon
import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.svg';

ResetPassword.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    isReset: PropTypes.bool,
    backToMainLogin: PropTypes.func
};

function ResetPassword({ submit, changeForm, isReset, backToMainLogin }) {
    const [type, setType] = useState(true);
    const [typeConfirm, setTypeConfirm] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        const pass = e.target.password.value;
        const passConf = e.target.confirmPassword.value;

        if (pass === '' || passConf === '') {
            notify('Fields should not be empty.', notifType.DANGER);

            return true;
        }

        if (pass.length < 5 || pass.length > 48) {
            const message = pass.length < 5
                ? 'Password is too short. Please choose another one.'
                : 'Password is too long. Please choose another one.';

            notify(message, notifType.DANGER);

            return true;
        }

        if (pass !== passConf) {
            notify('Passwords do not match.', notifType.DANGER);

            return true;
        }

        submit(pass);
    };

    useEffect(() => {
        storeUserEvents({ event: 'reset_password' });
    }, []);

    const handleCopyPaste = (e) => {
        e.preventDefault();
    };

    return (
        <div className={style.section}>
            <form onSubmit={handleSubmit} >
                <h3>
                    {backToMainLogin && <img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />
                    }
                    Create New Password</h3>
                <p>We&apos;ll ask for this password whenever you Sign-In.</p>
                <div className={style.row}>
                    <div className={style.row__fullCol}>
                        <div className={style.label}>
                            New Password
                        </div>
                        <div className={style.value}>
                            <div className={style.withIcon}>
                                <input
                                    name='password'
                                    className={style.value__input + ' ' + style.withIcon__input} placeholder='Enter New Password'
                                    type={showPassword ? 'text' : 'password'} />
                                <img
                                    src={showPassword ? EyeIcon : EyeSlashIcon}
                                    alt='Toggle password visibility'
                                    className={style.passwordToggle}
                                    onClick={() => setShowPassword(!showPassword)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.row__fullCol}>
                        <div className={style.label}>
                            Confirm Password
                        </div>
                        <div className={style.value}>
                            <div className={style.withIcon}>
                                <input
                                    name='confirmPassword'
                                    onCopy={handleCopyPaste}
                                    onPaste={handleCopyPaste}
                                    onCut={handleCopyPaste}
                                    className={style.value__input + ' ' + style.withIcon__input} placeholder='Re-enter New Password'
                                    type={showConfirmPassword ? 'text' : 'password'} />
                                <img
                                    src={showConfirmPassword ? EyeIcon : EyeSlashIcon}
                                    alt='Toggle password visibility'
                                    className={style.passwordToggle}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.buttons}>
                    <Button
                        type='submit'
                        title={'Save & Sign In'}
                        className={'submit'} />

                </div>
            </form>
        </div>
    );
}

export default ResetPassword;
