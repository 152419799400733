import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import StartWhite from 'assets/images/general/star-white.png';

import sampleImg from 'assets/images/noImg.png';

import './SlideShow.scss';
import Freebies from '../Freebies/Freebies';

const images = [
    {
        original: sampleImg,
        thumbnail: sampleImg
    }
];

SlideShowGallery.propTypes = {
    slides: PropTypes.array.isRequired,
    productTiles: PropTypes.array.isRequired,
    selectedColor: PropTypes.object,
    product: PropTypes.object,
    eligible: PropTypes.bool
};

function SlideShowGallery({
    slides,
    productTiles,
    selectedColor,
    product,
    eligible
}) {
    const [thumbnailPosition, setThumbnailPosition] = useState('left');
    const isMobile = window.matchMedia('(max-width: 992px)').matches;

    const handleSlides = () => {
        if (slides && slides.length === 0) {
            return images;
        }

        const tmp = [];

        slides && slides.map && slides.map(slide => {
            if (slide?.id === selectedColor?.value) {
                slide?.images.map(img =>
                    !eligible
                        ? tmp.push(
                            {
                                original: img.url,
                                thumbnail: img.url,
                                thumbnailClass: 'none-eligible-thumb',
                                originalClass: 'none-eligible-img'
                            }
                        )
                        : tmp.push(
                            {
                                original: img.url,
                                thumbnail: img.url
                            }
                        )
                );
            }

            return true;
        });

        return tmp;
    };

    useEffect(() => {
        function handleWindowResize() {
            const { innerWidth } = window;

            setThumbnailPosition(innerWidth < 992 ? 'bottom' : 'left');
        }

        window.addEventListener('resize', handleWindowResize);

        handleWindowResize();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const renderCustomSlide = (item) => {
        if (item.original === handleSlides()[0].original) {
            return (
                <div className='custom-slide'>
                    <img alt='' className={`first-slide-image ${isMobile && product?.category?.name === 'Tablets' ? 'w-100' : ''}`}
                        src={item.original} />
                    <div className='custom-caption d-none'>
                        {(productTiles && productTiles[0]) && (
                            <div className='custom-caption__freebies-card'>
                                {/* <img alt='' src={IcTool} className='icon mb-4'/> */}
                                <div className='custom-caption__freebies-card__content'>
                                    <p className='title mb-4'>{productTiles[0]?.name}</p>
                                    <p className='description mb-0'>{productTiles[0]?.description}</p>
                                </div>
                            </div>
                        )}
                        {(productTiles && productTiles[1]) &&
                            <div className='custom-caption__freebies-card'>
                                {/* <img src={ParallelArrows} className='icon mb-3' alt=''/> */}
                                <div className='custom-caption__freebies-card__content'>
                                    <p className='description mb-4'>{productTiles[1]?.name}</p>
                                    <p className='title  mb-0'>{productTiles[1]?.description}</p>
                                </div>
                            </div>}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <img className='image-gallery-image' src={item.original} alt='' />
            </div>
        );
    };

    return (
        <div className='slideShow-container'>
            <ImageGallery
                infinite={false}
                showIndex={false}
                additionalClass='slideShow'
                items={handleSlides()}
                autoPlay={false}
                disableKeyDown={true}
                lazyLoad={false}
                showNav={!isMobile}
                showBullets={true}
                showThumbnails={false}
                thumbnailPosition={thumbnailPosition}
                showPlayButton={false}
                renderItem={renderCustomSlide}
                showFullscreenButton={false} />
        </div>
    );
}

export default SlideShowGallery;
