export const ALL_BRANDS = [
    {
        name: 'Xiaomi',
        class: 'small',
        top: 0,
        left: -13
    },
    {
        name: 'Google',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Samsung',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'Microsoft',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'GoPro',
        class: 'small',
        top: 10,
        left: 0
    },
    {
        name: 'Dyson',
        class: 'large',
        top: 25,
        left: 0
    },
    {
        name: 'Lenovo',
        class: 'small',
        top: -20,
        left: 0
    },
    {
        name: 'Nothing',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Apple',
        class: 'large',
        top: 10,
        left: 0
    },
    {
        name: 'Dell',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Garmin',
        class: 'small',
        top: 0,
        left: 10
    },
    {
        name: 'Sony',
        class: 'large',
        top: -15,
        left: 0
    },
    {
        name: 'Sonos',
        class: 'medium',
        top: 0,
        left: -13
    },
    {
        name: 'DJI',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Nintendo',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'Bose',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Huawei',
        class: 'medium',
        top: 10,
        left: 0
    },
    {
        name: 'Asus',
        class: 'large',
        top: 25,
        left: 0
    },
    {
        name: 'Beats',
        class: 'medium',
        top: -20,
        left: 0
    },
    {
        name: 'Canon',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'JBL',
        class: 'large',
        top: 10,
        left: 0
    },
    {
        name: 'Thermomix',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Wacom',
        class: 'medium',
        top: 0,
        left: 10
    },
    {
        name: 'LG',
        class: 'large',
        top: -15,
        left: 0
    },
    {
        name: 'Devialet',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'HTC',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'OnePlus',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'Motorola',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Epson',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Oppo',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'Bang & Olufsen',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Aftershock',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Marshall',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'Razer',
        class: 'small',
        top: 0,
        left: 0
    },
    {
        name: 'Oculus',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'Panasonic',
        class: 'large',
        top: 0,
        left: 0
    },
    {
        name: 'BenQ',
        class: 'medium',
        top: 0,
        left: 0
    },
    {
        name: 'MSI',
        class: 'large',
        top: 0,
        left: 0
    }
];
