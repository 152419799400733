
import axios from 'axios';
import { AUTH_API_URL } from 'config/constants';

export async function getElfsightWidgets() {
    try {
        const response = await axios.get(AUTH_API_URL + '/widgets?channel=cinch');

        return response.data;
    } catch (error) {
        console.error('Error fetching Elfsight widgets:', error);

        throw error;
    }
}
