import React from 'react';

import WhatsAppImg from 'assets/images/WhatsApp.svg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './ContactUsScreen.scss';

const ContactUsScreen = () => {
    const openWhatsApp = () => {
        window.open('https://wa.me/6581688604', '_blank');
    };

    const openGmail = () => {
        window.open('mailto:support@cinch.sg', '_blank');
    };

    return (
        <div className='ContactUsScreen__container last-container'>
            <div className='ContactUsScreen__card'>
                <div className='ContactUsScreen__card__image-container'>
                    <img src={WhatsAppImg} alt='WhatsApp logo' className='ContactUsScreen__card__image-container__image' />
                </div>

                <div onClick={() => { openWhatsApp(); }} className='ContactUsScreen__card__contents'>
                    <h5 className='ContactUsScreen__card__contents__label'>WhatsApp</h5>
                    <h5 className='ContactUsScreen__card__contents__value'>+65 8168-8604</h5>
                </div>
            </div>

            <div className='ContactUsScreen__card'>
                <div className='ContactUsScreen__card__image-container'>
                    <FontAwesomeIcon icon={faEnvelope} className='ContactUsScreen__card__image-container__image email-icon'/>
                </div>

                <div onClick={() => { openGmail(); }} className='ContactUsScreen__card__contents_email'>
                    <h5 className='ContactUsScreen__card__contents__label'>Email</h5>
                    <h5 className='ContactUsScreen__card__contents__value'>support@cinch.sg</h5>
                </div>
            </div>
        </div>
    );
};

export default ContactUsScreen;
