import PropTypes from 'prop-types';
import Button from 'containers/App/components/Button/Button';
import React from 'react';
import style from './BuyProduct.module.scss';
import buy from 'assets/images/general/basket-white.svg';

BuyProduct.propTypes = {
    price: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

function BuyProduct({
    price,
    onClick,
    loading
}) {
    return (<div >
        <div className={style.BuyProduct}>
            <div className={style.BuyProduct__label}>
                Purchase price
            </div>
            <div className={style.BuyProduct__value}>
                {price}
            </div>
        </div>
        <div>
            <Button
                loading={loading}
                onClick={onClick}
                className={'submit ' + style.btn}
                title={<span className={style.btn__title}>
                    <img className={style.btn__title__img} src={buy} alt='Buy icon' />
                    <span className={style.btn__title__label}>Buy now</span>
                </span>} />
        </div>
    </div>);
}

export default BuyProduct;
