import PropTypes from 'prop-types';
import Button from 'containers/App/components/Button/Button';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import style from './ProductActions.module.scss';
import buy from 'assets/images/general/buy.svg';
import RentalPeriod from '../RentalPeriod/RentalPeriod';
import BuyProduct from '../BuyProduct/BuyProduct';
import RiseTicket from '../RiseTicket/RiseTicket';
// import ReturnOrder from '../ReturnOrder/ReturnOrder';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import ReturnProduct from '../ReturnProduct';
import { $, add, minus } from 'moneysafe';

ProductActions.propTypes = {
    handleRiseTicket: PropTypes.func,
    submitLoading: PropTypes.bool,
    rentalOptions: PropTypes.array,
    selectedRentalPeriod: PropTypes.object,
    setSelectedRentalPeriod: PropTypes.func,
    changeRentalTerms: PropTypes.func,
    handleBuyNow: PropTypes.func,
    price: PropTypes.string,
    handleReturnOrder: PropTypes.func,
    order: PropTypes.any,
    isOpenFirst: PropTypes.bool,
    setIsOpenFirst: PropTypes.func,
    isOpenSecond: PropTypes.bool,
    setIsOpenSecond: PropTypes.func,
    componnetType: PropTypes.any,
    setComponnetType: PropTypes.func,
    riseTicket: PropTypes.object,
    setRiseTicket: PropTypes.func,
    returnOrderType: PropTypes.string,
    setReturnOrderType: PropTypes.func,
    product: PropTypes.object,
    orderRentalPeriod: PropTypes.object,
    setOrderRentalPeriod: PropTypes.func
};

function ProductActions({
    handleRiseTicket,
    submitLoading,
    rentalOptions,
    selectedRentalPeriod,
    setSelectedRentalPeriod,
    changeRentalTerms,
    handleBuyNow,
    price,
    handleReturnOrder,
    order,
    isOpenFirst,
    setIsOpenFirst,
    isOpenSecond,
    setIsOpenSecond,
    componnetType,
    setComponnetType,
    riseTicket,
    setRiseTicket,
    returnOrderType,
    setReturnOrderType,
    product,
    orderRentalPeriod,
    setOrderRentalPeriod
}) {
    const handleRental = i => { setSelectedRentalPeriod(i); };

    const [open, setOpen] = useState(false);
    const [disable, setDisable] = useState(false);

    const history = useHistory();
    const { state } = useLocation();

    useEffect(() => {
        if (state && state.rentId && state.rentId.length) {
            setSelectedRentalPeriod(state.rentId?.[0]);
            setOrderRentalPeriod(state.rentId?.[0]);
        }
    }, [state, setSelectedRentalPeriod]);

    const handleFirstCollapse = (e) => {
        setIsOpenFirst(false);
        setComponnetType(e);
        setTimeout(function() {
            setIsOpenFirst(true);
        }, 100);
        setIsOpenSecond(false);
    };

    const handleSecondCollapse = (e) => {
        setIsOpenSecond(false);
        setComponnetType(e);
        setTimeout(function() {
            setIsOpenSecond(true);
        }, 100);
        setIsOpenFirst(false);
    };

    const handleBuy = () => {
        history.push(ROUTES.ORDER_SUMMARY + '?buy=true&order=' + order.id);
    };

    return (<div className={style.section}>

        <ReturnProduct
            setDisable={setDisable}
            open={open}
            handleClose={setOpen}
            order={order}
            product={product} />

        <div className={style.ctaBtns}>
            <Button
                disabled={!order.can_be_updated}
                onClick={() => !isOpenFirst ? handleFirstCollapse('rental') : setIsOpenFirst(false)}
                title={'Change Rental Terms'}
                className={(componnetType === 'rental' ? 'submit ' : 'ignore ') + style.productactionBtn} />
            <Button
                loading={submitLoading}
                disabled={!order.can_be_purchased}
                onClick={!order.can_be_purchased ? () => {} : () => handleBuy()}
                title={<span className={style.productactionBtn__title}>
                    {!order.is_purchased && <img className={style.productactionBtn__title__img} src={buy} alt='Buy icon'/>}
                    <span className={style.productactionBtn__title__label}>Buy</span>
                </span>} className={(componnetType === 'buy' ? 'submit ' : 'ignore ') + style.productactionBtn} />
            <Button
                onClick={() => !isOpenSecond ? handleSecondCollapse('ticket') : setIsOpenSecond(false)}
                title={'Report an Issue'}
                className={(componnetType === 'ticket' ? 'submit ' : 'ignore ') + style.productactionBtn} />
            <Button
                disabled={!order.can_be_returned || disable}
                onClick={() => setOpen(true)}
                title={'Return Product'}
                className={(componnetType === 'order' ? 'submit ' : 'ignore ') + style.productactionBtn} />
            <Collapse
                className={style.collapse}
                isOpen={isOpenFirst}>
                <UpdateRentalTerms order={order} selectedRentalPeriod={selectedRentalPeriod} product={product} />
                {componnetType === 'rental' &&
                    <RentalPeriod
                        submitLoading={submitLoading}
                        changeRentalTerms={changeRentalTerms}
                        rentalOptions={rentalOptions}
                        selected={selectedRentalPeriod}
                        orderRentalPeriod={orderRentalPeriod}
                        isSelected={true}
                        onClick={handleRental} />
                }

                {componnetType === 'buy' &&
                <div className={style.box}>
                    <BuyProduct
                        loading={submitLoading}
                        price={price}
                        onClick={handleBuyNow} />
                </div>
                }
            </Collapse>
            <Collapse className={style.collapse} isOpen={isOpenSecond}>
                {componnetType === 'ticket' &&
                    <RiseTicket
                        riseTicket={riseTicket}
                        setRiseTicket={setRiseTicket}
                        onClick={handleRiseTicket}
                        submitLoading={submitLoading} />
                }
            </Collapse>
        </div>
    </div>);
}

UpdateRentalTerms.propTypes = {
    order: PropTypes.object,
    selectedRentalPeriod: PropTypes.object,
    product: PropTypes.object
};

function UpdateRentalTerms({ order, selectedRentalPeriod, product }) {
    const convertToDateString = (date) => {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

        return `${da} ${mo} ${ye}`;
    };

    const newEndDate = () => {
        // add selectedRentalPeriod.duration_time as number of months, to order.ends_at
        const newEndDate = new Date(order.ends_at);

        newEndDate.setMonth(newEndDate.getMonth() + selectedRentalPeriod.duration_time);

        return newEndDate;
    };

    const calcTotalPrice = () => {
        if (product.attributes.storage && product.attributes.storage.values.length !== 0) {
            const attribute = product.attributes.storage.values.find(e => e.id === order.product_attribute_value_id);
            let attributePrice = 0;

            if (attribute?.price_impact) {
                attributePrice = attribute.price_impacts.find(e => e.rental_period_id === selectedRentalPeriod.id).price_impacts;
            }

            const selectedRentalPeriodPrice = selectedRentalPeriod.price;

            let dealDiscount = 0;

            if (order?.meta?.deal?.discount_amount) {
                dealDiscount = order?.meta?.deal?.discount_amount;
            }

            return add($(attributePrice), $(selectedRentalPeriodPrice)).minus($(dealDiscount)).toString();
        } else {
            return order.total;
        }
    };

    return (
        <div className={style.updatedFigures}>
            <div>
                <h3>Current</h3>
                <div>
                    <span>Price</span>
                    <span>{product.currency || 'SGD'} {order.total}</span>
                </div>
                <div>
                    <span>End Date</span>
                    <span>{convertToDateString(order.ends_at)}</span>
                </div>
            </div>
            <div>
                <h3>New</h3>
                <div>
                    <span>Price</span>
                    <span> {product.currency || 'SGD'} {calcTotalPrice()}</span>
                </div>
                <div>
                    <span>End Date</span>
                    <span>{convertToDateString(newEndDate())}</span>
                </div>
            </div>
        </div>
    );
}

export default ProductActions;
