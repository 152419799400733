import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
    ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE,
    ROUTE_REDIRECT_FROM_AUTHED_ROUTE
} from 'config/constants';

import { getAuthToken } from 'utils/helpers';

/*
 * Route only for authed clients
 */
export class AuthedRoute extends Component {
    static propTypes = {
        component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
        isAuthed: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
        location: PropTypes.object
    };

    shouldComponentUpdate(nextProps) {
        const { isAuthed, path } = this.props;

        if (isAuthed !== nextProps.isAuthed ||
            path !== nextProps.path) {
            return true;
        }

        return false;
    }

    render() {
        const isAuthed = getAuthToken();
        const { location, ...props } = this.props;

        if (isAuthed) {
            return (
                <Route {...props} />
            );
        }

        return (
            <Redirect to={{
                pathname: ROUTE_REDIRECT_FROM_AUTHED_ROUTE,
                state: { from: location }
            }} />
        );
    }
}

/*
 * Route only for un-authed clients
 */
export class UnauthedRoute extends Component {
    static propTypes = {
        component: PropTypes.object.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
        location: PropTypes.object
    };

    shouldComponentUpdate(nextProps) {
        const { isAuthed, path } = this.props;

        if (isAuthed !== nextProps.isAuthed ||
            path !== nextProps.path) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed, location, ...props } = this.props;

        if (!isAuthed) {
            return (
                <Route {...props} />
            );
        }

        return (
            <Redirect to={{
                pathname: ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE,
                state: { from: location }
            }} />
        );
    }
}
