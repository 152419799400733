import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import './AppLoading.scss';

AppLoading.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number
};

AppLoading.defaultProps = {
    className: '',
    size: 50
};

export default function AppLoading({ className, size }) {
    return (
        <div className={`AppLoading ${className}`}>
            <Spin size='large' />
        </div>
    );
}
