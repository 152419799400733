import Button from 'containers/App/components/Button/Button';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import style from './OTPForm.module.scss';
import OtpInput from 'react-otp-input';
import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.svg';
import { storeUserEvents } from 'api/user';
// import SingpassLogo from 'assets/images/general/smallIcon.png';

OTPForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    timer: PropTypes.number,
    resendCode: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    otp: PropTypes.string,
    setOtp: PropTypes.func,
    lastnumber: PropTypes.string,
    singpass: PropTypes.bool,
    email: PropTypes.string,
    showResend: PropTypes.bool,
    backToMainLogin: PropTypes.func,
    numInput: PropTypes.number,
    isReset: PropTypes.bool
};

function OTPForm({
    submit,
    changeForm,
    timer,
    resendCode,
    loading,
    type,
    otp,
    backToMainLogin,
    setOtp,
    lastnumber,
    email = '',
    singpass = false,
    showResend = true,
    numInput = 6,
    isReset
}) {
    const handleChange = (otp) => setOtp(otp);

    const handleSubmit = e => {
        e.preventDefault();
        submit(type);
    };

    useEffect(() => {
        storeUserEvents({ event: 'forgot_otp' });
    }, []);

    return (
        <div className={style.section}>
            <form onSubmit={handleSubmit}>
                <h3>
                    {backToMainLogin && <img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />
                    }
                    Verification Required</h3>
                <p>We&apos;ve sent a One Time Password (OTP) to the email <span>{email ? `${email}` : `SMS sent to phone number ending with ${lastnumber}`}.</span> Please enter it below.</p>
                <div className={style.otpBox}>
                    <OtpInput
                        isInputNum={true}
                        containerStyle={style.container}
                        inputStyle={style.input}
                        value={otp}
                        onChange={handleChange}
                        numInputs={numInput}
                        separator={<span />} />
                </div>
                <div className={style.buttons}>
                    <Button
                        loading={loading}
                        type='submit'
                        title={'Resend OTP'}
                        disabled={timer !== 0} />
                </div>
                {(showResend && timer !== 0) && <div className={style.again}>{timer !== 0 ? '00:' + (timer < 10 ? '0' + timer : timer) : null}</div>}
            </form>
        </div>
    );
}

export default OTPForm;
