import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { IPHONE_16_SLUGS } from 'config/constants';

import truck from 'assets/images/general/truck.svg';
import umbrella from 'assets/images/general/umbrella.svg';
import basket from 'assets/images/general/basket.svg';

import './CommitmentAndInsideBox.scss';

CommitmentAndInsideBox.propTypes = {
    insideBoxItems: PropTypes.array
};

function CommitmentAndInsideBox({ insideBoxItems }) {
    const { slug } = useParams();

    const getDeliveryTimingsLabel = () => {
        if (IPHONE_16_SLUGS.includes(slug)) {
            return 'Estimated delivery: 2 weeks';
        }

        return 'Delivery in 3-5 business days';
    };

    const features = [
        { icon: umbrella, label: 'Free Cinch Insurance' },
        { icon: basket, label: 'Includes purchase option' },
        { icon: truck, label: getDeliveryTimingsLabel() }
    ];

    return (
        <div className='commitment-n-inside-box'>
            <div className='commitment-n-inside-box__container commitment-n-inside-box__commitment'>
                <p className='commitment-n-inside-box__container__title'>Our Commitments</p>
                <div className='commitment-n-inside-box__commitment__feature-container'>
                    {features.map((feature, index) => (
                        <div className='commitment-n-inside-box__commitment__feature' key={index}>
                            <img className='commitment-n-inside-box__commitment__feature__icon' src={feature.icon} alt={feature.label} />
                            <p className='commitment-n-inside-box__commitment__feature__label'>{feature.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='commitment-n-inside-box__container commitment-n-inside-box__inside-box'>
                <p className='commitment-n-inside-box__container__title'>Inside the box</p>
                <div className='commitment-n-inside-box__inside-box__items-container'>
                    {insideBoxItems.map((item, index) => (
                        <img className='icon' src={item?.meta?.icon_url} key={index}
                            alt={item?.value} />
                    ))}
                </div>
                {insideBoxItems.map((item, index) => (
                    <p key={index?.id} className='commitment-n-inside-box__inside-box__item-label'>{index + 1}. {item?.value}</p>
                ))}
            </div> */}
        </div>
    );
}

export default CommitmentAndInsideBox;
