import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';

import './Button.scss';

Button.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

// sample classnames : submit, ignore
export default function Button({ className, title, onClick, type, loading, disabled }) {
    const getClassNames = () => {
        let name = 'button';

        if (className) {
            name = name + ' ' + className;
        }

        if (disabled) {
            name = name + ' disabled';
        }

        return name;
    };

    return (
        <button
            className={getClassNames()}
            disabled={disabled || loading}
            onClick={onClick}
            type={type}>

            {loading ? <Spinner className='spinner' /> : title}
        </button>
    );
}
