import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux/store';
import ReactPixel from 'react-facebook-pixel';
import * as FS from '@fullstory/browser';
import * as Sentry from '@sentry/react';
// import TagManager from 'react-gtm-module';

import AppContainer from 'containers/App/AppContainer';
import 'config/execute';

import 'styles/styles.scss';
// import ReactGA from 'react-ga4';

// ReactGA.initialize('G-3YZYGR3XWT');
// ReactGA.gtag('config', 'GTM-NRZ5357');

//
ReactPixel.init('644982220654988');
ReactPixel.pageView();
//
FS.init({ orgId: 'o-1K4VNE-na1' });
Sentry.init({
    dsn: 'https://c5028291a4be406e8a9eba47d7c00a7a@o4505007320006656.ingest.sentry.io/4505033559441408',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.NODE_ENV
});

// const tagManagerArgs = {
//     gtmId: 'GTM-NRZ5357'
// };

// TagManager.initialize(tagManagerArgs);

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppContainer />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
