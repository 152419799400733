import PropTypes from 'prop-types';
import React from 'react';

import style from './BoxContent.module.scss';

BoxContent.propTypes = {
    options: PropTypes.array.isRequired
};

function BoxContent({ options }) {
    return (<div className={style.section}>
        {options.map((option, i) =>
            <div key={i} className={style.box}>
                {option.meta && option.meta.icon_url &&
                <div className={style.box__icon}>
                    <img src={option.meta.icon_url} alt={option.value} className={style.box__img} />
                </div>
                }
                <div className={style.box__title}>
                    {option.value}
                </div>
            </div>
        )}
    </div>);
}

export default BoxContent;
