import React from 'react';
import StampedReviews from 'components/Stamped/StampedReviews';

import './Reviews.scss';

export default function Reviews() {
    return (
        <>
            <section className='container reviews'id='review_section'>
                <h2 className='header'>Reviews</h2>
                <StampedReviews widgetId='stamped-main-widget' widgetType='slider' />
            </section>
        </>
    );
}
