import PropTypes from 'prop-types';
import React from 'react';

import style from './StorageOptionsMobieView.module.scss';

StorageOptionsMobieView.propTypes = {
    onClick: PropTypes.func.isRequired,
    selectedStorage: PropTypes.object,
    options: PropTypes.array
};

function StorageOptionsMobieView({
    onClick,
    selectedStorage,
    options
}) {
    return (
        <div className={style.StorageOptionsWrapper}>
            <div className={style.StorageOptionsWrapper__label}>Storage options</div>
            <div className={style.StorageOptionsWrapper__options}>
                {options && options.map((option, i) =>
                    <div
                        key={i}
                        className={selectedStorage && selectedStorage.value === option.value ? style.option + ' ' + style.option__selected : style.option}
                        onClick={() => onClick(option)}>
                        <div className={selectedStorage && selectedStorage.value === option.value ? style.option__title + ' ' + style.option__title__selected : style.option__title}>
                            <span className={style.option__title__color__selection__image__span}>{option.label}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>);
}

export default StorageOptionsMobieView;
