import PropTypes from 'prop-types';
import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment';
import { styled } from '@mui/material/styles';

import ProductActions from '../ProductActions/ProductActions';

import style from './AfterBuy.module.scss';

AfterBuy.propTypes = {
    product: PropTypes.object.isRequired,
    handleRiseTicket: PropTypes.func,
    submitLoading: PropTypes.bool,
    rentalOptions: PropTypes.array,
    selectedRentalPeriod: PropTypes.object,
    setSelectedRentalPeriod: PropTypes.func,
    changeRentalTerms: PropTypes.func,
    handleBuyNow: PropTypes.func,
    handleReturnOrder: PropTypes.func,
    order: PropTypes.any,
    isOpenFirst: PropTypes.bool,
    setIsOpenFirst: PropTypes.func,
    isOpenSecond: PropTypes.bool,
    setIsOpenSecond: PropTypes.func,
    componnetType: PropTypes.any,
    setComponnetType: PropTypes.func,
    riseTicket: PropTypes.object,
    setRiseTicket: PropTypes.func,
    returnOrderType: PropTypes.string,
    setReturnOrderType: PropTypes.func,
    orderRentalPeriod: PropTypes.object,
    setOrderRentalPeriod: PropTypes.func
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
    }
}));

function AfterBuy({
    product,
    handleRiseTicket,
    submitLoading,
    rentalOptions,
    selectedRentalPeriod,
    setSelectedRentalPeriod,
    changeRentalTerms,
    handleBuyNow,
    handleReturnOrder,
    order,
    isOpenFirst,
    setIsOpenFirst,
    isOpenSecond,
    setIsOpenSecond,
    componnetType,
    setComponnetType,
    riseTicket,
    setRiseTicket,
    returnOrderType,
    setReturnOrderType,
    orderRentalPeriod,
    setOrderRentalPeriod
}) {
    const isMobile = window.matchMedia('(max-width: 992px)').matches;

    const convertToDateString = date => {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

        return `${da} ${mo} ${ye}`;
    };

    const findSelectedRental = (list, id) => {
        const rental = list.find(e => e.id === id);
        const text = rental.duration_time + (!isMobile ? ' Month' : '') + (rental.duration_time > 1 ? (!isMobile ? 's' : '') : '');

        return text;
    };

    const renderSubscriptionRemainingTime = (item, product) => {
        if (product?.rental_period) {
            const currentDate = new Date();
            const deliveredDate = new Date(item?.delivered_at);
            const subcriptionEndsDate = new Date(item?.ends_at);
            const subscriptionLeft = Math.abs(currentDate.getTime() - deliveredDate.getTime());
            const actualSubscriptionPeriod = product?.rental_period.filter(r => r?.id === item?.rental_period_id)[0]?.duration_time;
            const subscriptionLeftInMonths = subcriptionEndsDate > currentDate ? Math.ceil(subscriptionLeft / (2e3 * 3600 * 365.25)) : actualSubscriptionPeriod;
            const percentage = subscriptionLeftInMonths / actualSubscriptionPeriod * 100;

            return {
                percentage: percentage,
                endVal: subscriptionLeftInMonths
            };
        }
    };

    return (<div className={style.section}>
        <div>
            <h1 className={style.title}>
                {product.name}
            </h1>
            <h5 className={style.category}>
                {product.category && product.category.name}
            </h5>
        </div>
        <p className={style.desc}>
            {product.description}
        </p>
        <div className={style.info}>
            <div className={style.labelsSection}>
                <span>Subscription Price</span>
                <span>{product.currency || 'SGD'} {order.total}</span>
            </div>
            {product.colors && product.colors.length !== 0 &&
            <div className={style.labelsSection}>
                <span>Colour</span>
                <span>{product.colors.find(e => e.id === order.color_id).name}</span>
            </div>}
            {(product.attributes.storage && product.attributes.storage.values.length !== 0) &&
            <div className={style.labelsSection}>
                <span>Storage</span>
                {product.attributes.storage.values && <span>{product.attributes.storage.values.find(e => e.id === order.product_attribute_value_id)?.value}</span>}
            </div>}
            {order.rental_terms &&
            <div className={style.labelsSection}>
                <span>Rental Term</span>
                <span>{order.rental_terms}</span>
            </div>}
            {order.starts_at &&
            <div className={style.labelsSection}>
                <span>Start Date</span>
                <span>{convertToDateString(order.starts_at)}</span>
            </div>}
            {(order.ends_at && order.ends_at !== '') &&
            <>
                <div className={style.labelsSection}>
                    <span>End Date</span>
                    <span>{convertToDateString(order.ends_at)}</span>
                </div>
                <div className={style.labelsSection}>
                    <span>Term Elapsed</span>
                </div>
                <div className={style.progressBarContainer}>
                    <BorderLinearProgress variant='determinate' value={renderSubscriptionRemainingTime(order, product)?.percentage} />
                </div>
                <div className={style.subscriptionTermTextFull}>
                    <span>{renderSubscriptionRemainingTime(order, product)?.endVal} / {findSelectedRental(product.rental_period, order.rental_period_id)} month(s)</span>
                    <span>Ends {moment(order.ends_at).format('l')}</span>
                </div>
            </>}
        </div>

        {order && order.status === 'pending' && <div className={style.desc}> We are checking your order , we will get back to you soon.</div> }

        <div>
            <ProductActions
                product={product}
                returnOrderType={returnOrderType}
                setReturnOrderType={setReturnOrderType}
                riseTicket={riseTicket}
                setRiseTicket={setRiseTicket}
                isOpenFirst={isOpenFirst}
                setIsOpenFirst={setIsOpenFirst}
                isOpenSecond={isOpenSecond}
                setIsOpenSecond={setIsOpenSecond}
                componnetType={componnetType}
                setComponnetType={setComponnetType}
                order={order}
                handleReturnOrder={handleReturnOrder}
                price={String(product.currency + ' ' + product.purchase_price)}
                handleBuyNow={handleBuyNow}
                selectedRentalPeriod={selectedRentalPeriod}
                setSelectedRentalPeriod={setSelectedRentalPeriod}
                changeRentalTerms={changeRentalTerms}
                handleRiseTicket={handleRiseTicket}
                submitLoading={submitLoading}
                rentalOptions={rentalOptions}
                orderRentalPeriod={orderRentalPeriod}
                setOrderRentalPeriod={setOrderRentalPeriod} />
        </div>
    </div>);
}

export default AfterBuy;
