import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import style from './Cart.module.scss';
import close from 'assets/images/general/close.svg';
import basket from 'assets/images/general/basket-white.svg';
import Button from 'containers/App/components/Button/Button';
import minus from 'assets/images/general/minus.svg';
import plus from 'assets/images/general/plus.svg';
import { addProductToCart, removeOneProductfromCart, removeProductfromCart } from 'redux/reducers/cart';
import { connect } from 'react-redux';
import sampleImg from 'assets/images/noImg.png';
// import { $, add } from 'moneysafe';
import { returnImgOfColor } from 'utils/helpers';
import { updateUserCart } from 'api/cart';
import FullscreenLoader from 'components/FullscreenLoader/FullscreenLoader';

Cart.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    removeProductfromCart: PropTypes.func.isRequired,
    removeOneProductfromCart: PropTypes.func.isRequired,
    submitCart: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    product: PropTypes.any,
    cartItems: PropTypes.array,
    reFetchCart: PropTypes.func,
    cartMeta: PropTypes.object
};

function Cart({
    isOpen,
    toggle,
    // products,
    // addProductToCart,
    // removeProductfromCart,
    // removeOneProductfromCart,
    submitCart,
    loading,
    product,
    cartItems,
    reFetchCart,
    cartMeta
}) {
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const handleNextMonth = async (e, product) => {
        const periods = product.base_product.rental_period;

        const selectedPeriod = product.rental_period;

        const index = periods.findIndex(i => i.id === selectedPeriod.id);

        if (index > -1 && (index + e) > -1 && (index + e) < periods.length) {
        // // update order
            setLoadingUpdate(true);

            const id = product.product_attribute_value_id;
            const data = {
                rental_period_id: periods[index + e].id,
                is_purchase: false,
                quantity: 1,
                color_id: product.color.id
            };

            try {
                // id : product_attribute_value_id
                await updateUserCart(id, data);

                // refetch cart items
                reFetchCart();
            } catch (error) {
                //
            }

            setLoadingUpdate(false);
        }
    };

    const CheckIsRentalMonthIndex = (product, type) => {
        const selected = product.rental_period;
        const rentals = product.base_product.rental_period;

        const index = rentals.findIndex(e => e.id === selected.id);

        if (index === 0 && type === 'first') {
            // is first one

            return true;
        }

        if (index === rentals.length - 1 && type === 'last') {
            // is lastOne

            return true;
        }

        return false;
    };

    const getAttribute = (item) => {
        // base_product.attributes where type: "storage" attribute_values where id === product_attribute_value_id
        const find = item.base_product.attributes.find(e => e.type === 'storage');

        if (find) {
            const findAttribute = find.attribute_values.find(e => e.id === item.product_attribute_value_id);

            // console.log('findAttribute', findAttribute);
            return '/ ' + (findAttribute && findAttribute.value);
        } else { return ''; }
    };

    return (<Modal
        isOpen={isOpen}
        toggle={() => toggle(false)}
        className={style.modal}>
        <div className={style.section}>
            <div className={style.header}>
                <div className={style.header__title}>Your Cart</div>
                <div className={style.header__close}>
                    <img className={style.header__close__img}
                        onClick={() => toggle(false)}
                        src={close}
                        alt='Close button' />
                </div>
            </div>
            <div className={style.body}>
                {(loading || loadingUpdate) && <FullscreenLoader />}
                {cartItems.map((product, i) =>
                    <div className={style.product} key={i}>
                        <div className={style.top}>
                            <div className={style.top__img}>
                                <img
                                    src={product.base_product.medias.length !== 0
                                        ? returnImgOfColor(product.color.id, product.base_product.medias)
                                        : sampleImg}
                                    alt={product?.base_product?.name}
                                    className={style.top__img__icon}/>
                            </div>
                            <div className={style.top__info}>
                                <div className={style.top__info__desc}>
                                    Select your minimum rental period:
                                </div>
                                <div className={style.top__info__title}>
                                    {product?.base_product?.name}
                                </div>
                                <div className={style.top__info__color}>
                                    {product.color.name} {getAttribute(product)}
                                </div>
                            </div>
                        </div>
                        <div className={style.bottom}>
                            <div className={style.month}>
                                <div className={style.counter}>
                                    {!CheckIsRentalMonthIndex(product, 'first') &&
                                    <img src={minus}
                                        alt='Minus quantity icon'
                                        className={style.counter__icon}
                                        onClick={() => handleNextMonth(-1, product)}/>
                                    }
                                </div>
                                <div className={style.qty}>
                                    {product.rental_period.duration_time}<span className={style.qty__title}>Month</span>
                                </div>
                                <div className={style.counter}>
                                    {!CheckIsRentalMonthIndex(product, 'last') &&
                                    <img src={plus}
                                        alt='Plus quantity icon'
                                        className={style.counter__icon}
                                        onClick={() => handleNextMonth(1, product)}/>
                                    }
                                </div>
                            </div>
                            <div className={style.price}>
                                <div className={style.price__value}>
                                    SGD {product.total}
                                </div>
                                <div className={style.price__label}>
                                    Per Month
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={style.total}>
                    <div className={style.total__label}>
                                Total
                    </div>
                    <div className={style.total__value}>
                        {cartMeta?.total}
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.col}>
                        <Button
                            // disabled={products.length === 0}
                            // disabled={!!product}
                            // loading={loading}
                            onClick={() => submitCart(product)}
                            className={'submit'}
                            title={<span className={style.submit}>
                                <img
                                    src={basket}
                                    alt='Basket icon'
                                    className={style.submit__icon}/>
                                <span
                                    className={style.submit__title}>
                                            Proceed to order
                                </span>
                            </span>} />
                    </div>
                    <div className={style.col}>
                        <Button
                            // disabled={products.length === 0}
                            // disabled={!!product}
                            onClick={() => {
                                //  submitCart(product);
                                toggle(false);
                            }}
                            title={'Continue Shopping'}
                            className={'ignor ' + style.ignor} />
                    </div>
                </div>
            </div>
        </div>
    </Modal>);
}

function mapStateToProps({ cart }) {
    const { products, product } = cart;

    return {
        products: JSON.parse(products),
        product
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addProductToCart: (product) => {
            dispatch(addProductToCart(product));
        },
        removeProductfromCart: (product) => {
            dispatch(removeProductfromCart(product));
        },
        removeOneProductfromCart: (product) => {
            dispatch(removeOneProductfromCart(product));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
