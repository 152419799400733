import { createAxiosInstance, getAuthToken, getNavigatorData } from 'utils/helpers';

const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

const CREATE_ORDER_START = 'CREATE_ORDER_START';
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

// const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
// const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
// const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

const fetchOrdersStart = () => ({
    type: FETCH_ORDERS_START
});

const fetchOrdersSuccess = (data) => ({
    type: FETCH_ORDERS_SUCCESS,
    data
});

const fetchOrdersFailure = () => ({
    type: FETCH_ORDERS_FAILURE
});

const createOrderStart = () => ({
    type: CREATE_ORDER_START
});

const createOrderSuccess = () => ({
    type: CREATE_ORDER_SUCCESS
});

const createOrderFailure = () => ({
    type: CREATE_ORDER_FAILURE
});

// const updateOrderStart = () => ({
//     type: UPDATE_ORDER_START
// });

// const updateOrderSuccess = (data) => ({
//     type: UPDATE_ORDER_SUCCESS,
//     data
// });

// const updateOrderFailure = () => ({
//     type: UPDATE_ORDER_FAILURE
// });

export const handleFetchingOrders = () => {
    return async (dispatch) => {
        dispatch(fetchOrdersStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/order');

            dispatch(fetchOrdersSuccess(data));
        } catch (e) {
            console.error(e);
            dispatch(fetchOrdersFailure());
        }
    };
};

export const handleCreateOrder = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(createOrderStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        form.navigator = JSON.stringify(getNavigatorData());

        try {
            const res = await instance.post('/order/create', form);

            dispatch(createOrderSuccess());
            onSuccess(res?.data);
        } catch (e) {
            // const status = e.response.status;
            const msg = e?.response?.data?.data?.message;

            // if (msg) {
            //     notify(msg, notifType.DANGER);
            // }

            dispatch(createOrderFailure());
            onFailure(msg || 'sorry we can\'t make your order please contact us!');
        }
    };
};

const initialState = {
    orders: []
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS_SUCCESS: {
            return { state, orders: action.data };
        }

        default:
            return state;
    }
}
