import React, { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionItem } from 'reactstrap';

import { categories, content } from './data';

import { ROUTES } from 'config/constants';

import IcPlus from 'assets/images/general/ic-plus-rounded.svg';
import IcMinus from 'assets/images/general/ic-minus-rounded.svg';

import './FAQ.scss';

export default function FAQ() {
    const defaultCategory = categories[0].key;
    const [activeCategory, setActiveCategory] = useState(defaultCategory);
    const [faq, setFaq] = useState([]);
    const [open, setOpen] = useState();

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        setFaq(content[activeCategory]);
    }, [activeCategory]);

    useEffect(() => {
        setFaq(content[defaultCategory]);
    }, []);

    return (
        <section id='faq' className='faq'>
            <div className='section-heading'>
                <h2 className='faq-section-title'>Frequently asked questions</h2>
                <h4 className='section-subtitle'>Everything you need to know about subscriptions, damage protection, etc.</h4>
            </div>
            <div className='faq__types'>
                {categories.map((category) => (
                    <div key={category.key} onClick={() => setActiveCategory(category.key)}
                        className={`faq__types__type ${activeCategory === category.key ? 'active' : ''}`}>
                        {category.label}
                    </div>
                ))}
            </div>
            <div className='faq__content'>
                <Accordion open={open}>
                    {faq.map((faq) =>

                        <AccordionItem key={faq.id}>
                            <div className='faq__content__wrapper'>
                                <div className='faq__content__wrapper__item'>
                                    <p onClick={() => toggle(faq.id)}
                                        className='faq__content__wrapper__item__question'>{faq.title}</p>
                                    <AccordionBody accordionId={faq.id}>
                                        <p className='faq__content__description'>
                                            {faq.text}
                                            {faq.link && (
                                                <a
                                                    href={faq.link === 'forgot_password' ? ROUTES.HOME + '?forgot=true'
                                                        : faq.link ===
                                                            'official Apple instructions.'
                                                            ? 'https://www.support.apple.com/en-sg/guide/iphone/iphea1c2fe48/ios'
                                                            : faq.link ===
                                                                'official Google instructions.'
                                                                ? 'https://support.google.com/android/answer/6088915?hl=en'
                                                                : faq.link ===
                                                                    'privacy policy'
                                                                    ? ROUTES.PRIVACY_POLICY
                                                                    : faq.link ===
                                                                        'cinch.sg' ? ROUTES.HOME
                                                                        : faq.link ===
                                                                            'support@cinch.sg' ? 'mailto:support@cinch.sg' : faq.link}>
                                                    {faq.link === 'forgot_password' ? 'link' : faq.link}
                                                </a>
                                            )}
                                            {faq.text2}
                                            {faq.link2 && (
                                                <a
                                                    href={faq.link2 === 'forgot_password' ? ROUTES.HOME + '?forgot=true'
                                                        : faq.link2 ===
                                                            'official Apple instructions.'
                                                            ? 'https://www.support.apple.com/en-sg/guide/iphone/iphea1c2fe48/ios'
                                                            : faq.link2 ===
                                                                'official Google instructions.'
                                                                ? 'https://support.google.com/android/answer/6088915?hl=en'
                                                                : faq.link2 ===
                                                                    'privacy policy'
                                                                    ? ROUTES.PRIVACY_POLICY
                                                                    : faq.link2 ===
                                                                        'cinch.sg' ? ROUTES.HOME
                                                                        : faq.link2 ===
                                                                            'support@cinch.sg' ? 'mailto:support@cinch.sg' : faq.link2}>
                                                    {faq.link2 === 'forgot_password' ? 'link' : faq.link2}
                                                </a>
                                            )}
                                            {faq.text3}
                                        </p>
                                    </AccordionBody>
                                </div>
                                <img onClick={() => toggle(faq.id)}
                                    id={faq.id}
                                    className='faq__content__wrapper__icon'
                                    src={open && open === faq.id ? IcMinus : IcPlus}
                                    alt='Expand' />
                            </div>
                        </AccordionItem>
                    )}
                </Accordion>
            </div>
        </section>
    );
}
