import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

/**
 * get products
 */

export async function getProducts(query) {
    const updatedQuery = query.replace('?', '&');
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL + `/products?paginate=true&limit=12${updatedQuery.replace('query', 'search')}`
    };

    if (getAuthToken()) {
        options.headers = {
            Authorization: 'Bearer ' + getAuthToken()
        };
    }

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get min and max value prices in products
 */

export async function getRentalsMinMaxValues(periodQuery) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/rentals/price${periodQuery ? '?rental_period=' + periodQuery : ''
            }`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get autocomplete products
 */

export async function getAutocompleteProducts(query) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + `/products?search=${query}&autocomplete=true`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get new products
 */

export async function getNewProducts(query, limit) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/products?paginate=true${limit ? '&limit=' + limit : ''}${query && query.replace('?', '&')
            }`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get recently viewed products
 */

export async function getRecentlyViewedProducts() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/recent-products'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        const status = err.response.status;

        if (status) {
            console.error(err.response);
        }

        throw err;
    }
}

/**
 * store recently viewed products
 */

export async function storeRecentlyViewedProducts(products) {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/recent-products',
        data: { products: [products] }
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get top products
 */

export async function getTopProducts(query, limit) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/products?paginate=true&tags=Top${limit ? '&limit=' + limit : ''}${query && query.replace('?', '&')
            }`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get deal products
 */

export async function getDealProducts(query, limit) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/products?paginate=false&tags=Deal${limit ? '&limit=' + limit : ''
            }${query && query.replace('?', '&')}`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get products by category
 */

export async function getProductsByCategory(categ) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + `/products?paginate=true&category=${categ}&limit=4`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get homepage explore products
 */

export async function getExploreProducts() {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products?explore=true&limit=9'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get homepage trendy products
 */

export async function getTrendyProducts() {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products?best=true&limit=12'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get deals
 */

export async function getDeals() {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products?deals=true&limit=8'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * return product
 * @param {Object} params
 */

export async function returnProduct(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/products/return',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        }
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * return product
 * @param {Object} params
 */

export async function contactProduct(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/products/contact',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        }
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * return product of one category
 * @param {number} id
 */

export async function categoryProducts(id) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products?category[]=' + id
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * return products
 *  @param {Number} productId
 *  @param {Number} categoryId
 */
export async function fetchSimilarProducts(productId, categoryId) {
    const options = {
        method: 'GET',
        url: productId ? `${AUTH_API_URL}/products/similar/?product_id=${productId}&category_id=${categoryId}` : `${AUTH_API_URL}/products/similar/?category_id=${categoryId}`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        return false;
    }
}

/**
 * get new products
 */

export async function getFeaturedProductsListForRent() {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/assortment-product-lists/featured'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * get products
 */

export async function getProductsForAssortmentPage(query) {
    const options = {
        method: 'GET',
        url:
            AUTH_API_URL +
            `/assortment-product-lists/${query}`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
