import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { ROUTES } from 'config/constants';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { validateEmail } from 'utils/helpers';

import { storeUserEvents } from 'api/user';

import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.svg';
import EyeIcon from 'assets/images/eye-icon.svg'; // Add this import for the eye icon
import EyeSlashIcon from 'assets/images/eye-slash-icon.svg'; // Add this import for the hide icon

import style from './SignUpForm.module.scss';

SignUpForm.propTypes = {
    submit: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string,
    backToMainLogin: PropTypes.func
};

function SignUpForm({
    submit,
    loading,
    email,
    setEmail,
    backToMainLogin
}) {
    const [checked, setChecked] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        const email = (e.target.email.value).replace(/ /g, '');
        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        if (!checked) return true;

        if (!validateEmail(email)) {
            notify('Please enter a valid email address. ', notifType.DANGER);

            return true;
        }

        if (password === '' || confirmPassword === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        if (password !== confirmPassword) {
            notify('Password and Confirm Password do not match.', notifType.DANGER);

            return true;
        }

        if (password.length < 5 || password.length > 48) {
            const message = password.length < 5
                ? 'Password is too short. Please choose another one.'
                : 'Password is too long. Please choose another one.';

            notify(message, notifType.DANGER);

            return true;
        }

        submit({
            email,
            password,
            confirmPassword
        });
    };

    useEffect(() => {
        storeUserEvents({
            event: 'sign_up'
        });
    }, []);

    const renderLabelComponent = () => <label className={style.label} style={{ marginBottom: '0px' }}>I agree to the <a href={ROUTES.TERMS_AND_CONDITIONS} target='_blank' rel='noreferrer'>Terms and Conditions</a></label>;

    const handleChange = (event) => setChecked(event.target.checked);

    const handleCopyPaste = (e) => {
        e.preventDefault();
    };

    return (<div className={`${style.section}  fs-exclude`}>
        <form onSubmit={handleSubmit}>
            <h3><img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />Welcome to Cinch</h3>
            <p>Please sign up to continue.</p>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Email Address
                    </div>
                    <div className={style.value}>
                        <input name='email' type='email' className={style.value__input}
                            placeholder='Email Address' value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='password'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                className={style.value__input + ' ' + style.withIcon__input} placeholder='Password'
                                type={showPassword ? 'text' : 'password'} />
                            <img
                                src={showPassword ? EyeIcon : EyeSlashIcon}
                                alt='Toggle password visibility'
                                className={style.passwordToggle}
                                onClick={() => setShowPassword(!showPassword)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Confirm Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='confirmPassword'
                                onCopy={handleCopyPaste}
                                onPaste={handleCopyPaste}
                                onCut={handleCopyPaste}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                className={style.value__input + ' ' + style.withIcon__input} placeholder='Confirm Password'
                                type={showConfirmPassword ? 'text' : 'password'} />
                            <img
                                src={showConfirmPassword ? EyeIcon : EyeSlashIcon}
                                alt='Toggle password visibility'
                                className={style.passwordToggle}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.row + ' ' + style.term_condition}>
                <div className={style.row__fullCol}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} sx={{
                        color: '#667085',
                        '&.Mui-checked': {
                            color: '#6A4BFF'
                        }
                    }} checked={checked} />} label={renderLabelComponent()} />
                </div>
            </div>
            <div className={style.buttons}>
                <Button
                    loading={loading}
                    type='submit'
                    title={'Sign Up'}
                    disabled={!checked || !email || !password || password?.length < 5 || password?.length > 48 || !confirmPassword || password !== confirmPassword}
                    className={'submit'} />
            </div>
        </form>
    </div>);
}

export default SignUpForm;
