import React from 'react';
import Iframe from 'react-iframe';

const ImeiLookup = () => {
    return (
        <>
            <Iframe url='https://r83ba83b7i.execute-api.ap-southeast-1.amazonaws.com/prod/imei-lookup' name='business-iframe' width='100%'
                height='900px' allowFullScreen='true' />
        </>
    );
};

export default ImeiLookup;
