import { createAxiosInstance, getAuthToken } from 'utils/helpers';

const FETCH_ADDRESS_START = 'FETCH_ADDRESS_START';
const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
const FETCH_ADDRESS_FAILURE = 'FETCH_ADDRESS_FAILURE';

const CREATE_ADDRESS_START = 'CREATE_ADDRESS_START';
const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

const UPDATE_ADDRESS_START = 'UPDATE_ADDRESS_START';
const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

const fetchAddressStart = () => ({
    type: FETCH_ADDRESS_START
});

const fetchAddressSuccess = (data) => ({
    type: FETCH_ADDRESS_SUCCESS,
    data
});

const fetchAddressFailure = () => ({
    type: FETCH_ADDRESS_FAILURE
});

const createAddressStart = () => ({
    type: CREATE_ADDRESS_START
});

const createAddressSuccess = () => ({
    type: CREATE_ADDRESS_SUCCESS
});

const createAddressFailure = () => ({
    type: CREATE_ADDRESS_FAILURE
});

const updateAddressStart = () => ({
    type: UPDATE_ADDRESS_START
});

const updateAddressSuccess = (data) => ({
    type: UPDATE_ADDRESS_SUCCESS,
    data
});

const updateAddressFailure = () => ({
    type: UPDATE_ADDRESS_FAILURE
});

export const handleFetchAddress = () => {
    return async (dispatch) => {
        dispatch(fetchAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/user/address');

            dispatch(fetchAddressSuccess(data));
        } catch (e) {
            console.error(e);
            dispatch(fetchAddressFailure());
        }
    };
};

export const handleCreateAddress = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(createAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/user/address', form);

            dispatch(handleFetchAddress());
            dispatch(createAddressSuccess());

            onSuccess(data);
        } catch (e) {
            console.error(e);
            dispatch(createAddressFailure());

            onFailure();
        }
    };
};

export const handleUpdateAddress = (id, form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(updateAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.put(`user/address/${id}`, form);

            dispatch(updateAddressSuccess(form));

            onSuccess();
        } catch (e) {
            console.error(e);
            dispatch(updateAddressFailure());

            onFailure();
        }
    };
};

const initialState = {
    address: {},
    isLoading: false
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADDRESS_START:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.data,
                isLoading: false
            };

        case FETCH_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case UPDATE_ADDRESS_START:
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                address: { ...state.address, ...action.data },
                isLoading: false
            };

        case UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}
