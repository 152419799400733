// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './Bell.module.scss';
import bell from 'assets/images/general/bell.svg';
import logo from 'assets/images/general/logo.svg';
import { Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config/constants';

// Bell.propTypes = {
//     product: PropTypes.object.isRequired,
//     handleRiseTicket: PropTypes.func,
//     submitLoading: PropTypes.bool
// };

function Bell() {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const goToMyAccount = () => {
        handleClose();
        history.push(ROUTES.MYINFORMATION);
    };

    return (<div className={style.bell}>
        <div className={style.bell__box} onClick={handleOpen}>
            <img src={bell} className={style.bell__img} alt='Notification bell icon' />
        </div>
        <Modal isOpen={isOpen}
            toggle={handleClose}
            className={style.modal}>
            <div className={style.section}>
                <img src={logo} alt='Cinch Logo' className={style.section__logo} />
                <p className={style.section__text}>
                    Your profile is currently incomplete. To unlock all of our features, including checkout, please continue to the My Account page to fill in all the required information.
                </p>
                <button className={style.section__button} onClick={goToMyAccount}>Complete My Profile</button>
            </div>
        </Modal>
    </div>);
}

export default Bell;
