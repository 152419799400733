import { createAxiosInstance, getAuthToken } from 'utils/helpers';

const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const REMOVE_ONE_ITEM_FROM_CART = 'REMOVE_ONE_ITEM_FROM_CART';
const CLEAR_CART = 'CLEAR_CART';

const FETCH_CART_START = 'FETCH_CART_START';
const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
const UPDATE_CART_ITEM_START = 'UPDATE_CART_ITEM_START';
const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
const UPDATE_CART_ITEM_FAILURE = 'UPDATE_CART_ITEM_FAILURE';
const REMOVE_CART_ITEM_START = 'REMOVE_CART_ITEM_START';
const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';
const CLEAR_FETCHED_CART_START = 'CLEAR_FETCHED_CART_START';
const CLEAR_FETCHED_CART_SUCCESS = 'CLEAR_FETCHED_CART_SUCCESS';
const CLEAR_FETCHED_CART_FAILURE = 'CLEAR_FETCHED_CART_FAILURE';
const NOTIFY_HEADER_ON_CART_UPDATE = 'NOTIFY_HEADER_ON_CART_UPDATE';
const FETCH_CART_EMPTY = 'FETCH_CART_EMPTY';

export const addProductToCart = (product) => ({
    type: ADD_TO_CART,
    product
});

export const removeProductfromCart = (product) => ({
    type: REMOVE_FROM_CART,
    product
});

export const removeOneProductfromCart = (product) => ({
    type: REMOVE_ONE_ITEM_FROM_CART,
    product
});

export const clearCart = () => ({
    type: CLEAR_CART
});

export const fetchCartStart = () => ({
    type: FETCH_CART_START
});

export const fetchCartSuccess = (data) => ({
    type: FETCH_CART_SUCCESS,
    data
});

export const fetchCartFailure = () => ({
    type: FETCH_CART_FAILURE
});

export const updateCartItemStart = () => ({
    type: UPDATE_CART_ITEM_START
});

export const updateCartItemSuccess = (data) => ({
    type: UPDATE_CART_ITEM_SUCCESS,
    data
});

export const updateCartItemFailure = () => ({
    type: UPDATE_CART_ITEM_FAILURE
});

export const removeCartItemStart = () => ({
    type: REMOVE_CART_ITEM_START
});

export const removeCartItemSuccess = (data) => ({
    type: REMOVE_CART_ITEM_SUCCESS,
    data
});

export const removeCartItemFailure = () => ({
    type: REMOVE_CART_ITEM_FAILURE
});

export const clearFetchedCartStart = () => ({
    type: CLEAR_FETCHED_CART_START
});

export const clearFetchedCartSuccess = () => ({
    type: CLEAR_FETCHED_CART_SUCCESS
});

export const clearFetchedCartFailure = () => ({
    type: CLEAR_FETCHED_CART_FAILURE
});

export const notifyHeaderOnCartUpdate = (data) => ({
    type: NOTIFY_HEADER_ON_CART_UPDATE,
    data
});

export const forceEmptyTheCart = () => ({
    type: FETCH_CART_EMPTY
});

export const handleFetchingCart = () => {
    return async (dispatch) => {
        dispatch(fetchCartStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/user/cart');

            dispatch(fetchCartSuccess(data));
        } catch (e) {
            dispatch(fetchCartFailure());
        }
    };
};

export const handleClearCart = () => {
    return async (dispatch) => {
        dispatch(clearFetchedCartStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('/user/cart/empty');

            dispatch(clearFetchedCartSuccess());
        } catch {
            dispatch(clearFetchedCartFailure());
        }
    };
};

export const handleUpdateCartItem = (form, id, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(updateCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.put(`/user/cart/${id}`, form);

            dispatch(handleFetchingCart());
            dispatch(updateCartItemSuccess());
        } catch (err) {
            dispatch(updateCartItemFailure());

            if (err.response) {
                onFailure(err.response);
            }
        }
    };
};

export const handleUpdateCartForCoupon = (form) => {
    return async (dispatch) => {
        dispatch(updateCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('/user/cart/', form);

            dispatch(handleFetchingCart());
            dispatch(updateCartItemSuccess());
        } catch {
            dispatch(updateCartItemFailure());
        }
    };
};

export const handleRemoveCartItem = (id, onSuccess) => {
    return async (dispatch) => {
        dispatch(removeCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.delete(`/user/cart/${id}`);

            dispatch(handleFetchingCart());
            dispatch(removeCartItemSuccess());
            onSuccess();
        } catch {
            dispatch(removeCartItemFailure());
        }
    };
};

const initialState = {
    products: localStorage.getItem('products') || '[]',
    // products that are fetched from BE
    fetchedProducts: [],
    overview: {},
    isClearingFetchedProducts: false,
    isLoading: false,
    product: null,
    notifyHeader: false
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART: {
            const { product } = action;

            const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];

            const checkIfExist = products.findIndex(e => (e.id === product.id));

            if (checkIfExist < 0) {
                products.push({ ...product, qty: 1 });
            } else {
                products[checkIfExist] = product;
            }

            localStorage.setItem('products', JSON.stringify(products));

            return {
                ...state,
                product,
                products: JSON.stringify(products)
            };
        }

        case REMOVE_FROM_CART: {
            // products;
            // const { product } = action;
            // const products = JSON.parse(state.products);

            // const checkIfExist = products.findIndex(e => (e.id === product.id) && (e.color.value === product.color.value));

            // if (checkIfExist > -1) {
            //     products[checkIfExist].qty--;

            //     if (products[checkIfExist].qty < 1) {
            //         products.splice(checkIfExist, 1);
            //     }
            // }

            return {
                ...state,
                product: null
                // products: JSON.stringify(products)
            };
        }

        case REMOVE_ONE_ITEM_FROM_CART: {
            const { product } = action;
            const products = JSON.parse(state.products);

            const checkIfExist = products.findIndex(e => e.id === product.id && e.selectedRentalPeriod.id === product.selectedRentalPeriod.id);

            if (checkIfExist > -1) {
                products.splice(checkIfExist, 1);
            }

            return {
                ...state,
                products: JSON.stringify(products)
            };
        }

        case CLEAR_CART: {
            localStorage.removeItem('products');

            return {
                ...state,
                products: '[]',
                product: null
            };
        }

        case FETCH_CART_START: {
            return {
                ...state,
                isLoading: true
            };
        }

        case FETCH_CART_SUCCESS: {
            return {
                ...state,
                fetchedProducts: action.data?.data?.products || [],
                overview: action.data?.meta || {},
                isLoading: false
            };
        }

        case FETCH_CART_EMPTY: {
            return {
                ...state,
                fetchedProducts: [],
                overview: {}
            };
        }

        case FETCH_CART_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CLEAR_FETCHED_CART_START: {
            return {
                ...state,
                isClearingFetchedProducts: true,
                isLoading: true
            };
        }

        case CLEAR_FETCHED_CART_SUCCESS: {
            return {
                ...state,
                fetchedProducts: [],
                overview: {},
                isClearingFetchedProducts: false,
                isLoading: false
            };
        }

        case CLEAR_FETCHED_CART_FAILURE: {
            return {
                ...state,
                isClearingFetchedProducts: false,
                isLoading: false
            };
        }

        case UPDATE_CART_ITEM_START:
        case REMOVE_CART_ITEM_START:
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_CART_ITEM_SUCCESS:
        case UPDATE_CART_ITEM_FAILURE:
        case REMOVE_CART_ITEM_SUCCESS:
        case REMOVE_CART_ITEM_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case NOTIFY_HEADER_ON_CART_UPDATE:
            return {
                ...state,
                notifyHeader: action.data
            };

        default:
            return state;
    }
}
