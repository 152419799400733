import React from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'config/constants';

import './explore-item.scss';

const ExploreItem = ({ image, title, id }) => {
    const expoloreItemClickHandler = slug => {
        window.location.href = `${ROUTES.PRODUCTS}?category[]=${id}&rental_period=12`;
    };

    return (
        <div className='explore__item-container' onClick={() => expoloreItemClickHandler(id)}>
            <div className='explore__item_single'>
                <img className='explore__item-image' src={image} alt={`Icon for ${title} category`} />
            </div>
            <p>{title}</p>
        </div>
    );
};

ExploreItem.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string
};

export default ExploreItem;
