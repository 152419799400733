import React from 'react';

import ImageGroup from 'assets/images/get-in-touch-image.png';

import './GetInTouch.scss';

export default function GetInTouch() {
    return (
        <section className='getInTouch'>
            <div className='getInTouch__container'>
                <img src={ImageGroup} alt='Get in touch' />
                <div>
                    <p className='getInTouch__title'>Still have questions? We&apos;re all ears.</p>
                    <p className='getInTouch__desc'>Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                </div>
                <a className='getInTouch__btn' href='https://wa.me/6581688604' target='_new'>Get in touch</a>
            </div>
        </section>
    );
}
