import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';

import { storeRecentlyViewedProducts } from 'api/products';

import { ROUTES } from 'config/constants';

import NoImg from 'assets/images/noImg.png';
import Right from 'assets/images/arrow-right-s-line.svg';

import './product-item.scss';

function ProductItem({
    image,
    title,
    currency,
    price,
    item,
    isAuthed,
    eligible,
    productUrl,
    titleTrimmed,
    source,
    slug,
    badgeName,
    badgeColor
}) {
    const isMobile = window.matchMedia('(max-width: 992px)').matches;
    const [periodQuery] = useQueryParam('rental_period', StringParam);
    const rentalTermMatch = item.rental_period?.find((x) => x.duration_time.toString() === periodQuery);
    const allBrands = localStorage.getItem('basicBrands');
    const allBrandsList = allBrands ? JSON.parse(allBrands) : [];

    const linkProps = (productUrl !== '' && productUrl !== undefined)
        ? {
            href: productUrl + source,
            target: '_blank',
            rel: 'noopener noreferrer'
        }
        : {
            href: ROUTES.PRODUCT.generate(slug + source, rentalTermMatch ? periodQuery : ''),
            onClick: () => {
                isAuthed && storeRecentlyViewedProducts(item);
            }
        };

    const LinkComponent = slug ? 'a' : Link;

    const getBrandName = () => {
        const brand = allBrandsList.find((x) => x.id === item?.brand_id);

        return brand?.name;
    };

    return (
        <LinkComponent {...linkProps} className={`product__item ${!eligible ? 'not_eligible' : ''}`}>
            <div className='d-flex flex-column h-100'>
                <div className='product__item__ribbon'>
                    {(badgeName !== '' && badgeName !== null && badgeName !== undefined) &&
                        <span title={badgeName} className='product__item__ribbon__text' style={{ color: badgeColor ?? '#1D1D1F' }}>{badgeName}</span>}
                </div>
                <p className='product__item__brand'>{item?.brand?.name ?? getBrandName()}</p>
                <p className='product__item__title' title={title}>{title}</p>
                <div className='product__item__image-container mt-auto'>
                    <img
                        className='product__item__image-container__image mx-auto'
                        src={image || NoImg}
                        alt={title} />
                </div>
                <div className='product__item__footer'>
                    <div>
                        <p className='mb-0 subscribe-from'>Subscribe from</p>
                        <p className='mb-0 product__item__price'>{currency} {price}
                            <span className='month'>/mo</span>
                        </p>
                    </div>
                    <div className='view'>View</div>
                </div>
            </div>
        </LinkComponent>
    );
}

ProductItem.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.number,
    item: PropTypes.object,
    isAuthed: PropTypes.bool,
    category: PropTypes.string,
    eligible: PropTypes.bool,
    productUrl: PropTypes.string,
    titleTrimmed: PropTypes.bool,
    source: PropTypes.string,
    slug: PropTypes.string,
    badgeName: PropTypes.string,
    badgeColor: PropTypes.string
};

function mapStateToProps({ auth }) {
    const { isAuthed } = auth;

    return {
        isAuthed
    };
}

export default connect(mapStateToProps, null)(ProductItem);
