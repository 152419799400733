import PropTypes from 'prop-types';
import Button from 'containers/App/components/Button/Button';
import React, { useState } from 'react';
import style from './ReturnOrder.module.scss';
import SelectColor from '../SelectColor/SelectColor';
import { notifType, notify } from 'utils/notifSender';

ReturnOrder.propTypes = {
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    returnOrderType: PropTypes.string,
    setReturnOrderType: PropTypes.func
};

function ReturnOrder({
    onClick,
    loading,
    returnOrderType,
    setReturnOrderType
}) {
    const [title, setTitle] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const target = e.target;
        // const reason = target.reason.value;
        const message = target.message.value;

        if (!title || message === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        if (message.length > 1000) {
            notify('You have reached the maximum character limit.', notifType.DANGER);

            return true;
        }

        onClick({ reason: title.value, message, type: returnOrderType });
    };

    return (<div >
        <form onSubmit={handleSubmit}>
            <div className={style.select}>
                <div className={style.select__title}>
                    Why do you want to return this product ?
                </div>
                <div className={style.select__options}>
                    {/* <input className={style.select__options__input} name='reason'/> */}
                    <SelectColor
                        value={title}
                        onChange={setTitle}
                        placeholder='Please select one title'
                        classNamePrefix='ticket'
                        options={returnReasons} />
                </div>
            </div>

            <div className={style.select}>
                <div className={style.select__title}>
                    Comments
                </div>
                <div className={style.select__desc}>
                    <textarea className={style.select__desc__text} name='message' />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.col}>
                    <Button
                        onClick={() => setReturnOrderType('order')}
                        type='submit'
                        loading={returnOrderType === 'order' && loading}
                        disabled={returnOrderType === 'contact' && loading}
                        className={'submit '}
                        title={'Return Order'} />
                </div>
                <div className={style.col}>
                    <Button
                        loading={returnOrderType === 'contact' && loading}
                        disabled={returnOrderType === 'order' && loading}
                        type='submit'
                        onClick={() => setReturnOrderType('contact')}
                        className={'ignore'}
                        title={'Contact us'} />
                </div>
            </div>
        </form>
    </div>);
}

export const returnReasons = [
    { label: 'Prefer to get the latest model', value: 'Prefer to get the latest model' },
    { label: 'Prefer an alternative / substitute product', value: 'Prefer an alternative / substitute product' },
    { label: 'Subscription prices are too high', value: 'Subscription prices are too high' },
    { label: 'Prefer to purchase / own', value: 'Prefer to purchase / own' },
    { label: 'Don\'t need it anymore', value: 'Don\'t need it anymore' },
    { label: 'Other - I have issues with the order', value: 'Other - I have issues with the order' }

];

export default ReturnOrder;
