import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export function Type({ type, setType, setBestProdQuery, bestProdQuery, setNewProdQuery, newProdQuery, setDealsProdQuery, dealsProdQuery }) {
    return (
        <div className='mb-4'>
            <div
                className={classNames('sidebar__radio', {
                    'is-active': dealsProdQuery
                })}
                onClick={setDealsProdQuery} >
                <span /> Deals
            </div>
            <div
                className={classNames('sidebar__radio', {
                    'is-active': newProdQuery
                })}
                onClick={setNewProdQuery} >
                <span /> New
            </div>
            <div
                className={classNames('sidebar__radio', {
                    'is-active': bestProdQuery
                })}
                onClick={setBestProdQuery} >
                <span /> Trending
            </div>
            <div
                className={classNames('sidebar__radio', {
                    'is-active': !type && !bestProdQuery && !newProdQuery && !dealsProdQuery
                })}
                onClick={() => setType(undefined)} >
                <span /> All
            </div>
        </div>
    );
}

Type.propTypes = {
    type: PropTypes.string,
    setType: PropTypes.func,
    setBestProdQuery: PropTypes.func,
    bestProdQuery: PropTypes.string,
    setNewProdQuery: PropTypes.func,
    newProdQuery: PropTypes.string,
    dealsProdQuery: PropTypes.string,
    setDealsProdQuery: PropTypes.func
};
