import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

/**
 * login user
 * * 422 - Email or Password is wrong
 * @param {String} id
 */

export async function getOneProduct(id) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products/' + id
    };

    if (getAuthToken()) {
        options.headers = {
            Authorization: 'Bearer ' + getAuthToken()
        };
    }

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getOneProductWithDeals(id) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products/' + id + '?deals=true'
    };

    if (getAuthToken()) {
        options.headers = {
            Authorization: 'Bearer ' + getAuthToken()
        };
    }

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
/**
 * return dates
 * @param {Number} id
 */

export async function getReturnDates(id) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products/get-return/' + id,
        headers: { Authorization: 'Bearer ' + getAuthToken() }
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * return dates
 * @param {Object} form
 */

export async function sendReturnProduct(form) {
    const options = {
        method: 'POST',
        url: AUTH_API_URL + '/products/return',
        headers: { Authorization: 'Bearer ' + getAuthToken() },
        data: { ...form }
    };

    if (getAuthToken()) {
        options.headers = {
            Authorization: 'Bearer ' + getAuthToken()
        };
    }

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        throw err;
    }
}

export async function getOneProductWithFlashDeals(id) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/products/' + id + '?flash_deals=true'
    };

    if (getAuthToken()) {
        options.headers = {
            Authorization: 'Bearer ' + getAuthToken()
        };
    }

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}
