import React from 'react';
import Reviews from 'screens/Home/components/Reviews/Reviews';

const AddReviewContainer = () => {
    React.useEffect(() => {
        window.document.title = 'Add a review';

        const delayedInit = () => {
            window.StampedFn.toggleForm('review', '');
        };

        const delay = 2000;

        setTimeout(delayedInit, delay);
    }, []);

    return (
        <Reviews/>
    );
};

export default AddReviewContainer;
