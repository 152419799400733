import React from 'react';
import PropTypes from 'prop-types';
import './Daas.scss';

const Daas = ({ isAuthed }) => {
    return (
        <div className='daas-container'>
            <div className='daas-content'>
                <div className='klaviyo-form-wrapper'>
                    <div className='klaviyo-form-YqUTjg' />
                </div>
            </div>
        </div>
    );
};

Daas.propTypes = {
    isAuthed: PropTypes.bool.isRequired
};

export default Daas;
