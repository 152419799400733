import { getCategories, getAllCategories } from 'api/categories';

const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

const fetchCategoriesSuccess = (data) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    data: data
});

export const fetchCategories = () => {
    return async (dispatch) => {
        try {
            const data = await getCategories();

            dispatch(fetchCategoriesSuccess(data));
        } catch (e) {
            console.error(e);
            dispatch(fetchCategoriesSuccess());
        }
    };
};

export const fetchAllCategories = () => {
    return async (dispatch) => {
        try {
            const data = await getAllCategories();

            dispatch(fetchCategoriesSuccess(data));
        } catch (e) {
            console.error('here', e);
        }
    };
};

const initialState = {
    categories: []
};

export default function categories(state = initialState, action) {
    switch (action.type) {
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.data
            };
        default:
            return state;
    }
}
