import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import buy from 'assets/images/general/basket-white.svg';

import style from './RentalPeriod.module.scss';

RentalPeriod.propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    changeRentalTerms: PropTypes.func,
    rentalOptions: PropTypes.array,
    submitLoading: PropTypes.bool,
    orderRentalPeriod: PropTypes.object.isRequired
};

function RentalPeriod({
    selected,
    onClick,
    isSelected,
    changeRentalTerms,
    rentalOptions,
    submitLoading,
    orderRentalPeriod
}) {
    const location = useLocation();
    const containerRef = useRef(null);

    const handleSubmit = () => {
        if (selected.id === -1) {
            notify('Please select a rental option.', notifType.DANGER);

            return true;
        }

        changeRentalTerms();
    };

    return (<div className={style.RentalPeriod}>
        {<p className={style.RentalPeriod__label}>
            {!isSelected
                ? 'Minimum subscription:'
                : 'Select your rental terms:'
            }
        </p>}
        <div className={style.RentalPeriod__options} ref={containerRef}>
            {[...rentalOptions].reverse().map((option, i) => {
                if (option.duration_time !== 0 && option.name !== 'purchase' && ((orderRentalPeriod && orderRentalPeriod.id === option.id) || option.deleted_at === null)) {
                    return <div
                        key={i}
                        className={selected.id === option.id ? style.option + ' ' + style.option__selected : (option?.duration_time === 6 || option?.duration_time === 12) && !isSelected && (location.pathname.indexOf('deals') === -1) ? `${style.option} flex-column` : style.option}
                        onClick={() => onClick(option)}>
                        <div className={`${style.RentalPeriod__options__internal} d-flex mx-auto justify-content-center`}>
                            <div className={selected?.id === option?.id
                                ? style.option__title + ' ' + style.option__title__extended + ' ' + style.option__title__selected
                                : style.option__title + ' ' + style.option__title__extended}>{option?.duration_time}</div>&nbsp;
                            <div className={`${selected?.id === option?.id
                                ? style.option__title + ' ' + style.option__title__selected
                                : style.option__title} flex-col d-flex`}>
                                {'Month'}{option?.duration_time > 1 && 's'}</div>
                        </div>
                        {/* {((option?.duration_time === 6 || option?.duration_time === 12) && !isSelected && (location.pathname.indexOf('deals') === -1)) ? <div className={style.option__title__onemonth}>+1 month FREE</div> : ''} */}
                    </div>;
                }

                return true;
            })}
        </div>
        {isSelected &&
            <div>
                <Button
                    loading={submitLoading}
                    onClick={handleSubmit}
                    className={'submit ' + style.btn}
                    title={<span className={style.btn__title}>
                        <img className={style.btn__title__img} src={buy} alt='Buy icon' />
                        <span className={style.btn__title__label}>Update rental</span>
                    </span>} />
            </div>
        }
    </div>);
}

export default RentalPeriod;
