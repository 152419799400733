import React from 'react';
import './Freebies.scss';
import PropTypes from 'prop-types';
import FreebiesImage from 'assets/images/freebies_image.png';

Freebies.propTypes = {
    productTiles: PropTypes.array.isRequired
};

function Freebies({ productTiles }) {
    return (
        <div className='freebies'>
            <div className='freebies__container'>
                {productTiles && (
                    <>
                        <div className='freebies__card up_to'>
                            <h3>{productTiles[1]?.name}</h3>
                            <h1>${productTiles[1]?.description}</h1>
                        </div>
                        <div className='freebies__card free'>
                            <div className='freebies__card__inner'>
                                <div>
                                    <h3>{productTiles[0]?.name}</h3>
                                    <h1>{productTiles[0]?.description}</h1>
                                </div>
                                {/* <img src={FreebiesImage} alt='Freebies' /> */}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Freebies;
