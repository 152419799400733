import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import style from './ColorOptionsMobileView.module.scss';

ColorOptionsMobileView.propTypes = {
    options: PropTypes.array,
    onClick: PropTypes.func.isRequired,
    selectedColor: PropTypes.object,
    colorsGallery: PropTypes.array,
    container: PropTypes.string
};

function ColorOptionsMobileView({
    options,
    onClick,
    selectedColor,
    colorsGallery,
    container
}) {
    const getTheOptionForTheColor = (option, attr) => {
        switch (attr) {
            case 'hexcode':
                return colorsGallery.filter(g => g?.id === option?.value)[0]?.hex_code;
            case 'name':
            default:
                return colorsGallery.filter(g => g?.id === option?.value)[0]?.name;
        }
    };

    useEffect(() => {
        for (let i = 0; i < options.length; i++) {
            if (options[i]?.stock !== 0) {
                onClick(options[i]);
                break;
            }
        }
    }, [options]);

    return (
        <>
            <p className={style.ColorOptionsWrapper__title}>Colour</p>
            <div className={style.ColorOptionsWrapper}>
                <div className={style.ColorOptionsWrapper__options}>
                    {options && options.map((option, i) => {
                        let isOutOfStock = null;
                        let isSelected = null;
                        let stockStatus = null;

                        if (container !== 'deals') {
                            isOutOfStock = option?.stock === 0 || option?.stock === undefined;
                            stockStatus = option?.stock === 1 ? 'In Stock' : 'Out of stock';
                        }

                        isSelected = options.length === 1 ? selectedColor?.value === options[0]?.value : selectedColor?.value === colorsGallery[i]?.id;

                        return (
                            <div
                                key={i}
                                className={style.option}
                                onClick={!isOutOfStock ? () => onClick(option) : null}>
                                <div className={style.option__title + ' ' + ((isOutOfStock) ? style.option__disable : (isSelected) ? style.option__selected : null)}>
                                    <div className={`${isOutOfStock ? style.option__opacity_disable : ''} ${selectedColor?.value === option?.value ? style.ColorOptionsWrapper__color__selected : style.ColorOptionsWrapper__color}`}>
                                        <span className={style.color} style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '50%',
                                            background: getTheOptionForTheColor(option, 'hexcode')
                                        }} />
                                    </div>
                                    <div>
                                        <span className={`${isOutOfStock ? style.option__opacity_disable : ''} ${selectedColor?.value === option?.value ? style.option__title__selected__span : style.option__title__span}`}>{getTheOptionForTheColor(option, 'name')}</span>
                                        {isOutOfStock && (
                                            <div className={option?.stock === 1 ? style.ColorOptionsWrapper__stock_status : style.ColorOptionsWrapper__out_stock_status}>
                                                {stockStatus}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default ColorOptionsMobileView;
