import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import * as FS from '@fullstory/browser';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import * as Sentry from '@sentry/react';

import { ROUTES, AUTH_API_URL } from 'config/constants';

import { authSuccess, toggleInvoiceModal } from 'redux/reducers/auth';
import { clearCart, handleFetchingCart, notifyHeaderOnCartUpdate } from 'redux/reducers/cart';
import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';
import { addItemsToCart } from 'api/cart';
import { getAuthedProfile, getPendingInvoice, storeUserEvents } from 'api/user';

import { getLastPageUrlWithoutAuth, setAuthToken } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';
import InvoiceModal from 'containers/App/components/InvoiceModal/InvoiceModal';

import './SocialAuthHandlerContainer.scss';

const SocialAuthHandlerContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
    const [profile, setProfile] = useState({});
    const [loginResponse, setLoginResponse] = useState(null);
    const [doLogin, setDoLogin] = useState(false);
    const [doRegister, setDoRegister] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            const urlParams = new URLSearchParams(location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            if (!code || !state) {
                redirect();

                return;
            }

            const pathParts = location.pathname.split('/');
            const provider = pathParts[pathParts.length - 1];

            try {
                const { data } = await axios.post(`${AUTH_API_URL}/auth/oauth/callback/${provider}`, { code, state });
                const resp = data;

                setAuthToken(resp.access_token);
                const profileData = await getAuthedProfile();

                // Set the profile using the profileData
                setProfile(profileData.data);

                let pendingInvoice = null;

                if (profileData.data.activeSubscriber) {
                    pendingInvoice = await getPendingInvoice();
                }

                if (resp?.is_registered === true) {
                    trackKlaviyoEvent('user-registered', { ...profileData.data });
                    // storeUserEvent(userEventParams);
                    trackKlaviyoEvent('user_register', {
                        email: profile.email,
                        user_id: profile?.id
                    });

                    const urlParams = new URLSearchParams(window.location.search);
                    const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'irclickid'];

                    const userEventParams = {
                        event: 'user_registered',
                        ...Object.fromEntries(utmFields.map(field => [field, urlParams.get(field)]).filter(([_, value]) => value)) // get utm params from url if they exist, filtering out empty values
                    };

                    storeUserEvents(userEventParams);
                    setDoRegister(true);
                } else {
                    trackKlaviyoEvent('user_login', {
                        email: profileData?.data?.email,
                        user_id: profileData?.data?.id
                    });
                    setDoLogin(true);
                    dispatch(notifyHeaderOnCartUpdate(true));
                }

                localStorage.setItem('is_student', profileData.data.is_student ? 'b2s' : 'b2c');
                // dispatch(setSegment(profileData.data.is_student ? 'b2s' : 'b2c'));
                await dispatch(authSuccess(profileData.data, resp.access_token, true, profileData.invited));
                setLoginResponse(pendingInvoice ? pendingInvoice.data : {});
                dispatch(handleFetchingCart());
                notify('Welcome!', notifType.SUCCESS);
                setLoading(false);
            } catch (error) {
                Sentry.captureException(error);
                setLoading(false);
            }
        };

        fetchUser();
    }, [location.search, history]);

    useEffect(() => {
        async function sendProductsToBE() {
            const makeProducts = [];

            for (const product of products) {
                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: product.selectedStorage ? product.selectedStorage.value
                            : (
                                product.attributes.specifications.values[0].id ||
                                product.attributes.inside_box.values[0].id
                            ),
                        quantity: 1,
                        rental_period_id: product.selectedRentalPeriod.id,
                        rental_duration_time: product.selectedRentalPeriod.duration_time,
                        product_id: product.id,
                        color_id: product.selectedColor.value,
                        deal_id: !product.selectedDeals?.discount_type && product.selectedDeals ? product.selectedDeals.id : null,
                        flash_deal_product_id: product.selectedDeals && product.selectedDeals?.discount_type ? product.selectedDeals.id : null

                    }
                );
            }

            try {
                await addItemsToCart(makeProducts);

                notify(`Product${makeProducts.length > 1 ? 's' : ''} added to cart successfuly`, notifType.SUCCESS);
                clearCart();
                redirect(ROUTES.CART);
            } catch (err) {
                //
            }
        }

        if (profile.id && doRegister) {
            ReactGA.event('user_register', {
                category: 'user_auth',
                label: 'user_register',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            trackKlaviyoEvent('add-cart', {
                email: profile?.email,
                user_id: profile?.id,
                title: products[0]?.name,
                product: products[0],
                images: products[0]?.medias,
                price: products[0]?.selectedRentalPeriod?.price,
                cartItems: products,
                cartMeta: products[0]?.total,
                selectedRentalPeriod: products[0]?.selectedRentalPeriod,
                selectedStorage: products[0]?.storage
            });

            FS.event('User registered', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);
            trackFreshChatUser('identify', profile);
            redirect(null);
            setDoRegister(false);
        }

        if (profile.id && doLogin) {
            ReactGA.event('user_login', {
                category: 'user_auth',
                label: 'user_login',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            trackKlaviyoEvent('add-cart', {
                email: profile?.email,
                user_id: profile?.id,
                title: products[0]?.name,
                product: products[0],
                images: products[0]?.medias,
                price: products[0]?.selectedRentalPeriod?.price,
                cartItems: products,
                cartMeta: products[0]?.total,
                selectedRentalPeriod: products[0]?.selectedRentalPeriod,
                selectedStorage: products[0]?.storage
            });

            if (products.length !== 0) {
                if (profile.singpass_status !== 'verified') {
                    notify('Complete your Singpass profile before proceeding.', notifType.DANGER);
                    clearCart();
                    redirect(ROUTES.MYINFORMATION);
                } else {
                    sendProductsToBE();
                }
            }

            FS.event('User login', {
                user_id: profile.id.toString()
            });
            trackKlaviyoEvent('identify', profile);
            trackFreshChatUser('identify', profile);

            if (products.length <= 0) {
                redirect(null);
            }

            setDoLogin(false);
        }
    }, [profile, doLogin, doRegister, history, products]);

    const getRedirectUrl = () => {
        const queryParams = new URLSearchParams(location.search);
        const authenticate = queryParams.get('authenticate') ?? false;
        const redirectUrl = queryParams.get('redirect_url') ?? null;

        if (authenticate === 'true' && redirectUrl != null) {
            return redirectUrl;
        }

        return null;
    };

    const redirect = (route) => {
        let routeToRedirect = route;

        const redirectUrl = getRedirectUrl();
        const cartItems = JSON.parse(localStorage.getItem('products')) || [];

        if (redirectUrl !== null) {
            routeToRedirect = redirectUrl;
        }

        if (cartItems.length > 0) {
            routeToRedirect = ROUTES.CART;
        }

        if (routeToRedirect === null) {
            routeToRedirect = '/';
        }

        history.push(routeToRedirect);
    };

    if (loading) {
        return (
            <>
                <div className='loader-container'>
                    <div className='loader' />
                </div>
                <InvoiceModal />
            </>
        );
    }

    return <div>Loading...</div>;
};

export default SocialAuthHandlerContainer;
