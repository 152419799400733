import Button from 'containers/App/components/Button/Button';
import NumberInput from 'containers/App/components/NumberInput/NumberInput';
import PropTypes from 'prop-types';
import React from 'react';
import style from './LoginOTPForm.module.scss';

LoginOTPForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    loading: PropTypes.bool
};

function LoginOTPForm({
    phone,
    setPhone,
    submit,
    changeForm,
    loading
}) {
    const handleSubmit = e => {
        e.preventDefault();
        submit();
    };

    return (
        <div className={style.section}>
            <form onSubmit={handleSubmit}>
                <div className={style.row}>
                    <div className={style.label}>
                        Enter your mobile number
                    </div>
                    <div className={style.value}>
                        <NumberInput
                            value={phone}
                            onChange={e => setPhone(e)}
                            name='number'
                            className={style.value__input}/>
                    </div>
                </div>

                <div className={style.buttons}>
                    <div className={style.buttons__col}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Sign In'}
                            className={'submit'}/>
                    </div>
                    <div className={style.buttons__col}>
                        <Button
                            onClick={() => changeForm('signin')}
                            type='button'
                            title={'Login with password'}
                            className='ignore' />
                    </div>
                </div>
                <div className={style.account}>
                    <div className={style.account__title}>Need an account?</div>
                    <div className={style.account__button} onClick={() => changeForm('signup')}>sign up</div>
                </div>
            </form>
        </div>
    );
}

export default LoginOTPForm;
