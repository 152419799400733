import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { useQueryParam, StringParam, ArrayParam } from 'use-query-params';

import { getAllCategories } from 'api/categories';

import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import { Price } from './components/Price/Price';
import { MonthlyPrice } from './components/Price/MonthlyPrice';
import { Period } from './components/Period/Period';
import { Brands } from './components/Brands/Brands';
import { getBrands } from 'api/brands';
import { Type } from './components/Type/Type';
import { Categories } from './components/Categories/Categories';

import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';

import styles from '../../../../screens/Products/ProductsScreen.scss';
import './Sidebar.scss';

const Sidebar = ({
    isOpen,
    priceLimits,
    isSheetOpenForSorting,
    isSheetOpenForFiltering,
    loading
}) => {
    const history = useHistory();
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [categQuery, setCategQuery] = useQueryParam('category[]', ArrayParam);
    const [priceQuery, setPriceQuery] = useQueryParam('price', StringParam);
    const [brandsQuery, handleBrandsQuery] = useQueryParam('brand[]', ArrayParam);
    const [periodQuery, setPeriodQuery] = useQueryParam(
        'rental_period',
        StringParam
    );
    const [typeQuery, setTypeQuery] = useQueryParam('type', StringParam);
    const [bestProdQuery, setBestProdQuery] = useQueryParam(
        'best',
        StringParam
    );
    const [newProdQuery, setNewProdQuery] = useQueryParam('new', StringParam);
    const [dealsProdQuery, setDealsProdQuery] = useQueryParam('deals', StringParam);
    const [isShowOpen, setIsOpen] = useState(isMobile ? false : newProdQuery || bestProdQuery || typeQuery);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(!isMobile);
    const [categories, setCategories] = useState([]);
    const [price, setPrice] = useState(priceQuery);
    const [type, setType] = useState(typeQuery);
    // eslint-disable-next-line no-unused-vars
    const [pageQuery, setPageQuery] = useQueryParam('page', StringParam);
    const [brandsArray, setBrandsArray] = useState(brandsQuery || []);
    const [categoriesArray, setCategoriesArray] = useState(categQuery || []);
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        if (!isMobile) window.scrollTo(0, 0);
    }, [categQuery, priceQuery, periodQuery, brandsQuery, pageQuery]);

    useEffect(() => {
        getBrandsData();
        getCategories();
    }, []);

    useEffect(() => {
        getBrandsData(categQuery);
    }, [categQuery]);

    const getBrandsData = async () => {
        const data = await getBrands(categQuery);

        setBrands(data);
    };

    const getCategories = async () => {
        const data = await getAllCategories();

        setCategories(data);
    };

    const handleCategories = (id, slug) => {
        if (categoriesArray.includes(slug)) {
            const filteredCategory = categoriesArray.filter(item => item !== slug);

            setCategoriesArray(filteredCategory);
            setCategQuery(filteredCategory);
        } else {
            setCategoriesArray((prevCateg) => [
                ...prevCateg,
                slug
            ]);
            setCategQuery([...categoriesArray, slug]);
        }

        setPageQuery(undefined);
    };

    const handleBrands = brand => {
        if (brandsArray.includes(brand)) {
            const filteredBrands = brandsArray.filter(item => item !== brand);

            setBrandsArray(filteredBrands);
            handleBrandsQuery(filteredBrands);
        } else {
            setBrandsArray(prevBrands => [...prevBrands, brand]);
            handleBrandsQuery([...brandsArray, brand]);
        }

        setPageQuery(undefined);
    };

    return (
        <div className={classNames(`sidebar ${isMobile ? 'sidebar__mobile' : ''}`, { 'is-open': isOpen })}>
            {(loading && isMobile) && <div className={styles.loading}><AppLoadingOverlay /></div>}
            {((isSheetOpenForSorting && isMobile) || (!isMobile)) && <div>
                <div
                    role='button'
                    onClick={() => setIsOpen(!isShowOpen)}
                    className='sidebar__subtitle'
                    style={{ marginBottom: '1rem' }}>
                    Show
                    {!isMobile && <ChevronIcon
                        className={classNames({ '-is-active': isShowOpen })} />}
                </div>
                <Collapse isOpen={isMobile ? true : isShowOpen}>
                    <Type
                        type={type}
                        setType={val => {
                            setType(val);
                            setTypeQuery(val);
                            setPageQuery(undefined);
                            setBestProdQuery(undefined);
                            setNewProdQuery(undefined);
                            setDealsProdQuery(undefined);
                        }}
                        setDealsProdQuery={() => {
                            setDealsProdQuery(true);
                            setNewProdQuery(undefined);
                            setBestProdQuery(undefined);
                            setPageQuery(undefined);
                        }}
                        setNewProdQuery={() => {
                            setNewProdQuery(true);
                            setDealsProdQuery(undefined);
                            setBestProdQuery(undefined);
                            setPageQuery(undefined);
                        }}
                        newProdQuery={newProdQuery}
                        bestProdQuery={bestProdQuery}
                        dealsProdQuery={dealsProdQuery}
                        setBestProdQuery={() => {
                            setBestProdQuery(true);
                            setDealsProdQuery(undefined);
                            setNewProdQuery(undefined);
                            setPageQuery(undefined);
                        }} />
                </Collapse>
            </div>}

            {((isSheetOpenForSorting && isMobile) || (!isMobile)) && <div>
                <div
                    role='button'
                    onClick={() => setIsSortOpen(!isSortOpen)}
                    style={{ marginBottom: '1rem' }}>
                    <p className='sidebar__subtitle'>
                        Sort By{' '}
                        {!isMobile && <ChevronIcon
                            className={classNames({ '-is-active': isSortOpen })} />}
                    </p>
                </div>
                <Collapse isOpen={isMobile ? true : isSortOpen}>
                    <Price
                        price={price}
                        setPrice={val => {
                            setPrice(val);
                            setPriceQuery(val);
                            setPageQuery(undefined);
                        }} />
                </Collapse>
            </div>}

            {((isSheetOpenForFiltering && isMobile) || (!isMobile)) && <div>
                <div
                    role='button'
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    style={{ marginBottom: '1rem' }}>
                    <p className='sidebar__subtitle'>
                        Filter{' '}
                        {!isMobile && <ChevronIcon
                            className={classNames({
                                '-is-active': isFilterOpen
                            })} />}
                    </p>
                </div>
                <Collapse isOpen={isMobile ? true : isFilterOpen}>
                    <MonthlyPrice priceLimits={priceLimits} />
                    <Period
                        period={periodQuery || '12'}
                        setPeriod={val => setPeriodQuery(val)} />

                    <Categories
                        categories={categories}
                        handleCategories={handleCategories}
                        removeCateg={() => setCategQuery(undefined)}
                        categoryQuery={categQuery} />
                    <Brands
                        brands={brands}
                        handleBrands={handleBrands}
                        brandsQuery={brandsQuery} />
                </Collapse>
            </div>}

            <button
                className='sidebar__reset'
                onClick={() => {
                    history.push('/products');
                    setCategoriesArray([]);
                    setBrandsArray([]);
                }}>
                Reset
            </button>
        </div>
    );
};

Sidebar.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    priceLimits: PropTypes.array,
    isSheetOpenForSorting: PropTypes.bool,
    isSheetOpenForFiltering: PropTypes.bool,
    loading: PropTypes.bool
};

export default Sidebar;
