import React from 'react';
import PropTypes from 'prop-types';
import Daas from 'components/Daas';

const DaasContainer = ({ isAuthed }) => {
    return <Daas isAuthed={isAuthed} />;
};

DaasContainer.propTypes = {
    isAuthed: PropTypes.bool.isRequired
};

export default DaasContainer;
