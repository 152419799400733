import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { STAMPED_PUBLIC_API_KEY, STAMPED_STORE_HASH } from 'config/constants';
import './StampedReviews.scss';

export default function StampedReviews({ widgetId, widgetType, widgetBadgeType, widgetStarColor = '' }) {
    useEffect(() => {
        const delayedInit = () => {
            window.StampedFn.init({ apiKey: STAMPED_PUBLIC_API_KEY, sId: STAMPED_STORE_HASH });
        };

        const delay = 1000;

        const timerId = setTimeout(delayedInit, delay);

        return () => {
            clearTimeout(timerId);
        };
    }, []);

    if (widgetId && widgetType) {
        return (
            <div id={widgetId} data-widget-type={widgetType} data-badge-type={widgetBadgeType}
                data-style-color-star={widgetStarColor}/>
        );
    }

    return null;
}

StampedReviews.propTypes = {
    widgetId: PropTypes.string.isRequired,
    widgetType: PropTypes.string.isRequired,
    widgetBadgeType: PropTypes.string,
    widgetStarColor: PropTypes.string
};

StampedReviews.defaultProps = {
    widgetType: 'slider',
    widgetBadgeType: 'minimal'
};
