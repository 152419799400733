import React from 'react';
import PropTypes from 'prop-types';

NumberInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxLength: PropTypes.number
};

export default function NumberInput({
    onChange,
    className,
    name,
    placeholder,
    value,
    maxLength
}) {
    const handleChange = (e) => {
        const val = e.target.value;

        if (e.target.validity.valid) {
            if (onChange) {
                if (maxLength) {
                    if (val.length <= maxLength) {
                        onChange(val);
                    }
                } else {
                    onChange(val);
                }
            } else {
                onChange(val);
            }
        }
    };

    return (
        <input
            type='text'
            pattern='[0-9]*'
            placeholder={placeholder}
            className={className}
            onChange={handleChange}
            value={value}
            name={name}/>
    );
}
