import React from 'react';
import { Helmet } from 'react-helmet';
import ContactUsScreen from 'screens/ContactUs/ContactUsScreen';

const ContactUsContainer = () => {
    React.useEffect(() => {
        window.document.title = 'Contact Us';
    }, []);

    return (
        <>
            <Helmet>
                <title>How it Works</title>
                <meta name='description' content='Email us at support@cinch.sg' />
            </Helmet>
            <ContactUsScreen />
        </>
    );
};

export default ContactUsContainer;
